import ls from '.';
import { IFormDataBranchGym } from '../../chat4fit/components/generics/entities';
import AuthenticateService from '../../chat4fit/service/AuthenticateService';
import BranchGymService from '../../chat4fit/service/admin/BranchGymService';
import { isSuccess } from '../../utils/httpStatus';
import { IPerformRequestResult } from '../../utils/interfaces';

const authenticateService = new AuthenticateService();
const branchGymService = new BranchGymService();

// const limitMinutes = 1;

export const hasBranchGymSelected = (): boolean => {
    const branchGym = localStorage.getItem('branchGym');
    return !!branchGym;
};

export const getAllBranchGyms = (filters: {
    networkGymId?: string | null,
    branchGymIds?: string[] | null,
    branchGymId?: string | null,
    nonpayer?: boolean | null,
    unsubscribe?: boolean | null,
}): any[] | null => {
    const localStorageBranchs: any = ls.getLocalStorageDataKey('branchGyms');
    if (!localStorageBranchs) return null;
    let listBranchGyms = localStorageBranchs;
    if (filters.networkGymId) listBranchGyms = listBranchGyms.filter((b: any) => b.networkGym === filters.networkGymId || b.networkGym?._id === filters.networkGymId);
    if (filters.branchGymIds) listBranchGyms = listBranchGyms.filter((b: any) => filters.branchGymIds?.find((b2: any) => b._id === b2) !== undefined);
    if (filters.branchGymId) listBranchGyms = listBranchGyms.filter((b: any) => b._id === filters.branchGymId);
    if (filters.nonpayer !== undefined) listBranchGyms = listBranchGyms.filter((b: any) => b.nonpayer === filters.nonpayer);
    if (filters.unsubscribe !== undefined) listBranchGyms = listBranchGyms.filter((b: any) => b.unsubscribe === filters.unsubscribe);
    return listBranchGyms;
}

export const setAllBranchGyms = async () => {
    // console.log('PASSOU.1');
    // const datetime = ls.getLocalStorageDateTimeKey('branchGyms');
    // if (!datetime || moment(datetime).diff(moment(ls.socket.getServerSocketDateTime()), 'minutes') - limitMinutes < 0) {
    //     console.log('PASSOU.2');
    const resultBranchGyms: IPerformRequestResult<IFormDataBranchGym> = await branchGymService.findAllBranchGymByNetworkGymIds(await ls.networkGym.getNetworkGymsByStorageToken());
    ls.setLocalStorageKey('branchGyms', isSuccess(resultBranchGyms.status) ? resultBranchGyms.data : []);
    // }
}

export const getBranchGymsByStorageToken = async (): Promise<any[] | null> => {
    const token = localStorage.getItem('project.chat4fit.token');

    if (token) {
        if (localStorage.getItem('branchGymDataList')) {
            let branchGymDataList: any = localStorage.getItem('branchGymDataList');
            if (branchGymDataList) {
                branchGymDataList = JSON.parse(branchGymDataList);
                return branchGymDataList;
            }
        }
        return await authenticateService.dataList(token).then((returned: any) => {
            if (returned && returned.branchGyms) {
                if (ls.networkGym.hasNetworkGymSelected() && !localStorage.getItem('branchGym') && returned.branchGyms?.length === 1) localStorage.setItem('branchGym', returned.branchGyms[0]._id);
                localStorage.setItem('branchGymDataList', JSON.stringify(returned.branchGyms));
                return returned.branchGyms;
            } else {
                return [];
            }
        }).catch(() => []);
    } else {
        return null;

    }
};

export const defineUniqueBranchGym = async (select: (type: string, _id?: string | null, ...args: string[]) => void, branchGymDataList?: any[]) => {
    if (!hasBranchGymSelected()) {
        if (branchGymDataList && branchGymDataList.length === 1 && !localStorage.getItem('branchGym')) {
            localStorage.setItem('branchGym', branchGymDataList[0]._id);
            select('branchGym', branchGymDataList[0]._id);
        } else {
            const branchGyms = await ls.branchGym.getBranchGymsByStorageToken();
            let lBranchGyms = branchGyms?.filter((b: any) => !b.nonpayer && !b.unsubscribe);
            // console.log('lBranchGyms: ', lBranchGyms);
            if (lBranchGyms && lBranchGyms.length === 1) {
                if (lBranchGyms?.length === 1 && !localStorage.getItem('branchGym')) {
                    localStorage.setItem('branchGym', lBranchGyms[0]._id);
                    select('branchGym', lBranchGyms[0]._id);
                };
            }
        }
    } else {
        if (branchGymDataList) {
            if (branchGymDataList.find((b: any) => b._id === localStorage.getItem('branchGym'))) {
                select('branchGym', localStorage.getItem('branchGym'));
            } else {
                localStorage.removeItem('branchGym');
            }
        }
    }
};
