import moment from 'moment';
import EntitiesService, { Entities } from '../generics/EntitiesService';
import GenericService from '../generics/GenericService';

export default class TrackerMessageService extends GenericService {
    findTrackerMessageById = async (_id: string | null) => await super.findById(Entities.TRACKER_MESSAGE, false, _id);
    saveTrackerMessage = async (data: any) => await super.save(Entities.TRACKER_MESSAGE, false, data);

    findDistinctByNetworkGym = async (field: string, params: {
        references?: { field: Entities, value: any }[],
        queries?: { field?: string, value: any }[],
        sorts?: { field: string, value: number }[],
        projections?: { field: string, detail?: string | { field: string }[] }[]
        ignoreSummaryData?: boolean
    }) => {
        return await super.findDistinctByQuery(Entities.TRACKER_MESSAGE, false, field, params);
    }

    findTrackerMessageMonitoring = async (params: {
        jid?: string,
        networkGymId?: string,
        branchGymId?: string | null,
        trackerMobileId?: string,
        dateFilterParam?: { start: Date, end: Date },
        contactMessageList?: string[]
        search?: string,
        limit?: number,
    }) => {
        let limitValue: any;
        limitValue = params.limit ? { limit: params.limit } : {};
        const queries: { field: string, value: any }[] = [];

        queries.push({ field: 'networkGym', value: params.networkGymId });

        if (!params.jid && params.dateFilterParam) {
            queries.push({
                field: 'dateText',
                value: {
                    '$gte': moment(params.dateFilterParam.start).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString(),
                    '$lt': moment(params.dateFilterParam.end).add(1, 'days').set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString()
                }
            });
        }

        if (params.jid) queries.push({ field: 'jid', value: params.jid });
        if (!params.jid && params.contactMessageList) queries.push({ field: 'jid', value: { '$in': params.contactMessageList } });

        queries.push({ field: 'active', value: true });

        return await super.findByQuery(Entities.TRACKER_MESSAGE, false,
            {
                queries,
                sorts: [{
                    field: 'dateText', value: -1
                }],
                projections: [
                    { field: 'jid' },
                    { field: 'typeMessage' },
                    { field: 'typeObjectMessage' },
                    { field: 'typeShowMessage' },
                    { field: 'originalMessage' },
                    { field: 'fromMe' },
                    { field: 'messageId' },
                    { field: 'text' },
                    { field: 'dateText' },
                    { field: 'pushName' },
                    { field: 'group' },
                    { field: 'readReceipt' },
                    { field: 'isObject' },
                    { field: 'mimetype' },
                    { field: 'jpegThumbnail' },
                    { field: 'pathCDN' },
                    { field: 'active' },
                    { field: 'deleted' },
                ]
            }, undefined, limitValue);
    };

    findTrackerMessageByTrackerMobileId = async (jid?: string, networkGymId?: string, branchGymId?: string | null, trackerMobileId?: string,
        dateFilterParam?: { start: Date, end: Date }, search?: string, limit?: number, contactMessageList?: string[]) => {


        // let dateFilter: any;
        let limitValue: any;
        limitValue = limit ? { limit } : {};
        let queries: { field: string, value: any }[] = [];


        if (!jid && dateFilterParam) {
            queries = [{
                field: 'dateText',
                value: {
                    '$gte': moment(dateFilterParam.start).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString(),
                    '$lt': moment(dateFilterParam.end).add(1, 'days').set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString()
                }
            }];
        }

        queries.push({
            field: 'active', value: true
        });

        if (jid) queries.push({ field: 'jid', value: jid });

        if (contactMessageList) queries.push({ field: 'jid', value: { '$in': contactMessageList } });

        let resultSearch = await super.findByQuery(Entities.TRACKER_MESSAGE, false,
            {
                references: [{ field: Entities.TRACKER_MOBILE, value: trackerMobileId },
                { field: Entities.NETWORK_GYM, value: networkGymId }],
                queries,
                sorts: [{
                    field: 'dateText', value: -1
                }],
                projections: [
                    { field: 'jid' },
                    { field: 'typeMessage' },
                    { field: 'typeObjectMessage' },
                    { field: 'typeShowMessage' },
                    { field: 'originalMessage' },
                    { field: 'fromMe' },
                    { field: 'messageId' },
                    { field: 'text' },
                    { field: 'vcard' },
                    { field: 'dateText' },
                    { field: 'pushName' },
                    { field: 'group' },
                    { field: 'readReceipt' },
                    { field: 'isObject' },
                    { field: 'mimetype' },
                    { field: 'jpegThumbnail' },
                    { field: 'pathCDN' },
                    { field: 'reactText' },
                    { field: 'active' },
                    { field: 'deleted' },
                ]
            }, undefined, limitValue);

        // console.log('resultSearch: ', resultSearch?.data?.length);

        return resultSearch;
    };

    findLastTrackerMessageByTrackerMobileId = async (jid: string, networkGymId: string, trackerMobileId?: string, search?: any) => {
        if (!search) search = {};
        if (!search.queries) search.queries = [{ field: 'jid', value: jid }];

        search.queries.push({
            field: EntitiesService(Entities.NETWORK_GYM).singular,
            value: networkGymId
        });

        if (trackerMobileId) {
            search.queries.push({
                field: EntitiesService(Entities.TRACKER_MOBILE).singular,
                value: trackerMobileId
            });
        };

        search.sorts = [{
            field: 'dateText', value: -1
        }]

        return await super.findByQuery(Entities.TRACKER_MESSAGE, false, search, undefined, { limit: 1 })
    };
}
