/* eslint-disable react-hooks/exhaustive-deps */
import { FormikValues } from 'formik';
import moment from 'moment';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Calendar, CalendarProps } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import React, { useEffect, useRef, useState } from 'react';
import { RoleRules, checkRoleHierarchyByToken, checkRoleHierarchyByTokenOrs } from '../../../projects/checkRoleHierarchy';
import ls from '../../../projects/localStorage';
import { isArray } from '../../../utils/functions';
import { momentRoundMinutes } from '../../../utils/functions/data';
import { isDate } from '../../../utils/functions/date';
import { makeHash, makeKeyByString } from '../../../utils/functions/hash';
import { EnumTypeShowMessage } from '../../../utils/functions/message';
import { limitCharacters, onlyAlphaNumeric, padLeft } from '../../../utils/functions/string';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import BranchGymService from '../../service/admin/BranchGymService';
import DashboardReportService from '../../service/admin/DashboardReportService';
import ScheduleService from '../../service/admin/ScheduleService';
import TrackerContactMessageService from '../../service/admin/TrackerContactMessageService';
import TrackerMessageService from '../../service/admin/TrackerMessageService';
import TrackerMobileService from '../../service/admin/TrackerMobileService';
import { IMessage } from '../Control';
import { createSkeletons } from '../commons/GenericCard';
import { GenericFormDialog } from '../commons/GenericFormDialog';
import { SelecDialog } from '../commons/SelectDialog';
import { saveFailed, saveSuccessed } from '../commons/functions';
import { IFormDataAttendance, IFormDataAttendanceCreateData, IFormDataAttendanceUpdateData, IFormDataBranchGym, IFormDataScheduleData, IFormDataTag, IFormListDataQuickMessage } from '../generics/entities';
import { IPropsArgsComponents, IPropsFormDialog, IPropsFormListDialog } from '../generics/formInterfaces';
import { FormikInterface } from '../interfaces/formikInterface';

import MimeType from 'mime';

import { FileUpload } from 'primereact/fileupload';

import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';
import { InputTextarea } from 'primereact/inputtextarea';
import QuickMessageBranchGymService from '../../service/admin/QuickMessageBranchGymService';
import QuickMessageService from '../../service/admin/QuickMessageService';
import TagService from '../../service/admin/TagService';
import ManagerSystemMessageService from '../../service/generics/ManagerSystemMessageService';
import NotificationComponent from '../Notification/Notification';
import './Attendance.css';
import { AttendanceMessage } from './AttendanceMessage';

enum StatusContactMessage {
    newer = 'newer',
    inProgress = 'inProgress',
    available = 'available',
    blocked = 'blocked',
    finished = 'finished',
    unknown = 'unknown',
}

const branchGymService = new BranchGymService();
const dashboardReportService = new DashboardReportService();
const trackerMessageService = new TrackerMessageService();
const trackerContactMessageService = new TrackerContactMessageService();
const scheduleService = new ScheduleService();
const quickMessageService = new QuickMessageService();
const quickMessageBranchGymService = new QuickMessageBranchGymService();
const tagService = new TagService();
const managerSystemMessageService = new ManagerSystemMessageService();

const timeRefreshContactList = 500;

const marginDaysFilter = 90;

// const limitContacts = 5000;
const limitContactsMessage = 400;
const limitContactSearch = 5050;

export const Attendance = (props: IPropsArgsComponents) => {
    const inputMessageSend = useRef<HTMLTextAreaElement>(null);

    const [unreadMessages, setUnreadMessages] = useState<number>(0);

    const [waitInteraction, setWaitInteraction] = useState<boolean>(false);

    const [branchGymData, setBranchGymData] = useState<IFormDataBranchGym[]>([]);
    const [branchGymNoBlockedData, setBranchGymNoBlockedData] = useState<IFormDataBranchGym[]>([]);

    const [networkGymId, setNetworkGymId] = useState(props.args.networkGymId);
    const [branchGymId, setBranchGymId] = useState(props.args.branchGymId);
    const [hasNotifications, setHasNotifications] = useState(props.args.hasNotifications);
    const [mobileMenuActive, setMobileMenuActive] = useState<boolean>(props.args.mobileMenuActive);
    const [token] = useState(props.args.token);
    const [customerId] = useState<string | null>(ls.customer.getCustomerIdStorageToken());
    const [startDateMessages, setStartDateMessages] = useState<Date>(ls.socket.getServerSocketDateTime().subtract(7, 'days').toDate());
    const [selectStartDateMessages, setSelectStartDateMessages] = useState<Date>(ls.socket.getServerSocketDateTime().subtract(7, 'days').toDate());
    const [endDateMessages, setEndDateMessages] = useState<Date>(ls.socket.getServerSocketDateTime().toDate());
    const [selectEndDateMessages, setSelectEndDateMessages] = useState<Date>(ls.socket.getServerSocketDateTime().toDate());

    const [quickMessageData, setQuickMessageData] = useState<IFormListDataQuickMessage[]>();
    const [quickMessageBranchGym, setQuickMessageBranchGym] = useState<any[]>();
    const [tagsData, setTagsData] = useState<IFormDataTag[]>();
    const [tagFiltersData, setTagFiltersData] = useState<IFormDataTag[]>();
    const [tagContactsData, setTagContactsData] = useState<IFormDataTag[]>();
    const [contactSelected, setContactSelected] = useState<any>();
    const [trackerContactMessages, setTrackerContactMessages] = useState<any[]>();
    const [distinctTrackerContactMessages, setDistinctTrackerContactMessages] = useState<string[]>();
    const [editFormData, setEditFormData] = useState<any | null>();
    const [editFormCreateData, setEditFormCreateData] = useState<any | null>();
    const [editScheduleData, setEditScheduleData] = useState<any | null>();
    const [messagesEnd] = useState<HTMLDivElement | null>();
    const [showConfirmateActionMethod, setShowConfirmateActionMethod] = useState<any>();
    const [branchGymsByStorageTokens, setBranchGymsByStorageTokens] = useState<any[] | null>();

    const [textQuickMessage, setTextQuickMessage] = useState<string | null>(null);
    const [quickMessageToShow, setQuickMessageToShow] = useState<IFormListDataQuickMessage | null>(null);

    const [dialogQuickMessageToShow, setDialogQuickMessageToShow] = useState<boolean>(false);
    const [dialogData, setDialogData] = useState(false);
    const [dialogCreateData, setDialogCreateData] = useState(false);
    const [dialogBranchGymData, setDialogBranchGymData] = useState(false);
    const [methodBranchGymData, setMethodBranchGymData] = useState<any>();
    const [scheduleDialogData, setScheduleDialogData] = useState(false);
    const [optionsMessage, setOptionsMessage] = useState<boolean>(false);
    const [mediasMessage, setMediasMessage] = useState<boolean>(false);
    const [optionsFilters, setOptionsFilters] = useState<boolean>(false);
    const [selectActiveStatus, setSelectActiveStatus] = useState<boolean>(true);
    const [selectEndStatus, setSelectEndStatus] = useState<boolean>(false);
    const [selectMyContacts, setSelectMyContacts] = useState<boolean>(false);
    const [loadingData, setLoadingData] = useState(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [scrollMessages, setScrollMessages] = useState<boolean>(true);
    const [showConfirmateAction, setShowConfirmateAction] = useState<boolean>(false);
    const [saveCancelButtonDisabled, setSaveCancelButtonDisabled] = useState<boolean>(false);
    const [rightSideOpen, setRightSideOpen] = useState<boolean>(true);

    const [countContacts, setCountContacts] = useState<number>(0);
    const [refreshKey, setRefreshKey] = useState(0);
    const [initialHour] = useState<number>(6);
    const [initialMinute] = useState<number>(0);
    const [finalHour] = useState<number>(22);
    const [finalMinute] = useState<number>(0);
    const [rowsSizeCount, setRowsSizeCount] = useState<number>(50);

    const [messageChangeDate, setMessageChangeDate] = useState<string>('');
    const [filterContactMessage, setFilterContactMessage] = useState<string>('');
    const [messageToSend, setMessageToSend] = useState<string>('');
    const [showConfirmateActionText, setShowConfirmateActionText] = useState<string>('');

    const [messageReplyId, setMessageReplyId] = useState<string>();
    const [messageReplyText, setMessageReplyText] = useState<string>();
    const [originalMessageReply, setOriginalMessageReply] = useState<string>();


    useEffect(() => { if (!token) window.location.href = '/'; }, [token]);
    // useEffect(() => {
    //     setInterval(() => {
    //         methods.changeAttrib<HTMLTitleElement>('id', 'text', 'project-title', `${randomInt(0, 1000)}`);

    //     }, 100)
    // }, []);




    useEffect(() => { prepareLoadingScreen(true); }, []);
    useEffect(() => {
        props.args.setUpdateConsidered([
            { key: 'networkGym', show: true, considered: true },
            { key: 'branchGym', show: true, considered: true },
        ]);
    }, []);
    useEffect(() => { ls.branchGym.getBranchGymsByStorageToken().then((branchGymsByStorageToken) => { if (branchGymsByStorageToken) setBranchGymsByStorageTokens(branchGymsByStorageToken); }); }, []);
    useEffect(() => { setNewMessageToSend(messageToSend); }, [optionsMessage, mediasMessage]);
    useEffect(() => { setMobileMenuActive(props.args.mobileMenuActive); }, [props.args.mobileMenuActive]);
    useEffect(() => { setHasNotifications(props.args.hasNotifications); }, [props.args.hasNotifications]);
    useEffect(() => { if (!props?.args?.networkGymId) prepareLoadingScreen(false); setNetworkGymId(props.args.networkGymId); }, [props.args.networkGymId]);
    useEffect(() => { setBranchGymId(props.args.branchGymId); }, [props.args.branchGymId]);
    useEffect(() => { refreshDistinctContactByMessages(); }, [networkGymId, selectActiveStatus, selectEndStatus, selectStartDateMessages, selectEndDateMessages]);


    // selectStartDateMessages
    // startDateMessages
    // selectEndDateMessages
    // endDateMessages

    useEffect(() => { refreshListBranchGyms() }, [networkGymId]);

    useEffect(() => { refreshListQuickMessage(); refreshListTags(); }, [networkGymId, branchGymId]);
    useEffect(() => { checkShortCodeQuickMessage(messageToSend); }, [messageToSend]);
    useEffect(() => { setMessageChangeDate(''); setMessageChangeDate(''); }, [selectStartDateMessages, selectEndDateMessages]);
    useEffect(() => { refreshListQuickMessageBranchGym(); }, [quickMessageData]);
    useEffect(() => { addBranchGymToQuickMessage(quickMessageData, quickMessageBranchGym); }, [quickMessageData, quickMessageBranchGym]);
    useEffect(() => {
        let contactMessageToSelect: any;
        if (contactSelected) contactMessageToSelect = trackerContactMessages?.find((d: any) => d._id === contactSelected._id);
        if (!contactMessageToSelect) setContactSelected(undefined);
        else setContactSelected(contactMessageToSelect);
    }, [trackerContactMessages]);
    useEffect(() => { refreshListTrackerContactMessage(false); }, [refreshKey]);
    useEffect(() => { defaultFilters(); }, [tagsData]);

    useEffect(() => {
        setMessageReplyId(undefined);
        setMessageReplyText(undefined);
        setOriginalMessageReply(undefined);
    }, [contactSelected]);


    const prepareLoadingScreen = ((loadingState: boolean) => {
        props.args.setLoading(loadingState)
    });

    const addBranchGymToQuickMessage = (quickMessageData: IFormListDataQuickMessage[] | undefined, quickMessageBranchGym: any[] | undefined) => {
        if (quickMessageData && quickMessageBranchGym) {
            for (let i = 0; i < quickMessageData.length; i++) {
                const quickMessage = quickMessageData[i];
                quickMessage.branchGyms = quickMessageBranchGym.filter(b => b.quickMessage === quickMessage._id).map(b => b.branchGym).join();
            }
        }
    }

    const checkShortCodeQuickMessage = async (message: string) => {
        if (quickMessageData && quickMessageData.length > 0) {
            for (let i = 0; i < quickMessageData.length; i++) {
                const quickMessage = quickMessageData[i];
                if (message.includes(`#${quickMessage.tag}#`)) {
                    setShowQuickMessage(quickMessage);
                    setMessageToSend(message.replaceAll(`#${quickMessage.tag}#`, ''));
                    break;
                }
            }
        }
    }

    const refreshListQuickMessage = () => {
        let isMounted = true;
        if (networkGymId) {
            quickMessageService.allNetworkGymQuickMessages(networkGymId).then((returned: any) => {
                if (isMounted) {
                    if (returned.status === HttpStatus.UNAUTHORIZED) {
                        props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                        setTimeout(() => { window.location.href = '/'; }, 5000);
                        setQuickMessageData([]);
                        // refreshQuickMessageBranchGymList();
                    } else if (isSuccess(returned.status)) {
                        let returnedQuickMessages = returned.data;
                        setQuickMessageData(returnedQuickMessages);
                        // refreshQuickMessageBranchGymList();
                    } else {
                        setQuickMessageData([]);
                        // refreshQuickMessageBranchGymList();
                    }
                }
            }).catch((error: any) => {
                console.error('error', error);
                if (isMounted) {
                    setQuickMessageData([]);
                    // refreshQuickMessageBranchGymList();
                }
            });
        } else {
            setQuickMessageData([]);
            // refreshQuickMessageBranchGymList();
            // setTimeout(() => { refreshListQuickMessage(); }, 1000);
        }
        return () => { isMounted = false };
    };

    const refreshListQuickMessageBranchGym = () => {
        let isMounted = true;
        if (quickMessageData) {
            let listQuickMessages: (string | undefined)[] = [...quickMessageData.filter(q => !!q._id).map(q => q._id)];
            quickMessageBranchGymService.allQuickMessageBranchGyms(listQuickMessages).then((returned: any) => {
                if (isMounted) {
                    if (returned.status === HttpStatus.UNAUTHORIZED) {
                        props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                        setTimeout(() => { window.location.href = '/'; }, 5000);
                        setQuickMessageBranchGym([]);
                        // refreshQuickMessageBranchGymList();
                    } else if (isSuccess(returned.status)) {
                        let returnedQuickMessages = returned.data;
                        setQuickMessageBranchGym(returnedQuickMessages);
                        // refreshQuickMessageBranchGymList();
                    } else {
                        setQuickMessageBranchGym([]);
                        // refreshQuickMessageBranchGymList();
                    }
                }
            }).catch((error: any) => {
                console.error('error', error);
                if (isMounted) {
                    setQuickMessageBranchGym([]);
                    // refreshQuickMessageBranchGymList();
                }
            });
        } else {
            setQuickMessageBranchGym([]);
            // refreshQuickMessageBranchGymList();
        }
        return () => { isMounted = false };
    };

    const refreshListTags = () => {
        let isMounted = true;
        if (networkGymId) {
            tagService.allTags(networkGymId).then((returned: any) => {
                if (isMounted) {
                    if (returned.status === HttpStatus.UNAUTHORIZED) {
                        props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                        setTimeout(() => { window.location.href = '/'; }, 5000);
                        setTagsData([]);
                        setTagFiltersData([]);
                        setTagContactsData([]);
                        // refreshTag();
                    } else if (isSuccess(returned.status)) {
                        let returnedTag = returned.data.sort((d1: IFormDataTag, d2: IFormDataTag) => d1.tag < d2.tag ? -1 : d1.tag > d2.tag ? 1 : 0);
                        setTagsData(returnedTag.map((r: IFormDataTag) => Object.assign({}, r)));
                        setTagFiltersData(returnedTag.map((r: IFormDataTag) => Object.assign({}, r)));
                        setTagContactsData(returnedTag.map((r: IFormDataTag) => Object.assign({}, r)));
                        // refreshTag();
                    } else {
                        setTagsData([]);
                        setTagFiltersData([]);
                        setTagContactsData([]);
                        // refreshTag();
                    }
                }
            }).catch((error: any) => {
                console.error('error', error);
                if (isMounted) {
                    setTagsData([]);
                    setTagFiltersData([]);
                    setTagContactsData([]);
                    // refreshTag();
                }
            });
        } else {
            setTagsData([]);
            setTagFiltersData([]);
            setTagContactsData([]);
            // refreshTag();
            // setTimeout(() => { refreshListTags(); }, 1000);
        }
        return () => { isMounted = false };
    };

    const updateListContact = async (newDataList: any[], includeNewer: boolean) => {
        let isMounted = true;
        let changed = false;
        let newListContacts: any[] = [];

        if (newDataList.length > 0) {
            for (let i = 0; i < newDataList.length; i++) {
                const newContactData = newDataList[i];
                let contactInList = trackerContactMessages?.find((c: any) => c._id === newContactData._id) || undefined;
                // console.log('newContactData: ', newContactData);
                if (!contactInList && includeNewer) {
                    newListContacts.push(newContactData);
                    changed = true;
                } else {
                    let clearNewContactData = Object.assign({}, newContactData);
                    newListContacts.push(Object.assign(contactInList, clearNewContactData));
                    changed = true;
                }
            }
        }

        if (isMounted) {
            if (changed) {
                setCountContacts(newListContacts?.length || 0);
                setTrackerContactMessages(newListContacts);
            }
        }

        return () => { isMounted = false };
    }

    const refreshDistinctContactByMessages = async () => {
        if (networkGymId) {
            let isMounted = true;

            const returned = await trackerMessageService.findDistinctByNetworkGym('jid', {
                queries: [{
                    value: {
                        networkGym: networkGymId,
                        dateText: {
                            $gte: moment(selectStartDateMessages).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate(),
                            $lt: moment(selectEndDateMessages).add(1, 'days').set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate()
                        }
                    },
                }]
            });

            if (returned) {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { window.location.href = '/'; }, 5000);
                    setDistinctTrackerContactMessages([]);
                    setTrackerContactMessages([]);
                    setContactSelected(undefined);
                } else if (isSuccess(returned.status)) {
                    if (isMounted) {
                        setDistinctTrackerContactMessages(returned.data);
                    }
                } else {
                    setDistinctTrackerContactMessages([]);
                }
            } else {
                setDistinctTrackerContactMessages([]);
                setTrackerContactMessages([]);
                setContactSelected(undefined);
            }
        }
    }

    const refreshListTrackerContactMessage = async (unloadingScreen?: boolean, refreshTimeout: boolean = true) => {
        // console.log('distinctTrackerContactMessages: ', distinctTrackerContactMessages);
        if (networkGymId) {
            let isMounted = true;

            const queries = [];
            let queryOrs: any = [{ finished: false }];

            if (selectEndStatus) {
                queryOrs = [
                    { finished: false },
                    {
                        '$and': [
                            { finished: true }, {
                                jid: {
                                    $in: distinctTrackerContactMessages || []
                                }
                            }
                        ]
                    },
                ];
            };


            // const filterContactName = new RegExp(`${filterContactMessage}`, 'img');


            queries.push({
                field: '$and',
                value: [
                    {
                        '$or': [
                            { branchGym: { $in: (branchGymsByStorageTokens || (await ls.branchGym.getBranchGymsByStorageToken()))?.map(b => b._id) } },
                            { branchGym: { $exists: false } },
                        ]
                    },
                    {
                        '$or': queryOrs
                    },
                    {
                        group: false
                    }
                ]
            });

            // console.log('queries: ', queries);

            // let projections: any[] = [];
            let projections = [
                // { field: 'key' },
                { field: 'networkGym', detail: [{ field: '_id' }] },
                { field: 'branchGym', detail: [{ field: '_id' }, { field: 'name' }, { field: 'minutesTimeoutAttendant' }] },
                { field: 'trackerMobile', detail: [{ field: '_id' }] },
                { field: 'assumedBy', detail: [{ field: '_id' }, { field: 'nickname' }] },
                { field: 'lastAssumedBy', detail: [{ field: '_id' }] },
                // { field: 'closedBy' },
                // { field: 'lastClosedBy' },
                { field: 'lastFromMessage' },
                // { field: 'typeContactMessage' },
                // { field: 'typeInitiatedContact' },
                // { field: 'enumerator' },
                { field: 'unreadMessages' },
                { field: 'dataExtra' },
                { field: 'jid' },
                { field: 'pushName' },
                { field: 'group' },
                // { field: 'guide' },
                // { field: 'guideStep' },
                // { field: 'npsRatings' },
                // { field: 'npsRatingComments' },
                // { field: 'stateContactMessage' },
                { field: 'tags' },
                { field: 'available' },
                { field: 'new' },
                { field: 'inProgress' },
                { field: 'finished' },
                { field: 'dateCreated' },
                { field: 'lastChange' },
                { field: 'isValidWhatsapp' },
                // { field: 'activelyInitiatedContact' },
                // { field: 'deleted' },
                { field: 'active' },
                { field: 'processFindbranchGymOptions' }
            ];

            // console.log('START: ', ls.socket.getServerSocketDateTime().toISOString(), networkGymId, branchGymId, { queries });
            trackerContactMessageService.findTrackerContactMessageByNetworkGymId(networkGymId, branchGymId, { queries, projections }, limitContactSearch).then((returned: any) => {
                if (isMounted) {
                    if (returned) {
                        if (returned.status === HttpStatus.UNAUTHORIZED) {
                            props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                            if (refreshTimeout) setTimeout(() => {
                                window.location.href = '/';
                            }, 5000);
                            setTrackerContactMessages([]);
                            setContactSelected(undefined);
                        } else if (isSuccess(returned.status)) {
                            if (isMounted) {
                                const returnedData = returned.data;
                                setUnreadMessages(returnedData?.reduce((p: number, c: any) => p +=
                                    (!c.assumedBy || !c.assumedBy?._id || c.assumedBy?._id === customerId) ?
                                        c.unreadMessages || 0 : 0, 0) || 0);
                                if (contactSelected && contactSelected._id) {
                                    for (let i = 0; i < returnedData.length; i++) {
                                        const returnedDataItem = returnedData[i];
                                        if (returnedDataItem._id === contactSelected._id && contactSelected.assumedBy?._id === customerId && returnedDataItem.unreadMessages > 0) {
                                            returnedDataItem.unreadMessages = 0;
                                            trackerContactMessageService.saveTrackerContactMessage({ _id: returnedDataItem._id, unreadMessages: returnedDataItem.unreadMessages });
                                        }
                                    }
                                }
                                // console.log('returned.data: ', returned.data);
                                updateListContact(returned.data, true);
                            }
                            if (refreshTimeout) setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshContactList);
                        } else {
                            setTrackerContactMessages([]);
                            setContactSelected(undefined);
                            if (refreshTimeout) setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshContactList);
                        }
                    } else {
                        setTrackerContactMessages([]);
                        setContactSelected(undefined);
                        setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshContactList);
                    }
                }
                if (unloadingScreen) prepareLoadingScreen(false);
            }).catch(error => {
                console.error('error', error);
                // if (isMounted) { }
                if (refreshTimeout) setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshContactList);
                if (unloadingScreen) prepareLoadingScreen(false);
            });
            // }
            return () => { isMounted = false };
        } else {
            if (refreshTimeout) setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshContactList);
        }
    };

    // const addItensInDataList = (fetchedList: any[], dataList: any[], setDataList: (value: any[]) => void) => {
    const addItensInDataList = (fetchedList: any[], dataList2: any[], setDataList: any) => {
        // const concatBranchGymData = fetchedList.concat(dataList);
        setDataList((dataList: any[]) => {
            const concatBranchGymData = fetchedList.concat(dataList);
            concatBranchGymData.filter((value: any, index: number, self: any[]) => index === self.findIndex((t) => (t._id === value._id)))
            return concatBranchGymData;
        });
    }

    const refreshListBranchGyms = async (unloadingScreen?: boolean) => {
        setLoadingData(true);
        let isMounted = true;

        if (networkGymId) {
            const resultBranchGyms: IPerformRequestResult<IFormDataBranchGym> = await branchGymService.findBranchGymByNetworkGymId(networkGymId);
            // const resultBranchGym = await branchGymService.findAllBranchGymNoBlockedByNetworkGymId(networkGymId);
            if (isMounted) {
                if (isSuccess(resultBranchGyms.status)) {
                    addItensInDataList(resultBranchGyms.data, branchGymData, setBranchGymData);
                    setBranchGymNoBlockedData(resultBranchGyms.data.filter((b: any) => !b.nonpayer && !b.unsubscribe));
                    setLoadingData(false);
                } else {
                    setBranchGymNoBlockedData([]);
                    setLoadingData(false);
                }
            }
        } else {
            if (isMounted) {
                setBranchGymNoBlockedData([]);
                setLoadingData(false);
            }
        }
        if (unloadingScreen) prepareLoadingScreen(false);
    };

    const setContact = (selectContact: any) => {
        // console.log('PASSOU!');
        // prepareLoadingScreen(true);
        if (!contactSelected || (contactSelected && selectContact && selectContact.jid !== contactSelected.jid)) {
            setOptionsMessage(false);
            setMediasMessage(false);
            setContactSelected(selectContact);
            setTagContactsData((tags) => tags ? tags.map(t => {
                t.selected = selectContact.tags?.indexOf(t._id) >= 0;
                return t;
            }) : undefined);
            setScrollMessages(true);
            setOptionsFilters(false);
            setWaitInteraction(true);
        } else {
            setOptionsMessage(false);
            setMediasMessage(false);
            setContactSelected(undefined);
            setTagContactsData((tags) => tags ? tags.map(t => {
                t.selected = false;
                return t;
            }) : undefined);
        }
        // prepareLoadingScreen(false);
    }

    const updateDashboard = async (savedTrackerContactMessage: IPerformRequestResult<unknown>, networkGymId: string | null, branchGymId: string | null, customerId: string, lastAssumedBy: any) => {
        if (savedTrackerContactMessage?.data && savedTrackerContactMessage?.data._id && (savedTrackerContactMessage?.data.networkGym?._id || networkGymId)
            && (savedTrackerContactMessage?.data.branchGym?._id || branchGymId) && customerId) {
            if (!!lastAssumedBy?._id) {
                await new DashboardReportService().saveConversationDashboardReport('dashboard-report-assumed-conversation-frontend',
                    savedTrackerContactMessage?.data.networkGym?._id || networkGymId, savedTrackerContactMessage?.data.branchGym?._id || branchGymId,
                    lastAssumedBy?._id,
                    {
                        contactMessage: savedTrackerContactMessage.data,
                        contactMessageEnumerator: savedTrackerContactMessage?.data.enumerator, flags: {
                            contactMessageAvailabled: true
                        }
                    });
            }

            if (!!lastAssumedBy?._id) {
                await new DashboardReportService().saveGenericConversationDashboardReport('dashboard-report-availabled-conversation-frontend-attendant', 'conversation', 'availabled',
                    savedTrackerContactMessage?.data.networkGym._id || networkGymId, savedTrackerContactMessage?.data.branchGym._id || branchGymId,
                    lastAssumedBy?._id || customerId, savedTrackerContactMessage?.data._id, savedTrackerContactMessage?.data.enumerator,
                    !!lastAssumedBy?._id);
            }

            await new DashboardReportService().saveGenericConversationDashboardReport('dashboard-report-assumed-conversation-frontend-attendant', 'conversation', 'assumed',
                savedTrackerContactMessage?.data.networkGym._id || networkGymId, savedTrackerContactMessage?.data.branchGym._id || branchGymId,
                customerId, savedTrackerContactMessage?.data._id, savedTrackerContactMessage?.data.enumerator);
        }
    }

    const reopenMessage = async () => {
        try {
            prepareLoadingScreen(true);
            setOptionsMessage(false);
            setMediasMessage(false);

            if (customerId) {
                let lastAssumedBy = contactSelected.assumedBy;

                let trackerContactMessageUpdated: any = {
                    _id: contactSelected._id,
                    assumedBy: customerId,
                    lastChange: (await ls.socket.getServerSocketDateTime()).toDate(),
                    guide: '',
                    guideStep: '',
                    activelyInitiatedContact: true,
                    typeInitiatedContact: 'pró-ativa',
                    available: false,
                    new: false,
                    inProgress: true,
                    finished: false
                };
                if (lastAssumedBy?._id) trackerContactMessageUpdated.lastAssumedBy = lastAssumedBy?._id;
                // console.log('trackerContactMessageUpdated: ', trackerContactMessageUpdated);
                const savedTrackerContactMessage = await trackerContactMessageService.saveTrackerContactMessage(trackerContactMessageUpdated);

                if (savedTrackerContactMessage?.data && savedTrackerContactMessage?.data._id && (savedTrackerContactMessage?.data.networkGym?._id || networkGymId)
                    && (savedTrackerContactMessage?.data.branchGym?._id || branchGymId) && customerId) {
                    await new DashboardReportService().saveConversationDashboardReport(
                        'dashboard-report-new-conversation-attendance-reopen',
                        savedTrackerContactMessage?.data.networkGym?._id || networkGymId, savedTrackerContactMessage?.data.branchGym?._id || branchGymId,
                        customerId!,
                        {
                            contactMessage: contactSelected,
                            contactMessageEnumerator: contactSelected.enumerator,
                            flags: {
                                contactMessageReopened: true,
                                contactMessageProactive: true
                            }
                        });
                    // await new DashboardReportService().saveGenericConversationDashboardReport('dashboard-report-assumed-conversation-frontend-attendant', 'conversation', 'assumed',
                    //     savedTrackerContactMessage?.data.networkGym._id || networkGymId, savedTrackerContactMessage?.data.branchGym._id || branchGymId,
                    //     customerId, savedTrackerContactMessage?.data._id, savedTrackerContactMessage?.data.enumerator);
                }

                setContactSelected(savedTrackerContactMessage.data);
            }
        } catch (error) {
            console.error('Error: ', error);
            // console.log('prepareLoadingScreen(false);');
            prepareLoadingScreen(false);
        }
    }

    const assumeMessage = async () => {
        try {
            prepareLoadingScreen(true);
            setOptionsMessage(false);
            setMediasMessage(false);
            if (customerId) {

                let lastAssumedBy = contactSelected.assumedBy;

                let trackerContactMessageUpdated: any = {
                    _id: contactSelected._id,
                    assumedBy: customerId,
                    lastChange: (await ls.socket.getServerSocketDateTime()).toDate(),
                    guide: '',
                    guideStep: '',
                    available: false,
                    new: false,
                    inProgress: true,
                    finished: false
                };
                if (lastAssumedBy?._id) trackerContactMessageUpdated.lastAssumedBy = lastAssumedBy?._id;

                // console.log('trackerContactMessageUpdated: ', trackerContactMessageUpdated);

                await trackerContactMessageService.updateTrackerContactMessageByFiltered(contactSelected._id, { filter: { $or: [{ assumedBy: lastAssumedBy?._id }, { assumedBy: null }, { assumedBy: { $exists: false } }] }, object: trackerContactMessageUpdated });
                const savedTrackerContactMessage = await trackerContactMessageService.findTrackerContactMessageById(contactSelected._id);
                const updatedContactMessage = savedTrackerContactMessage?.data;

                if (updatedContactMessage) {
                    if (updatedContactMessage.assumedBy?._id === customerId) {
                        if (networkGymId) {
                            await managerSystemMessageService.sendSystemMessage('ASSUMIR_ATENDIMENTO', networkGymId, contactSelected.group, {
                                methodSend: sendMessageToContact,
                                typeShowMessage: EnumTypeShowMessage.botMessage,
                                customerNickname: ls.customer.getCustomerNicknameStorage(),
                                typeObjectMessage: `messagesSender.message.conversation.assumedBy.${customerId}`,
                                callbackMethod: () => {
                                    prepareLoadingScreen(false);
                                    updateDashboard(savedTrackerContactMessage, networkGymId, branchGymId, customerId, lastAssumedBy);
                                    setContactSelected(savedTrackerContactMessage.data);
                                },
                                callbackMethodNotMessage() {
                                    prepareLoadingScreen(false);
                                    updateDashboard(savedTrackerContactMessage, networkGymId, branchGymId, customerId, lastAssumedBy);
                                },
                            });

                            // await sendMessageToContact(`Bem-vindo! Eu sou o *Consultor ${getCustomerNicknameStorage()}* e ficarei feliz em auxiliá-lo. Por favor, me diga como posso ajudar.`,
                            //     EnumTypeShowMessage.botMessage, `messagesSender.message.conversation.assumedBy.${customerId}`
                            // );
                        }
                    } else {
                        prepareLoadingScreen(false);
                        props.args.setMessage({
                            severity: 'warn',
                            summary: `Este atendimento já foi assumido${updatedContactMessage.assumedBy?.nickname ? ` por ${updatedContactMessage.assumedBy?.nickname}` : ''}${updatedContactMessage.branchGym?.name ? ` na unidade ${updatedContactMessage.branchGym?.name}` : ''}!`,
                            detail: '',
                            life: 5000
                        });
                    }
                    // refreshListTrackerContactMessage(true);
                } else {
                    console.error('Error: ', 'Não retornou dados atualizados.');
                    prepareLoadingScreen(false);

                }
            }
        } catch (error) {
            console.error('Error: ', error);
            prepareLoadingScreen(false);
        }
    }

    const releaseMessage = async () => {
        try {
            prepareLoadingScreen(true);
            setOptionsMessage(false);
            setMediasMessage(false);

            if (contactSelected.assumedBy?._id === customerId) {
                let trackerContactMessageUpdated = {
                    _id: contactSelected._id,
                    // assumedBy: null,
                    // lastAssumedBy: trackerContactMessage.assumedBy?._id,
                    lastChange: (await ls.socket.getServerSocketDateTime()).toDate(),
                    available: true,
                    guide: '',
                    guideStep: '',
                    new: false,
                    inProgress: false,
                    finished: false
                };
                // console.log('trackerContactMessageUpdated: ', trackerContactMessageUpdated);
                const savedTrackerContactMessage = await trackerContactMessageService.saveTrackerContactMessage(trackerContactMessageUpdated);

                if (savedTrackerContactMessage?.data && savedTrackerContactMessage?.data._id && (savedTrackerContactMessage?.data.networkGym?._id || networkGymId)
                    && (savedTrackerContactMessage?.data.branchGym?._id || branchGymId) && customerId) {
                    await new DashboardReportService().saveGenericConversationDashboardReport('dashboard-report-released-conversation-frontend-attendant', 'conversation', 'released',
                        savedTrackerContactMessage?.data.networkGym._id || networkGymId, savedTrackerContactMessage?.data.branchGym._id || branchGymId,
                        customerId, savedTrackerContactMessage?.data._id, savedTrackerContactMessage?.data.enumerator, false);
                }
                setContactSelected(savedTrackerContactMessage.data);
            }
        } catch (error) {
            console.error('Error: ', error);
            // console.log('prepareLoadingScreen(false);');
            prepareLoadingScreen(false);
        }
    }

    const redefineBranchGym = async (branchGymId: string) => {
        try {
            selecioneBranchGym(true);
            prepareLoadingScreen(true);

            const oldBranchGym = contactSelected.branchGym;

            const returedBranchGym = await branchGymService.findBranchGymById(branchGymId);
            const branchGym = returedBranchGym?.data;
            // eslint-disable-next-line eqeqeq
            if (oldBranchGym?._id != branchGym._id) {
                // console.log('branchGym: ', branchGym);
                if (branchGym && customerId) {
                    let trackerContactMessageUpdated = {
                        _id: contactSelected._id,
                        assumedBy: null,
                        available: true,
                        new: false,
                        inProgress: false,
                        finished: false,
                        branchGym: branchGymId,
                        guide: '',
                        guideStep: 'finished'
                    }
                    const savedTrackerContactMessage: any = await trackerContactMessageService.saveTrackerContactMessage(trackerContactMessageUpdated);

                    updateOldSchedules(oldBranchGym, branchGym);

                    dashboardReportRedefineReports(savedTrackerContactMessage, branchGymId, branchGym, customerId);

                    if (methodBranchGymData) await methodBranchGymData.method();
                    else {
                        // const branchGyms = await ls.branchGym.getBranchGymsByStorageToken();
                        // const branchGymLocated = branchGyms?.find(b => b.branchGym === branchGymId);
                        // if (branchGymLocated) props.args.select('branchGym', branchGymId);
                    }

                }
            }
            prepareLoadingScreen(false);
            setOptionsMessage(false);
            setMediasMessage(false);

            // refreshListTrackerContactMessage(true);
        } catch (error) {
            console.error('Error: ', error);
            // console.log('prepareLoadingScreen(false);');
            prepareLoadingScreen(false);
        }
    }

    async function updateOldSchedules(oldBranchGym: any, branchGym: any) {
        if (oldBranchGym?._id) {
            const returnSchedulesToChanges = await scheduleService.findScheduleByQuery({
                queries: [
                    { field: 'guest', value: contactSelected._id },
                    { field: 'branchGym', value: oldBranchGym._id },
                    { field: 'date', value: { $gte: (await ls.socket.getServerSocketDateTime()).add(15, 'minutes').toDate() } },
                ]
            });

            if (isSuccess(returnSchedulesToChanges.status) && isArray(returnSchedulesToChanges.data)) {
                const schedulesToChanges = returnSchedulesToChanges.data;
                schedulesToChanges.forEach(async (s: any) => await scheduleService.saveSchedule({
                    _id: s._id,
                    dataScheduleExtra: `${s.dataScheduleExtra}${s.dataScheduleExtra.length > 0 ? '\n\n' : ''}${(await ls.socket.getServerSocketDateTime()).format('DD/MM/YYYY HH:mm')}: A conversa que gerou este agendamento foi redirecionada para unidade '${branchGym.name}'.`
                }));
            }
        }
    }

    const methodUpdateQuestions = async () => {
        if (networkGymId) {
            let isBlockedQuestions = await managerSystemMessageService.isBlockedSystemMessage('AVALIE_ATENDIMENTO', networkGymId);
            let trackerContactMessageUpdated = {
                _id: contactSelected._id,
                assumedBy: null,
                lastAssumedBy: contactSelected.assumedBy?._id,
                lastChange: (await ls.socket.getServerSocketDateTime()).toDate(),
                available: false,
                new: false,
                inProgress: false,
                finished: true,
                guide: !contactSelected.activelyInitiatedContact && !isBlockedQuestions ? 'nps.question' : '',
                guideStep: !contactSelected.activelyInitiatedContact && !isBlockedQuestions ? 'step-1' : ''
            };
            await trackerContactMessageService.saveTrackerContactMessage(trackerContactMessageUpdated);
        }
    };

    const methodUpdateDirectQuestions = async () => {
        if (networkGymId) {
            await managerSystemMessageService.sendSystemMessage('AVALIE_ATENDIMENTO', networkGymId, contactSelected.group, {
                methodSend: sendMessageToContact,
                typeShowMessage: EnumTypeShowMessage.npsQuestionHidden,
                customerNickname: ls.customer.getCustomerNicknameStorage(),
                npsOptions: '1. Ruim\n2. Razoável\n3. Bom\n4. Muito bom\n5. Excelente',
                typeObjectMessage: `messagesSender.message.conversation.assumedBy.${customerId}`,
                callbackMethod: async () => {
                    let isBlockedQuestions = await managerSystemMessageService.isBlockedSystemMessage('AVALIE_ATENDIMENTO', networkGymId);
                    let trackerContactMessageUpdated = {
                        _id: contactSelected._id,
                        assumedBy: null,
                        lastAssumedBy: contactSelected.assumedBy?._id,
                        lastChange: (await ls.socket.getServerSocketDateTime()).toDate(),
                        available: false,
                        new: false,
                        inProgress: false,
                        finished: true,
                        guide: !contactSelected.activelyInitiatedContact && !isBlockedQuestions ? 'nps.question' : 'wait',
                        guideStep: !contactSelected.activelyInitiatedContact && !isBlockedQuestions ? 'step-2' : ''
                    };
                    await trackerContactMessageService.saveTrackerContactMessage(trackerContactMessageUpdated);
                },
            });
        }
    };

    const finishMessage = async () => {
        try {
            prepareLoadingScreen(true);
            setOptionsMessage(false);
            setMediasMessage(false);

            let trackerContactMessageUpdated = {
                _id: contactSelected._id,
                assumedBy: null,
                lastAssumedBy: contactSelected.assumedBy?._id,
                lastChange: (await ls.socket.getServerSocketDateTime()).toDate(),
                available: false,
                new: false,
                inProgress: false,
                finished: true,
                guide: 'wait',
                guideStep: ''
            };
            const savedTrackerContactMessage = await trackerContactMessageService.saveTrackerContactMessage(trackerContactMessageUpdated);

            if (customerId && savedTrackerContactMessage && savedTrackerContactMessage.data && savedTrackerContactMessage.data?._id) {

                if (!contactSelected.activelyInitiatedContact && networkGymId) {
                    await managerSystemMessageService.sendSystemMessage('ENCERRAR_ATENDIMENTO', networkGymId, contactSelected.group, {
                        methodSend: sendMessageToContact,
                        typeShowMessage: EnumTypeShowMessage.botMessage,
                        customerNickname: ls.customer.getCustomerNicknameStorage(),
                        typeObjectMessage: `messagesSender.message.conversation.assumedBy.${customerId}`,
                        callbackMethod: methodUpdateQuestions,
                        callbackMethodNotMessage: methodUpdateDirectQuestions,
                    });
                    // await sendMessageToContact('Muito obrigado pela atenção. Foi um prazer conversar com você. Há menos que haja algo mais em que possa auxiliar encerrarei por aqui. Estarei à sua disposição sempre que necessitar. Até breve!',
                    //     EnumTypeShowMessage.botMessage,
                    //     `messagesSender.message.conversation.assumedBy.${customerId}`);
                }

                new DashboardReportService().saveConversationDashboardReport('dashboard-report-finished-frontend-conversation',
                    savedTrackerContactMessage?.data?.networkGym?._id || networkGymId, savedTrackerContactMessage?.data?.branchGym?._id || branchGymId,
                    customerId,
                    {
                        contactMessage: savedTrackerContactMessage.data,
                        contactMessageEnumerator: savedTrackerContactMessage?.data.enumerator, flags: {
                            contactMessageFinished: true
                        }
                    });

                new DashboardReportService().saveGenericConversationDashboardReport('dashboard-report-finished-conversation-frontend-attendant', 'conversation', 'finished',
                    savedTrackerContactMessage?.data?.networkGym._id, savedTrackerContactMessage?.data?.branchGym?._id, customerId, savedTrackerContactMessage?.data?._id,
                    savedTrackerContactMessage?.data?.enumerator);
            }

            setTrackerContactMessages([]);
            setContactSelected(undefined);
            // if (!checkRoleHierarchyByToken(RoleRules.manager)) {
            // } else {
            //     setContactSelected(savedTrackerContactMessage.data);
            // }

            prepareLoadingScreen(false);

            // refreshListTrackerContactMessage(true);
        } catch (error) {
            console.error('Error: ', error);
            // console.log('prepareLoadingScreen(false);');
            prepareLoadingScreen(false);
        }
    }

    // const routerMessage = async () => {
    //     try {
    //         prepareLoadingScreen(true);
    //         setOptionsMessage(false);
    //         setMediasMessage(false);

    //         const oldBranchGym = contactSelected.branchGym;

    //         let trackerContactMessageUpdated = {
    //             _id: contactSelected._id,
    //             assumedBy: null,
    //             lastAssumedBy: contactSelected.assumedBy?._id,
    //             lastChange: (await ls.socket.getServerSocketDateTime()).toDate(),
    //             available: false,
    //             new: true,
    //             inProgress: false,
    //             finished: false,
    //             branchGym: null,
    //             guide: 'findBranchGym',
    //             guideStep: 'step-1.2'
    //         };
    //         const savedTrackerContactMessage = await trackerContactMessageService.saveTrackerContactMessage(trackerContactMessageUpdated);

    //         const returnSchedulesToChanges = await scheduleService.findScheduleByQuery({
    //             queries: [
    //                 { field: 'guest', value: contactSelected._id },
    //                 { field: 'branchGym', value: oldBranchGym._id },
    //                 { field: 'date', value: { $gte: (await ls.socket.getServerSocketDateTime()).add(15, 'minutes').toDate() } },
    //             ]
    //         });

    //         if (isSuccess(returnSchedulesToChanges.status) && isArray(returnSchedulesToChanges.data)) {
    //             const schedulesToChanges = returnSchedulesToChanges.data;
    //             schedulesToChanges.forEach(async (s: any) =>
    //                 await scheduleService.saveSchedule({
    //                     _id: s._id,
    //                     dataScheduleExtra: `${s.dataScheduleExtra}${s.dataScheduleExtra.length > 0 ? '\n\n' : ''}${(await ls.socket.getServerSocketDateTime()).format('DD/MM/YYYY HH:mm')}: A conversa que gerou este agendamento foi redirecionada.`
    //                 }));
    //         }

    //         if (customerId && savedTrackerContactMessage && savedTrackerContactMessage.data && savedTrackerContactMessage.data._id) {
    //             await new DashboardReportService().saveConversationDashboardReport('dashboard-report-routed-conversation-frontend',
    //                 savedTrackerContactMessage?.data.networkGym?._id || networkGymId, savedTrackerContactMessage?.data.branchGym?._id || branchGymId,
    //                 customerId,
    //                 {
    //                     contactMessage: savedTrackerContactMessage.data,
    //                     contactMessageEnumerator: savedTrackerContactMessage?.data.enumerator, flags: {
    //                         contactMessageRouted: true
    //                     }
    //                 });

    //             await new DashboardReportService().saveGenericConversationDashboardReport('dashboard-report-routed-conversation-frontend-attendant', 'conversation', 'routed',
    //                 savedTrackerContactMessage.data.networkGym._id, savedTrackerContactMessage.data.branchGym._id, customerId, savedTrackerContactMessage.data._id,
    //                 savedTrackerContactMessage.data.enumerator);
    //         }

    //         setContactSelected(savedTrackerContactMessage.data);

    //         // refreshListTrackerContactMessage(true);
    //     } catch (error) {
    //         console.error('Error: ', error);
    //         // console.log('prepareLoadingScreen(false);');
    //         prepareLoadingScreen(false);
    //     }
    // }

    const defineContactData = async () => {
        let mobilePhone = contactSelected.jid;
        if (mobilePhone.indexOf('@') > 0) mobilePhone = mobilePhone.substring(0, mobilePhone.indexOf('@'));
        else mobilePhone = '';

        setEditFormData({
            _id: contactSelected._id,
            pushName: contactSelected.pushName,
            mobilePhone: mobilePhone,
            dataExtra: contactSelected.dataExtra,
            active: true,
            deleted: false
        });
        setDialogData(true);
    }

    const openSchedule = async () => {
        setEditScheduleData({
            key: makeKeyByString(`${networkGymId}_${branchGymId}_${contactSelected._id}_${customerId}_${ls.socket.getServerSocketDateTime().toDate()}`),
            networkGym: contactSelected.networkGym?._id || contactSelected.networkGym,
            branchGym: contactSelected.branchGym?._id || contactSelected.branchGym,
            date: momentRoundMinutes((await ls.socket.getServerSocketDateTime()), 30).toDate(),
            datePristine: (await ls.socket.getServerSocketDateTime()).toDate(),
            statusSchedule: 'opened',
            typeSchedule: { id: 'visit', name: 'VISITA PROGRAMADA', description: 'Visita Programada' },
            guest: contactSelected._id,
            guestName: contactSelected.pushName,
            invitedBy: customerId!,
            confirmedPresence: false,
            attendedAcademy: false,
            closedContract: false,
            dataScheduleExtra: '',
            deleted: false,
            active: true
        })
        setScheduleDialogData(true);
    }

    const sendMessage = async () => {
        prepareLoadingScreen(true);
        setOptionsMessage(false);
        setMediasMessage(false);
        await sendMessageToContact(messageToSend, EnumTypeShowMessage.interactionMessage);
        prepareLoadingScreen(false);
        setNewMessageToSend('');

        if (inputMessageSend && inputMessageSend.current) inputMessageSend.current.style.height = '45px';
        inputMessageSend.current?.focus();
    }

    async function sendMessageToContact(messageToSend: string, typeShowMessage: EnumTypeShowMessage, typeObjectMessage?: string) {
        try {
            if (messageToSend && messageToSend.length > 0 && contactSelected.networkGym?._id && contactSelected.branchGym?._id && contactSelected.trackerMobile?._id) {
                const dataMessage: IFormDataAttendance = {
                    key: `${contactSelected.jid}.web.whatsapp.${makeKeyByString(messageToSend)}`,
                    typeMessage: 'web.whatsapp',
                    typeObjectMessage: typeObjectMessage || 'messagesSender.message.conversation',
                    typeShowMessage,
                    jid: contactSelected.jid,
                    fromMe: true,
                    text: messageToSend,
                    dateText: (await ls.socket.getServerSocketDateTime()).toDate(),
                    networkGym: contactSelected.networkGym?._id,
                    branchGym: contactSelected.branchGym?._id,
                    trackerMobile: contactSelected.trackerMobile?._id,
                    messageReplyId: messageReplyId || null,
                    originalMessageToReply: originalMessageReply || null,
                    group: false,
                    readReceipt: false,
                    sendedMessage: false,
                    deleted: false,
                    active: true,
                };

                // console.log('dataMessage: ', dataMessage);

                trackerMessageService.saveTrackerMessage(dataMessage);
                // const trackerMessage = await trackerMessageService.saveTrackerMessage(dataMessage);
                // console.log('trackerMessage: ', trackerMessage);
                // data.push(dataMessage);

                // const savedTrackerContactMessage = await trackerContactMessageService.saveTrackerContactMessage({
                trackerContactMessageService.saveTrackerContactMessage({
                    _id: contactSelected._id,
                    inProgress: true,
                    available: false,
                    lastChange: ls.socket.getServerSocketDateTime().toDate()
                });
            }
            scrollToBottom();
            setMessageToSend('');
            setScrollMessages(true);
            setMessageReplyId(undefined);
            setMessageReplyText(undefined);
            setOriginalMessageReply(undefined);
        } catch (error) {
            console.error('Error: ', error);
            // console.log('// prepareLoadingScreen(false);');
            // prepareLoadingScreen(false);
        }
    }

    async function sendMediaMessageToContact(mediaMessageToSend: any, mimetype: string, typeShowMessage: EnumTypeShowMessage, typeObjectMessage?: string) {
        try {
            if (mediaMessageToSend && contactSelected.networkGym?._id && contactSelected.branchGym?._id && contactSelected.trackerMobile?._id) {
                const dataMessage: IFormDataAttendance = {
                    key: `${contactSelected.jid}.web.whatsapp.${makeHash(16)}`,
                    typeMessage: 'web.whatsapp',
                    typeObjectMessage: typeObjectMessage || 'messagesSender.message.conversation',
                    typeShowMessage,
                    mediaBase64: mediaMessageToSend,
                    mimetype,
                    jid: contactSelected.jid,
                    fromMe: true,
                    dateText: (await ls.socket.getServerSocketDateTime()).toDate(),
                    networkGym: contactSelected.networkGym?._id,
                    branchGym: contactSelected.branchGym?._id,
                    trackerMobile: contactSelected.trackerMobile?._id,
                    messageReplyId: messageReplyId || null,
                    originalMessageToReply: originalMessageReply || null,
                    group: false,
                    readReceipt: false,
                    sendedMessage: false,
                    isObject: true,
                    deleted: false,
                    active: true,
                };

                // try {
                //     dataMessage.mimetype = mimeType;
                // } catch (error) {}

                // console.log('dataMessage.mimetype: ', dataMessage.mimetype);

                trackerMessageService.saveTrackerMessage(dataMessage);
                // data.push(dataMessage);

                trackerContactMessageService.saveTrackerContactMessage({
                    _id: contactSelected._id,
                    inProgress: true,
                    available: false,
                    lastChange: ls.socket.getServerSocketDateTime().toDate()
                });

            }
            scrollToBottom();
            setMessageToSend('');
            setScrollMessages(true);
            setMessageReplyId(undefined);
            setMessageReplyText(undefined);
            setOriginalMessageReply(undefined);
        } catch (error) {
            console.error('Error: ', error);
            // console.log('// prepareLoadingScreen(false);');
            // prepareLoadingScreen(false);
        }
    }

    const scrollToBottom = () => {
        messagesEnd?.scrollIntoView({ behavior: 'auto' });
        setScrollMessages(false);
    }

    const checkTagContactMessage = (contactMessage: any, index: number, lastFromMessage?: boolean): StatusContactMessage => {
        // console.log('contactMessage.assumedBy?._id: ', contactMessage.assumedBy?._id);
        // if (contactMessage) {
        //     console.log('contactMessage.inProgress: ', contactMessage.inProgress);
        //     console.log('contactMessage.finished: ', contactMessage.finished);
        //     console.log('contactMessage.assumedBy?._id: ', contactMessage.assumedBy?._id);
        //     console.log('customerId: ', customerId);
        //     console.log('lastFromMessage: ', lastFromMessage);
        //     console.log('moment(contactMessage.lastChange): ', moment(contactMessage.lastChange));
        //     console.log('ls.socket.getServerSocketDateTime(): ', ls.socket.getServerSocketDateTime());
        // }
        if (contactMessage && contactMessage.inProgress && !contactMessage.finished
            && contactMessage.assumedBy?._id && contactMessage.assumedBy?._id !== customerId
            && (lastFromMessage === true
                || (lastFromMessage === false && (!moment(contactMessage.lastChange).isBefore(ls.socket.getServerSocketDateTime().subtract(contactMessage.branchGym?.minutesTimeoutAttendant || 15, 'minutes'))))
            )) {
            return StatusContactMessage.blocked;
        } else if (contactMessage && contactMessage.inProgress && !contactMessage.finished && contactMessage.assumedBy?._id !== customerId) {
            return StatusContactMessage.available;
        }
        if (contactMessage && contactMessage.inProgress && !contactMessage.finished && contactMessage.assumedBy?._id === customerId) {
            return StatusContactMessage.inProgress;
        }
        if (contactMessage && contactMessage.finished) {
            return StatusContactMessage.finished;
        }
        if (contactMessage && contactMessage.available) {
            return StatusContactMessage.available;
        }
        if (contactMessage && contactMessage.new) {
            return StatusContactMessage.newer;
        }
        return StatusContactMessage.unknown;
    }

    const prepareTagContactMessage = (contactMessage: any, index: number, lastFromMessage?: boolean) => {
        const tagContactMessage: string = checkTagContactMessage(contactMessage, index, lastFromMessage);
        let backgroundColor = '#000';
        let fontColor = '#fff';
        let caracter = '';

        if (tagContactMessage === StatusContactMessage.blocked) {
            backgroundColor = '#000';
            fontColor = '#fff';
            caracter = 'A';
        } else if (tagContactMessage === StatusContactMessage.available) {
            backgroundColor = '#00F6';
            fontColor = '#fff';
            caracter = 'D';
        }
        if (tagContactMessage === StatusContactMessage.inProgress) {
            backgroundColor = '#ccf';
            fontColor = '#000';
            caracter = 'C';
        }
        if (tagContactMessage === StatusContactMessage.finished) {
            backgroundColor = '#F006';
            fontColor = '#fff';
            caracter = 'E';
        }
        if (tagContactMessage === StatusContactMessage.available) {
            backgroundColor = '#00F6';
            fontColor = '#fff';
            caracter = 'D';
        }
        if (tagContactMessage === StatusContactMessage.newer) {
            backgroundColor = '#FF06';
            fontColor = '#000';
            caracter = 'N';
        }

        if (caracter !== '') {
            return <div className='contact-status' style={{ backgroundColor, color: fontColor }}>
                <span>{caracter}</span>
            </div>;
        }

        return null;
    }

    const prepareTagContactMessageTooltip = (contactMessage: any, index: number, lastFromMessage: boolean) => {
        // console.log('contactMessage: any, index: number, lastFromMessage: boolean: ', contactMessage, index, lastFromMessage);
        const checkTag: StatusContactMessage = checkTagContactMessage(contactMessage, index, lastFromMessage);

        switch (checkTag) {
            case StatusContactMessage.blocked:
                return <Tooltip target={`.contact_message_${index}`} content='Em atendimento' mouseTrack mouseTrackLeft={10} />;
            case StatusContactMessage.available:
                return <Tooltip target={`.contact_message_${index}`} content='Disponível' mouseTrack mouseTrackLeft={10} />;
            case StatusContactMessage.inProgress:
                return <Tooltip target={`.contact_message_${index}`} content='Conversa em andamento' mouseTrack mouseTrackLeft={10} />;
            case StatusContactMessage.finished:
                return <Tooltip target={`.contact_message_${index}`} content='Encerrado' mouseTrack mouseTrackLeft={10} />;
            // eslint-disable-next-line no-duplicate-case
            case StatusContactMessage.available:
                return <Tooltip target={`.contact_message_${index}`} content='Disponível' mouseTrack mouseTrackLeft={10} />;
            case StatusContactMessage.newer:
                return <Tooltip target={`.contact_message_${index}`} content='Novo Contato' mouseTrack mouseTrackLeft={10} />;
            default:
                break;
        }
        return null;
    }

    const renderDateOrTime = (c: any) => {
        return moment(c.lastChange || c.updatedAt).format('yyyyMMDD') !== moment().format('yyyyMMDD') ?
            <span className={classNames('time', `tooltip_${moment(c.lastChange || c.updatedAt).format('yyyyDDMMHHmmss')}`)}>{moment(c.lastChange || c.updatedAt).format('DD/MM')}</span>
            :
            <span className={classNames('time', `tooltip_${moment(c.lastChange || c.updatedAt).format('yyyyDDMMHHmmss')}`)}>{moment(c.lastChange || c.updatedAt).format('HH:mm')}</span>;
    }

    const renderContactMessages = () => {
        if (!trackerContactMessages) return <></>;

        let contactList = trackerContactMessages.sort((t1: any, t2: any) => moment(t2.lastChange).diff(moment(t1.lastChange)));

        contactList = filterContactMessage && filterContactMessage.length > 0 ? contactList.filter(c =>
            (c.pushName && c.pushName.toLowerCase().includes(filterContactMessage.toLowerCase())) ||
            (c.groupName && c.groupName.toLowerCase().includes(filterContactMessage.toLowerCase())) ||
            (c.jid.includes(filterContactMessage.toLowerCase()))) : contactList;

        contactList = contactList.filter(function (item, pos, self) {
            return self.findIndex(s => s.jid === item.jid) === pos;
        });

        contactList = contactList.filter((c: any) => c.jid !== 'status@broadcast');

        contactList = contactList.filter((c: any) => c.group === false);

        // eslint-disable-next-line array-callback-return
        let filteredContactMessages = contactList.filter((c: any, index: number) => {
            let lastFromMessage = c.lastFromMessage;
            let tagContactMessage = checkTagContactMessage(c, index, lastFromMessage);
            if (selectActiveStatus && selectEndStatus && selectMyContacts) {
                return ((tagContactMessage === StatusContactMessage.newer || tagContactMessage === StatusContactMessage.blocked || tagContactMessage === StatusContactMessage.inProgress
                    || tagContactMessage === StatusContactMessage.available) || tagContactMessage === StatusContactMessage.finished)
                    && (c.assumedBy?._id === ls.customer.getCustomerIdStorageToken() || tagContactMessage === StatusContactMessage.finished);
            } else if (selectActiveStatus && !selectEndStatus && selectMyContacts) {
                return (tagContactMessage === StatusContactMessage.newer || tagContactMessage === StatusContactMessage.blocked || tagContactMessage === StatusContactMessage.inProgress
                    || tagContactMessage === StatusContactMessage.available)
                    // || tagContactMessage === StatusContactMessage.finished
                    || c.assumedBy?._id === ls.customer.getCustomerIdStorageToken();
            } else if (selectActiveStatus && selectEndStatus && !selectMyContacts) {
                return (tagContactMessage === StatusContactMessage.newer || tagContactMessage === StatusContactMessage.blocked || tagContactMessage === StatusContactMessage.inProgress
                    || tagContactMessage === StatusContactMessage.available) || tagContactMessage === StatusContactMessage.finished;
                // || c.assumedBy?._id === ls.customer.getCustomerIdStorageToken();
            } else if (selectActiveStatus && !selectEndStatus && !selectMyContacts) {
                return (tagContactMessage === StatusContactMessage.newer || tagContactMessage === StatusContactMessage.blocked || tagContactMessage === StatusContactMessage.inProgress
                    || tagContactMessage === StatusContactMessage.available);
                // || tagContactMessage === StatusContactMessage.finished)
                // && (c.assumedBy?._id === ls.customer.getCustomerIdStorageToken() || tagContactMessage === StatusContactMessage.finished);
            } else if (!selectActiveStatus && selectEndStatus && selectMyContacts) {
                return tagContactMessage === StatusContactMessage.finished || c.assumedBy?._id === ls.customer.getCustomerIdStorageToken();
            } else if (!selectActiveStatus && !selectEndStatus && selectMyContacts) {
                // - OK
                return c.assumedBy?._id === ls.customer.getCustomerIdStorageToken() || tagContactMessage === StatusContactMessage.newer || tagContactMessage === StatusContactMessage.available;
            } else if (!selectActiveStatus && selectEndStatus && !selectMyContacts) {
                return (tagContactMessage === StatusContactMessage.newer || tagContactMessage === StatusContactMessage.blocked || tagContactMessage === StatusContactMessage.inProgress
                    || tagContactMessage === StatusContactMessage.available) || tagContactMessage === StatusContactMessage.finished;
            } else if (!selectActiveStatus && !selectEndStatus && !selectMyContacts) {
                return true;
            }
        });

        filteredContactMessages = filteredContactMessages.filter((c: any, index: number) => {
            let valid = true;
            if (tagFiltersData) {
                let filteredList: any[] = tagFiltersData.filter(t => !t.blocked && t.selected)
                let filtered = filteredList.find((t: IFormDataTag) => c.tags?.includes(t._id));
                valid = filtered || filteredList.length === 0;
            }

            return valid;
        });

        return filteredContactMessages.map((c: any, index: number) => {
            // const lastMessages = listLastMessages.filter((l: any) => l.trackerContactMessageId === c._id);
            // console.log('c.lastFromMessage: ', c.lastFromMessage);
            let lastFromMessage = c.lastFromMessage;

            const contactResponsibleNickName = c.assumedBy && c.assumedBy?.nickname ?
                `${c.assumedBy?.nickname || ''} ${c.branchGym && c.branchGym.name ? `(${c.branchGym.name.trim()})` : ''}` : '';

            const contactLastResponsibleNickName = c.lastAssumedBy && c.lastAssumedBy.nickname && checkTagContactMessage(c, index, lastFromMessage) === StatusContactMessage.finished ?
                `${c.lastAssumedBy?.nickname || ''} ${c.branchGym && c.branchGym.name ? `(${c.branchGym.name.trim()})` : ''}` :
                `${c.branchGym && c.branchGym.name ? `(${c.branchGym.name.trim()})` : ''}`;

            let listTags = tagsData ? [...tagsData.map(t => Object.assign({}, t))] : [];

            let tagListContact = listTags ? listTags.map((t) => {
                t.selected = c.tags?.indexOf(t._id) >= 0;
                return t;
            }) : [];

            tagListContact = tagListContact.filter(t => !t.blocked && t.selected).length > 0 ? tagListContact.filter(t => !t.blocked && t.selected) : [];

            const contactTag = <div key={c.jid}>
                <div className={classNames('chat', `contact_message_${index}`,
                    { newer: (c && c.newer) || (checkTagContactMessage(c, 0, lastFromMessage) === StatusContactMessage.newer) },
                    { available: (c && c.available) || (checkTagContactMessage(c, 0, lastFromMessage) === StatusContactMessage.available) },
                    { inProgress: c && c.inProgress },
                    { finished: c && c.finished },
                    { active: c && c.jid === contactSelected?.jid },
                    { wait: waitInteraction && c.jid !== contactSelected?.jid },
                    { nowait: !waitInteraction && c.jid !== contactSelected?.jid },
                    { ignoredContact: c.jid === contactSelected?.jid })}
                    onClick={() => !waitInteraction && c.jid !== contactSelected?.jid ? setContact(c) : null} style={{ minHeight: 60 }}>
                    <div className='details'>
                        {/* <div className='imgBx'> <Image src='assets/imgs/img1.jpg' alt='' /> </div> */}
                        {c.group ? <i className='pi pi-users' /> : <i className='pi pi-user' />}
                        <div className={classNames('name', { 'group': c.group })}>
                            <span>{limitCharacters(c.pushName || '(não identificado)', 48)}</span>
                        </div>
                        {renderDateOrTime(c)}
                        {c.unreadMessages && c.unreadMessages !== 0 ?
                            <Badge className='unread-messages' value={`${c.unreadMessages > 9 ? '+9' : c.unreadMessages}`} severity='warning' />
                            : null}
                        {prepareTagContactMessage(c, index, lastFromMessage)}
                        <i className='pi pi-whatsapp' />

                        {/* <Tooltip target={`.tooltip_${moment(c.lastChange || c.updatedAt).format('yyyyDDMMHHmmss')}`} content={`${moment(c.lastChange || c.updatedAt).format('DD-MM-yyyy HH:mm')}`} /> */}
                        {contactResponsibleNickName ?
                            <span className='responsible-name'>{contactResponsibleNickName}</span>
                            : contactLastResponsibleNickName ?
                                <span className='responsible-name'>{contactLastResponsibleNickName}</span>
                                : null}
                        {
                            tagListContact.length >= 1 ?
                                tagListContact.length >= 2 ?
                                    <>
                                        <span className="pi pi-bookmark-fill tag-1" style={{ color: `#${tagListContact[1].tagColor}` }}></span>
                                        <span className="pi pi-bookmark-fill tag-2" style={{ color: `#${tagListContact[0].tagColor}` }}></span>
                                    </>
                                    : <span className="pi pi-bookmark-fill tag-1" style={{ color: `#${tagListContact[0].tagColor}` }}></span>
                                : null
                        }
                        <div className='msgs'> <b className='num'></b> </div>
                    </div>
                </div>
                {prepareTagContactMessageTooltip(c, index, lastFromMessage)}
            </div>;

            const contactTagList = [contactTag];
            // for (let i = 0; i < 25; i++) {
            //     contactTagList.push(contactTag);

            // }

            return (contactTagList);
        });
    };

    const customBase64Uploader = async (event: { files: any[]; }) => {
        prepareLoadingScreen(true);
        // convert file to base64 encoded
        const file = event.files[0];
        // console.log('event.files[0]: ', event.files[0]);
        if (file.size >= 10240000) {
            props.args.setMessage({ severity: 'warn', summary: 'O arquivo a ser enviado não pode ser superior a 10MB de tamanho.!', detail: '', life: 5000 });
            prepareLoadingScreen(false);
        } else {
            const reader = new FileReader();
            // let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url

            reader.readAsDataURL(file);

            reader.onloadend = async () => {
                // console.log('reader.result: ', reader.result);
                const mimetype = MimeType.getType(file.name);
                // console.log('mimetype: ', mimetype);

                if (mimetype) {
                    await sendMediaMessageToContact(reader.result, mimetype, EnumTypeShowMessage.interactionMessage, `messagesSender.media.message.${customerId}`);
                } else {
                    props.args.setMessage({ severity: 'warn', summary: 'Tipo de arquivo não reconhecido ou inválido!', detail: '', life: 5000 });
                }
                prepareLoadingScreen(false);
            };

            reader.onerror = async () => {
                props.args.setMessage({ severity: 'danger', summary: 'Erro ao carregar o arquivo!', detail: '', life: 5000 });
            }
        }
        setMediasMessage(false);
    };

    const selecioneBranchGym = (value: boolean, method?: any) => {
        setDialogBranchGymData(value);
        setMethodBranchGymData(method);
        // if (method) setMethodBranchGymData(() => method());
        // else setMethodBranchGymData(undefined);
        // console.log('selecioneBranchGym: ', value, method);
    }

    const setNewMessageToSend = (text: string) => {
        let regExp = RegExp(/[\n|\n\r]/, 'g');
        let countResult = 1;
        let m;
        // eslint-disable-next-line no-cond-assign, @typescript-eslint/no-unused-vars
        while (m = regExp.exec(text)) {
            countResult++;
        }
        let rowsSizeCount = Math.ceil((countResult - 1));
        // if (rowsSizeCount < 7) 
        setMessageToSend(text);
        rowsSizeCount = rowsSizeCount > 7 ? 7 : rowsSizeCount;
        rowsSizeCount = rowsSizeCount * 16.6667 + 58;
        rowsSizeCount = rowsSizeCount > 150 ? 150 : rowsSizeCount;

        rowsSizeCount = optionsMessage && rowsSizeCount < 100 ? 100 : rowsSizeCount;

        setRowsSizeCount(rowsSizeCount);
    }
    // alert( 'there are ' + numberOfLines + ' lines in the text area');

    const prettyMessage = (message: string) => {
        return message.replace(/```([a-z][\S\s]+?)```/gmi, '<span style="font-size: 0.7rem; font-style: italic;">$1</span>')
            .replace(/\*([a-z][\S\s]+?)\*/gmi, '<span style="font-weight: bold;">$1</span>')
            .replace(/_([a-z][\S\s]+?)_/gmi, '<span style="font-style: italic;">$1</span>')
            .replace(/\n/g, "<br />");
    }

    const defineControlMessages = () => {
        if (contactSelected && contactSelected._id) {

            let lastFromMessage = contactSelected.lastFromMessage;

            // console.log('hasNotifications: ', hasNotifications);


            if (hasNotifications) {
                return (<div className='flex flex-column p-fluid col-12 align-items-center justify-content-center' style={{ fontSize: '1.2rem', color: '#e73b3b' }}>
                    <span>Não será possível interagir com o contato. </span>
                    <span style={{ fontSize: '0.8rem', color: '#e73b3b' }}>O Whatsapp necessita ser conectado ao sistema na tela de Canais.</span>
                </div>);

            } else if (contactSelected.assumedBy && contactSelected.assumedBy?._id === customerId) {
                return (<>
                    {
                        !networkGymId ? <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                            <h3>Você precisa selecionar uma rede: '{contactSelected.networkGym?.fantasyName}'.</h3>
                        </div> :
                            <>
                                {messageReplyId && messageReplyText && originalMessageReply ?
                                    <>
                                        <div className='message-reply-title'>
                                            <span>Resposta de:</span>
                                        </div>
                                        <div className='message-reply'>
                                            {/* <i className={classNames('pi', 'pi-reply', 'message-heply-header')} ></i>
                                            <Divider layout="vertical" /> */}
                                            <div className='message-reply-cancel' onClick={() => {
                                                setMessageReplyId(undefined);
                                                setMessageReplyText(undefined);
                                                setOriginalMessageReply(undefined);
                                            }}>
                                                <i className={classNames('pi', 'pi-ban')} ></i>
                                            </div>
                                            <Divider layout="vertical" />
                                            <span className='flex-initial flex flex-wrap message-reply-text' dangerouslySetInnerHTML={{ __html: prettyMessage(messageReplyText) }}></span>
                                        </div>
                                    </>
                                    : null}

                                {!optionsMessage && !mediasMessage ?
                                    <div className='flex justify-content-between' style={{ marginLeft: '10px', marginRight: '10px' }}>
                                        {/* RENDER SENDER MESSAGES */}
                                        <Button icon={`pi ${optionsMessage ? 'pi-times-circle' : 'pi-cog'}`} className='button-options'
                                            rounded text
                                            style={{ marginTop: `${optionsMessage ? '5px' : '5px'}` }} aria-label='Funcionalidades'
                                            onClick={() => {
                                                setMessageReplyId(undefined);
                                                setMessageReplyText(undefined);
                                                setOriginalMessageReply(undefined);
                                                setMediasMessage(false);
                                                setOptionsMessage(!optionsMessage);
                                            }} tooltip='Funcionalidades' />

                                        <Button icon={`pi ${mediasMessage ? 'pi-ellipsis-v' : 'pi-ellipsis-h'}`} className='button-options'
                                            rounded text
                                            style={{ marginTop: `${mediasMessage ? '5px' : '5px'}` }} aria-label='Anexar arquivos'
                                            onClick={() => { setOptionsMessage(false); setMediasMessage(!mediasMessage); }} tooltip='Anexar arquivos' />

                                        <InputTextarea ref={inputMessageSend} autoResize={true} value={messageToSend} style={{ width: '100%', maxHeight: 150 }}
                                            onChange={(e) => setNewMessageToSend(e.target.value)}
                                            placeholder='Digite uma mensagem'
                                            onKeyPress={(e) => {
                                                if (e.shiftKey && e.key === 'Enter') {
                                                    setNewMessageToSend(messageToSend);
                                                } else if ((e.ctrlKey || e.metaKey || e.altKey) && e.key === 'Enter') {
                                                    setNewMessageToSend(messageToSend + '\n');
                                                } else if (e.key === 'Enter' && onlyAlphaNumeric(messageToSend).length > 0) {
                                                    e.preventDefault();
                                                    sendMessage();
                                                } else if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            rows={1} cols={30} />

                                        <Button icon='pi pi-send' className='button-options' style={{ marginTop: 5 }} aria-label='enviar'
                                            rounded text
                                            onClick={() => sendMessage()} />
                                    </div> : null}

                                {optionsMessage ?
                                    <div className='flex justify-content-center flex-wrap' style={{ marginLeft: '10px', marginRight: '10px' }}>
                                        {/* RENDER OPTIONS MESSAGES */}
                                        <Button icon={`pi ${optionsMessage ? 'pi-times-circle' : 'pi-cog'}`} className='button-options'
                                            rounded text
                                            style={{ marginTop: `${optionsMessage ? '5px' : '5px'}`, height: '2rem' }} aria-label='Funcionalidades'
                                            onClick={() => { setMediasMessage(false); setOptionsMessage(!optionsMessage); }} tooltip='Funcionalidades' />

                                        <Button icon='pi pi-book' label='Dados contato' className='p-button-rounded p-button-secondary button-messages'
                                            style={{ marginTop: 5 }} aria-label='Dados contato'
                                            onClick={() => defineContactData()} />

                                        <Button icon='pi pi-calendar-plus' label='Criar agendamento' className='p-button-rounded p-button-secondary button-messages'
                                            style={{ marginTop: 5 }} aria-label='Criar agendamento'
                                            onClick={() => openSchedule()} />

                                        {/* {!checkRoleHierarchyByToken(RoleRules.admin, false) && (branchGymNoBlockedData.length > 1) ?
                                                (contactSelected.finished === false && !checkRoleHierarchyByToken(RoleRules.admin, false)) ?
                                                    <Button icon='pi pi-replay' label='Menu principal' className='p-button-rounded p-button-secondary button-messages'
                                                        style={{ marginTop: 5 }} aria-label='Menu principal'
                                                        onClick={() => {
                                                            setShowConfirmateActionMethod({ method: async () => await routerMessage() });
                                                            setShowConfirmateActionText('Deseja realmente solicitar ao contato a seleção de outra unidade?');
                                                            setShowConfirmateAction(true);
                                                        }} />
                                                    : null
                                                : null
                                            } */}

                                        <Button icon='pi pi-car' label='Disponibilizar' className='p-button-rounded p-button-secondary button-messages'
                                            style={{ position: 'relative', marginTop: 5 }} aria-label='Disponibilizar'
                                            onClick={() => releaseMessage()} />

                                        {!checkRoleHierarchyByToken(RoleRules.admin, false) && (branchGymNoBlockedData.length > 1 || !branchGymNoBlockedData.find(b => b._id === branchGymId)) ?
                                            <Button icon='pi pi-th-large' label='Trocar unidade' className='p-button-rounded p-button-secondary button-messages'
                                                style={{ position: 'relative', marginTop: 5 }} aria-label='Trocar unidade'
                                                onClick={() => selecioneBranchGym(true)} /> : null}

                                        <Button icon='pi pi-stop-circle' label='Encerrar' className='p-button-rounded p-button-secondary button-messages'
                                            style={{ position: 'relative', marginTop: 5 }} aria-label='Encerrar'
                                            onClick={() => {
                                                setShowConfirmateActionMethod({ method: async () => await finishMessage() });
                                                setShowConfirmateActionText('Deseja realmente encerrar a conversa?');
                                                setShowConfirmateAction(true);
                                            }} />
                                    </div> : null}

                                {mediasMessage ?
                                    <div className='flex justify-content-around' style={{ marginLeft: '10px', marginRight: '10px' }}>
                                        {/* RENDER MEDIA MESSAGES */}
                                        <Button icon={`pi ${mediasMessage ? 'pi-ellipsis-v' : 'pi-ellipsis-h'}`} className='button-options'
                                            rounded text
                                            style={{ marginTop: `${mediasMessage ? '5px' : '5px'}`, height: '2rem' }} aria-label='Anexar arquivos'
                                            onClick={() => { setOptionsMessage(false); setMediasMessage(!mediasMessage); }} tooltip='Anexar arquivos' />

                                        <FileUpload mode='basic' accept='image/*' maxFileSize={102400000} chooseOptions={{ icon: 'pi pi-image' }} multiple={false} auto chooseLabel='Imagens'
                                            customUpload uploadHandler={(event: { files: any[]; }) => customBase64Uploader(event)} style={{ marginTop: `${mediasMessage ? '10px' : '10px'}` }} />

                                        <FileUpload mode='basic' accept='audio/*' maxFileSize={102400000} chooseOptions={{ icon: 'pi pi-volume-up' }} multiple={false} auto chooseLabel='Arquivos de Áudio'
                                            customUpload uploadHandler={(event: { files: any[]; }) => customBase64Uploader(event)} style={{ marginTop: `${mediasMessage ? '10px' : '10px'}` }} />

                                        {/* <FileUpload mode='basic' accept='video/*' maxFileSize={102400000} chooseOptions={{ icon: 'pi pi-video' }} multiple={false} auto chooseLabel='Vídeos'
                                            customUpload uploadHandler={(event: { files: any[]; }) => customBase64Uploader(event)} style={{ marginTop: `${mediasMessage ? '10px' : '10px'}` }} /> */}

                                        <FileUpload mode='basic' accept='application/pdf' maxFileSize={102400000} chooseOptions={{ icon: 'pi pi-file-pdf' }} multiple={false} auto chooseLabel='PDF`s'
                                            customUpload uploadHandler={(event: { files: any[]; }) => customBase64Uploader(event)} style={{ marginTop: `${mediasMessage ? '10px' : '10px'}` }} />

                                    </div> : null}
                            </>
                    }
                </>)
            } else {
                const buttons: any[] = [];
                if (contactSelected.branchGym && !isArray(contactSelected.branchGym) && (
                    (contactSelected.inProgress
                        && (!contactSelected.assumedBy
                            || (checkTagContactMessage(contactSelected, 0, lastFromMessage) === StatusContactMessage.available
                                && checkRoleHierarchyByToken(RoleRules.attendant, false))
                            || checkRoleHierarchyByToken(RoleRules.manager))
                        && !checkRoleHierarchyByToken(RoleRules.admin, false))
                    ||
                    (contactSelected.available
                        && (!contactSelected.assumedBy || contactSelected.assumedBy?._id !== customerId)
                        && checkRoleHierarchyByTokenOrs([RoleRules.attendant], false))
                    ||
                    (checkRoleHierarchyByToken(RoleRules.manager) && !checkRoleHierarchyByToken(RoleRules.admin, false))
                )) {
                    if (contactSelected.finished === false) {
                        buttons.push(<Button key='button_assume_message' icon='pi pi-check-square' label='Assumir contato' className='p-button-rounded p-button-secondary'
                            style={{ position: 'relative', marginRight: 20, marginTop: 5 }} aria-label='Assumir contato'
                            onClick={() => assumeMessage()} />);

                        buttons.push(<Button icon="pi pi-stop-circle" label='Encerrar' className="p-button-rounded p-button-secondary"
                            style={{ position: 'relative', marginRight: 20, marginTop: 5 }} aria-label="Encerrar"
                            onClick={() => {
                                setShowConfirmateActionMethod({ method: async () => await finishMessage() });
                                setShowConfirmateActionText('Deseja realmente encerrar a conversa?');
                                setShowConfirmateAction(true);
                            }} />);
                    }
                }
                if ((!contactSelected.branchGym || isArray(contactSelected.branchGym)) && !checkRoleHierarchyByToken(RoleRules.admin, false)) {
                    buttons.push(<Button key='select_branchGym' icon='pi pi-th-large' label='Vincular unidade' className='p-button-rounded p-button-secondary'
                        style={{ position: 'relative', marginRight: 20, marginTop: 5 }} aria-label='Vincular unidade'
                        onClick={() => selecioneBranchGym(true)} />);

                    buttons.push(<Button key='button_assume_message' icon="pi pi-check-square" label='Assumir contato' className="p-button-rounded p-button-secondary"
                        style={{ position: 'relative', marginRight: 20, marginTop: 5 }} aria-label="Assumir contato"
                        onClick={() => selecioneBranchGym(true, { method: async () => await assumeMessage() })} />);

                    buttons.push(<Button icon="pi pi-stop-circle" label='Encerrar' className="p-button-rounded p-button-secondary"
                        style={{ position: 'relative', marginRight: 20, marginTop: 5 }} aria-label="Encerrar"
                        onClick={() => selecioneBranchGym(true, {
                            method: () => {
                                setShowConfirmateActionMethod({ method: async () => await finishMessage() });
                                setShowConfirmateActionText('Deseja realmente encerrar a conversa?');
                                prepareLoadingScreen(false);
                                setShowConfirmateAction(true);
                            }
                        })} />);
                } else {

                    // buttons.push(<Button icon='pi pi-book' label='Dados contato' className='p-button-rounded p-button-secondary'
                    //     style={{ position: 'relative', marginRight: 20, marginTop: 5 }} aria-label='Dados contato'
                    //     onClick={() => defineContactData()} />);

                    if (contactSelected.finished === true && !checkRoleHierarchyByToken(RoleRules.admin, false)) {
                        buttons.push(<Button icon='pi pi-upload' label='Reabrir conversa' className='p-button-rounded p-button-secondary'
                            style={{ position: 'relative', marginRight: 20, marginTop: 5 }} aria-label='Reabrir conversa'
                            onClick={() => reopenMessage()} />);

                    }
                }

                // if (branchGymId && trackerContactMessage && !trackerContactMessage.inProgress &&
                //     checkRoleHierarchyByTokenOrs([RoleRules.admin, RoleRules.owner, RoleRules.manager], false)) {
                //     buttons.push(<Button key='button_route_message' icon='pi pi-replay' label='Retornar' className='p-button-rounded p-button-secondary'
                //         style={{ position: 'relative', marginRight: 20, marginTop: 5 }} aria-label='Retornar'
                //         onClick={() => {
                //             setShowConfirmateActionMethod({ method: async () => await routerMessage() });
                //             setShowConfirmateActionText('Deseja realmente solicitar ao contato a seleção de outra unidade?');
                //             setShowConfirmateAction(true);
                //         }} />);
                // }

                if (checkRoleHierarchyByToken(RoleRules.admin, false)) {
                    return (<div className='flex flex-column p-fluid col-12 align-items-center justify-content-center' style={{ fontSize: '1.2rem', color: '#e73b3b' }}>
                        <span>Não será possível interagir com o contato. </span>
                        <span style={{ fontSize: '0.8rem', color: '#e73b3b' }}>O perfil administrador não pode interagir com contatos da academia.</span>
                    </div>);
                } else if (buttons.length === 0 && contactSelected?.assumedBy?.nickname) {
                    return (<div className='flex flex-column p-fluid col-12 align-items-center justify-content-center' style={{ fontSize: '1.2rem', color: '#e73b3b' }}>
                        <span>Não será possível interagir com o contato. </span>
                        <span style={{ fontSize: '0.8rem', color: '#e73b3b' }}>Este atendimento se encontra em andamento com o(a) atendente "{contactSelected?.assumedBy?.nickname}".</span>
                    </div>);
                } else {
                    return <>{buttons}</>
                }

            }

        } else {
            return <></>;
        }
    }

    const select = async (type: string, _id?: string | null, ...args: string[]) => {
        if (type === 'contactMessageBranchGym' && _id) {
            await redefineBranchGym(_id);
            // if (methodBranchGymData) await methodBranchGymData.method();
        }
    };

    const unselect = async (type: string, _id?: string | null, ...args: string[]) => {
        if (type === 'contactMessageBranchGym' && _id) {
            await redefineBranchGym(_id);
            // if (methodBranchGymData) await methodBranchGymData.method();
        }
    };

    const defaultFilters = () => {
        setContactSelected(undefined);
        setMessageChangeDate('');
        setOptionsFilters(false);
        setFilterContactMessage('');

        setTagFiltersData((tags) => tags ? tags.map(t => {
            t.selected = false;
            return t;
        }) : undefined);

        setSelectActiveStatus(false);
        setSelectEndStatus(false);
        setSelectMyContacts(false);

        setSelectStartDateMessages(ls.socket.getServerSocketDateTime().subtract(7, 'days').toDate());
        setStartDateMessages(ls.socket.getServerSocketDateTime().subtract(7, 'days').toDate());
        setSelectEndDateMessages(ls.socket.getServerSocketDateTime().toDate());
        setEndDateMessages(ls.socket.getServerSocketDateTime().toDate());
    }

    const processFilters = (process: boolean) => {
        // if (process) {
        //     setContactSelected(undefined);
        //     if (!activeStatus && !endStatus) {
        //         setMessageChangeStatus('É obrigatória a seleção de ao menos um status. Escolha entre Ativas e/ou Encerradas.');
        //         return;
        //     }
        // }
        // setOptionsFilters(false);
        // setFilterContactMessage('');
        if (process && validStartDate(startDateMessages, endDateMessages) && validEndDate(startDateMessages, endDateMessages)) {
            // if (!activeStatus || endStatus || myContacts ||
            //     moment(selectStartDateMessages).diff(moment(startDateMessages), 'minutes') > 0 ||
            //     moment(selectEndDateMessages).diff(moment(endDateMessages), 'minutes') > 0) setFilterPristine(false);

            // setSelectActiveStatus(activeStatus || true);
            // setSelectEndStatus(endStatus || false);
            // setSelectMyContacts(myContacts);
            setSelectStartDateMessages(startDateMessages);
            setSelectEndDateMessages(endDateMessages);
        } else {
            // setActiveStatus(selectActiveStatus);
            // setEndStatus(selectEndStatus);
            // setMyContacts(selectMyContacts);
            setStartDateMessages(selectStartDateMessages);
            setEndDateMessages(selectEndDateMessages);
        }
    }

    const defineStartDate = (props: CalendarProps | Readonly<CalendarProps>): void => {
        setMessageChangeDate('');
        if (props.value instanceof Date) {
            setStartDateMessages(props.value);
            validStartDate(props.value, endDateMessages);
        }
    }

    const defineEndDate = (props: CalendarProps | Readonly<CalendarProps>): void => {
        setMessageChangeDate('');
        if (props.value instanceof Date) {
            setEndDateMessages(props.value);
            validEndDate(startDateMessages, props.value);
        }
    }

    const validStartDate = (startDateMessages: Date, endDateMessages: Date) => {
        if (moment(startDateMessages).isAfter(endDateMessages)) {
            setMessageChangeDate('Data de início da pesquisa não pode ser superior a data de início.');
            // setEndDateMessages(moment(startDateMessages).add(7, 'days').toDate());
            return false;
        };
        if (moment(startDateMessages).isBefore(moment(endDateMessages).subtract('days', marginDaysFilter))) {
            setMessageChangeDate(`Por favor, informe um período de no máximo ${marginDaysFilter} dias entre a data inicial e final da pesquisa.`);
            // setEndDateMessages(moment(startDateMessages).add(7, 'days').toDate());
            return false;
        }
        return true;
    }

    const validEndDate = (startDateMessages: Date, endDateMessages: Date) => {
        if (moment(endDateMessages).isBefore(startDateMessages)) {
            setMessageChangeDate('Data de fim da pesquisa não pode ser inferior a data de início.');
            // setStartDateMessages(moment(endDateMessages).subtract(7, 'days').toDate());
            return false;
        }
        if (moment(endDateMessages).isAfter(moment(startDateMessages).add(marginDaysFilter, 'days'))) {
            setMessageChangeDate(`Por favor, informe um período de no máximo ${marginDaysFilter} dias entre a data inicial e final da pesquisa.`);
            // setStartDateMessages(moment(endDateMessages).subtract(7, 'days').toDate());
            return false;
        }
        return true;
    }

    const prepareCreateContact = () => {
        if (!branchGymId) {
            props.args.setMessage({
                severity: 'warn',
                summary: 'Filtre uma unidade para iniciar uma nnova conversa!',
                detail: '',
                life: 10000
            })

        } else {
            setEditFormCreateData({
                _id: '',
                countryMobilePhone: '+55',
                districtMobilePhone: '11',
                numberMobilePhone: '',
                mobilePhone: '',
                jid: '',
                pushName: '',
                dataExtra: '',
                isValidWhatsapp: false,
                active: true,
                deleted: false
            });
            setDialogCreateData(true);
        }
    }

    const limitCharactersInput = (fieldFormData: string, size: number, formData: FormikValues, event: React.ChangeEvent<any>) => {
        // console.log('limitCharactersInput');
        let newValue = event.currentTarget.value;
        formData.setFieldValue(fieldFormData, newValue.substring(0, size));
    }

    const saveDashboardSchedule = async (customerId: string | null, schedule: any, networkGymId: string, branchGymId: string | null, contactMessageSelected: any) => {
        if (customerId && schedule && schedule._id) {
            try {
                await new DashboardReportService().saveConversationDashboardReport(
                    'dashboard-report-schedule-conversation-by-chat-frontend',
                    schedule.networkGym?._id || networkGymId, schedule.branchGym?._id || branchGymId,
                    customerId,
                    {
                        contactMessage: contactMessageSelected,
                        contactMessageEnumerator: contactMessageSelected.enumerator, flags: {
                            contactMessageScheduled: true
                        }
                    });

                await new DashboardReportService().saveScheduleDashboardReport(
                    'dashboard-report-schedule-by-chat-frontend',
                    schedule.networkGym?._id || networkGymId, schedule.branchGym?._id || branchGymId,
                    customerId,
                    {
                        schedule: schedule,
                        flags: {
                            scheduleByChat: true,
                            scheduleReason: schedule.typeSchedule,
                            scheduleFinished: schedule.statusSchedule === 'closed'
                        }
                    });

                await new DashboardReportService().saveGenericConversationDashboardReport('dashboard-report-schedule-conversation-frontend-attendant',
                    'conversation', 'scheduled',
                    contactMessageSelected.networkGym?._id || networkGymId, contactMessageSelected.branchGym?._id || branchGymId,
                    customerId, contactMessageSelected._id,
                    contactMessageSelected.enumerator);

                await new DashboardReportService().saveGenericScheduleDashboardReport('dashboard-report-created-schedule-by-chat-frontend-attendant',
                    'schedule', 'created',
                    schedule.networkGym?._id || networkGymId, schedule.branchGym?._id || branchGymId,
                    customerId, schedule._id);
            } catch (error) {
                console.error('Error save dashboard.: ', error);
            }
        }
    }

    const setLoadingDialog = (loading: boolean) => {
        prepareLoadingScreen(loading);
    }

    const changeMobilePhone = async (formData: FormikValues): Promise<void> => {
        setLoadingDialog(true);
        const dataValues = formData.values;
        const dataErrors = formData.errors;
        let invalid: boolean = false;
        let countryMobilePhone, districtMobilePhone, numberMobilePhone;

        countryMobilePhone = dataValues.countryMobilePhone.substring(0, 4);
        if (!countryMobilePhone || countryMobilePhone.length < 1 || countryMobilePhone.substring(0, 1) !== '+' || isNaN(countryMobilePhone)) {
            invalid = true;
        }
        districtMobilePhone = dataValues.districtMobilePhone.substring(0, 3);
        if (!districtMobilePhone || districtMobilePhone.length < 2 || isNaN(districtMobilePhone)) {
            invalid = true;
        }
        numberMobilePhone = dataValues.numberMobilePhone.substring(0, 9);
        let validNumberMobilePhone = numberMobilePhone.replace(/[-|&;$%@'<>()+,]/g, '');
        if (!validNumberMobilePhone || validNumberMobilePhone.length < 8 || isNaN(validNumberMobilePhone)) {
            invalid = true;
        }

        if (invalid) console.warn('Invalid Number!');
        else {

            let contactMessageByJidData: any;

            const numberJid = `${dataValues.countryMobilePhone.replace(/[-|&;$%@'<>()+,]/g, '')}${dataValues.districtMobilePhone.replace(/[-|&;$%@'<>()+,]/g, '')}${dataValues.numberMobilePhone.replace(/[-|&;$%@'<>()+,]/g, '')}`;
            const jid = `${numberJid}@s.whatsapp.net`;

            // console.log('jid: ', jid);

            const trackerMobile = await new TrackerMobileService().findTrackerMobileByNetworkGymId(networkGymId);
            const trackerMobileData = trackerMobile.data;

            // console.log('trackerMobileData: ', trackerMobileData, trackerMobileData.length, trackerMobileData[0]._id);
            if (trackerMobileData && trackerMobileData.length > 0 && trackerMobileData[0]._id) {
                const contactMessageByJid = await new TrackerContactMessageService().findTrackerContactMessageByNetworkGymId(networkGymId, undefined, {
                    queries: [{
                        field: 'jid',
                        value: jid
                    }]
                });
                if (contactMessageByJid && contactMessageByJid.data && contactMessageByJid.data.length > 0) {
                    contactMessageByJidData = (await trackerContactMessageService.findTrackerContactMessageById(contactMessageByJid.data[0]._id)).data;
                }
            }
            let messageAlert: string | undefined = undefined;
            if (contactMessageByJidData && contactMessageByJidData.finished && contactMessageByJidData?.finished !== true) {
                delete dataErrors.numberMobilePhone;
                if (contactMessageByJidData.assumedBy._id !== customerId) {
                    messageAlert = `Existe uma conversa em andamento com este contato`;
                    if (contactMessageByJidData.branchGym && contactMessageByJidData?.branchGym?.name) messageAlert += ` na unidade: ${contactMessageByJidData?.branchGym?.name}`;
                    if (contactMessageByJidData.assumedBy && contactMessageByJidData.assumedBy.nickname) messageAlert += ` com o atendente: ${contactMessageByJidData.assumedBy.nickname}`;
                } else {
                    messageAlert = `Este contato está sendo atendido por você`;
                    if (contactMessageByJidData.branchGym && contactMessageByJidData?.branchGym?.name) messageAlert += ` na unidade: ${contactMessageByJidData?.branchGym?.name}`;
                }
            }
            if (messageAlert) {
                dataErrors.numberMobilePhone = messageAlert;
            }
            if (contactMessageByJidData) {
                delete dataErrors.pushName;
                dataValues.pushName = contactMessageByJidData.pushName;
                delete dataErrors.dataExtra;
                dataValues.dataExtra = contactMessageByJidData.dataExtra;
            }

        }
        setLoadingDialog(false);
    }

    const dashboardReportRedefineReports = async (contactMessage: any, branchGymId: string, branchGym: any, customerId: string) => {

        const returnedDashboardReports = await dashboardReportService.findDashboardReportsBySearch({
            queries: [
                { field: 'report', value: 'access' },
                { field: 'referenceReport', value: 'logon' },
                { field: 'networkGym', value: networkGymId },
                { field: 'branchGym', value: branchGymId },
                { field: 'date', value: { $gte: (await ls.socket.getServerSocketDateTime()).subtract(15, 'minutes').toDate() } },
                { field: 'deleted', value: false },
                { field: 'active', value: true }
            ]
        });
        const dashboardReports = returnedDashboardReports?.status && isSuccess(returnedDashboardReports?.status) ? returnedDashboardReports?.data : undefined;

        if (networkGymId) {
            if (!dashboardReports || dashboardReports?.length === 0) {

                await managerSystemMessageService.sendSystemMessage('REDIRECIONAR_SEM_CONSULTOR', networkGymId, contactSelected.group, {
                    methodSend: sendMessageToContact,
                    typeShowMessage: EnumTypeShowMessage.botMessage,
                    customerNickname: ls.customer.getCustomerNicknameStorage(),
                    branchGymName: branchGym.name,
                    typeObjectMessage: 'messagesSender.message.conversation',
                    callbackMethod: () => { }
                });

                // await trackerMessageService.saveTrackerMessage(createMessage(contactMessage?.data.jid, `${contactMessage?.data?.networkGym?._id || contactMessage?.data?.networkGym}`,
                //     EnumTypeShowMessage.botMessage,
                //     'Você foi redirecionado para *{{branchGym.name}}*.\n\n' +
                //     'No momento *não há consultores disponíveis*. 😬\n\n' +
                //     '*Não se preocupe*, será atendido assim que houver *um consultor disponível*. 😉',
                //     {
                //         trackerMobileId: contactMessage?.data?.trackerMobile?._id, networkGymId: contactMessage?.data?.networkGym?._id || contactMessage?.data?.networkGym,
                //         branchGymId: branchGymId
                //     },
                //     { replaceMessages: [{ key: 'branchGym.name', value: branchGym.name }] }));
            } else {
                await managerSystemMessageService.sendSystemMessage('REDIRECIONAR_ATENDIMENTO', networkGymId, contactSelected.group, {
                    methodSend: sendMessageToContact,
                    typeShowMessage: EnumTypeShowMessage.botMessage,
                    customerNickname: ls.customer.getCustomerNicknameStorage(),
                    branchGymName: branchGym.name,
                    typeObjectMessage: 'messagesSender.message.conversation',
                    callbackMethod: () => { }
                });

                // await trackerMessageService.saveTrackerMessage(createMessage(contactMessage?.data.jid, `${contactMessage?.data?.networkGym?._id || contactMessage?.data?.networkGym}`,
                //     EnumTypeShowMessage.botMessage,
                //     'Você foi redirecionado para *{{branchGym.name}}*.\n\n' +
                //     'Um consultor irá lhe *atender assim que possível*.',
                //     {
                //         trackerMobileId: contactMessage?.data?.trackerMobile?._id, networkGymId: contactMessage?.data?.networkGym?._id || contactMessage?.data?.networkGym,
                //         branchGymId: branchGymId
                //     },
                //     { replaceMessages: [{ key: 'branchGym.name', value: branchGym.name }] }));
            }
        }
        await dashboardReportService.saveConversationDashboardReport('dashboard-report-change-branchgym-attendannce-frontend',
            contactMessage.data?.networkGym?._id || contactMessage.data?.networkGym,
            branchGymId, customerId,
            {
                contactMessage: contactMessage.data,
                contactMessageEnumerator: contactMessage.data.enumerator, flags: {
                    contactMessageRedirectBranchGym: true
                }
            });
    }

    const setShowQuickMessage = async (quickMessage: IFormListDataQuickMessage) => {
        if (quickMessage && quickMessage._id) {
            prepareLoadingScreen(true);
            const resultQuickMessage = await quickMessageService.findQuickMessageById(quickMessage?._id);
            if (resultQuickMessage?.data) {
                setDialogQuickMessageToShow(true);
                setQuickMessageToShow(resultQuickMessage?.data);
                setTextQuickMessage(resultQuickMessage?.data?.text || null);
            }
            // prepareLoadingScreen(false);
        }
    }

    const sendMessageByQuickMessage = async (quickMessage: IFormListDataQuickMessage) => {
        prepareLoadingScreen(true);
        if (quickMessage.isObject) {
            await sendMediaMessageToContact(quickMessage.mediaBase64, quickMessage.mimetype, EnumTypeShowMessage.interactionMessage);
        } else {
            if (textQuickMessage) await sendMessageToContact(textQuickMessage, EnumTypeShowMessage.interactionMessage);
        }
        setDialogQuickMessageToShow(false);
        setTextQuickMessage(null);
        setQuickMessageToShow(null);
        prepareLoadingScreen(false);
    }

    const setSelectTagContact = (tagId: string) => {
        // console.log('setSelectTagContact: ', tagId);
        if (tagContactsData) {
            const tag = tagContactsData.find(t => t._id === tagId);
            if (tag) {
                tag.selected = !tag?.selected;
                trackerContactMessageService.saveTrackerContactMessage({ _id: contactSelected._id, tags: tagContactsData.filter(t => t.selected).map(t => t._id).join() })
            }
            setTagContactsData([...tagContactsData]);
        }
    }

    const setSelectTagFiltered = (tagId: string) => {
        // console.log('setSelectTagContact: ', tagId);
        if (tagFiltersData) {
            const tag = tagFiltersData.find(t => t._id === tagId);
            if (tag) {
                tag.selected = !tag?.selected;
            }
            setTagFiltersData([...tagFiltersData]);
        }
    }

    const releaseWaitInteraction = () => {
        setWaitInteraction(false);
    }

    const maskBuilder = (mobilePhone: string) => {
        return mobilePhone.length === 13 ? '+55 (99) 99999-9999' : mobilePhone.length === 12 ? '+55 (99) 9999-9999' : '+55 (99) 999-9999';
    }

    // console.log('messageReplyId: ', messageReplyId);
    // console.log('messageReplyText: ', messageReplyText);


    if (!networkGymId)
        return (<div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
            <h3>Você precisa selecionar uma Rede.</h3>
        </div>);
    else if (loadingData) return createSkeletons(8);
    else {
        return (
            <>
                <NotificationComponent unreadMessages={unreadMessages} />
                <div className='layout-content-whatsapp-attendance flex' style={{ height: 'calc(100vh - 194px)' }}>
                    <div className='left-side'>
                        <div className='left-side-header'>
                            <div className='search-chat'>
                                <InputText type='text' value={filterContactMessage} placeholder='Buscar por contato' onChange={(e: any) => { setFilterContactMessage(e.currentTarget.value); }} />
                                <i className='pi pi-search'></i>
                                {filterContactMessage ? <i className='pi pi-times' onClick={(e: any) => setFilterContactMessage('')}></i> : null}
                            </div>
                            <div className='action-chat flex justify-content-around'>
                                <Button icon={`pi pi-user-plus`} className='button-action-chat' text aria-label='Iniciar conversa'
                                    onClick={() => { prepareCreateContact(); }} tooltip='Iniciar conversa' tooltipOptions={{ position: 'bottom' }} />

                                <Button icon={`pi pi-star`} className={classNames('button-action-chat', { 'active': selectMyContacts })} text aria-label='Minhas conversas'
                                    onClick={() => { setSelectMyContacts(!selectMyContacts); }} tooltip='Minhas conversas' tooltipOptions={{ position: 'bottom' }} />

                                <Button icon={`pi pi-comments`} className={classNames('button-action-chat', { 'active': selectActiveStatus })} text aria-label='Conversas Ativas'
                                    style={{ display: 'none' }}
                                    onClick={() => { setSelectActiveStatus(!selectActiveStatus); }} tooltip='Conversas Ativas' tooltipOptions={{ position: 'bottom' }} />

                                <Button icon={`pi pi-ban`} className={classNames('button-action-chat', { 'active': selectEndStatus })} text aria-label='Conversas encerradas'
                                    onClick={() => { setSelectEndStatus(!selectEndStatus); }} tooltip='Conversas encerradas' tooltipOptions={{ position: 'bottom' }} />

                                <Button icon={`pi pi-filter-slash`} className={classNames('button-action-chat')} text aria-label='Limpar filtros'
                                    onClick={() => { defaultFilters(); }} tooltip='Limpar filtros' tooltipOptions={{ position: 'bottom' }} />

                            </div>
                        </div>
                        <div className='left-side-content'>
                            {countContacts && countContacts >= limitContactsMessage ?
                                <div className='alert-max-contacts flex flex-column justify-content-center'>
                                    <span className='pi pi-exclamation-triangle warn'></span>
                                    <span>{`O sistema recuperou mais de ${limitContactsMessage} contatos ultrapassando o limite da lista abaixo.`}</span>
                                    <span>{`Favor considerar o encerramento de conversas ou restringir a pesquisa de conversas encerradas.`}</span>
                                </div>
                                : null
                            }
                            <div className={classNames('chat-list', { 'max-contacts': (countContacts && countContacts >= limitContactsMessage) })}>
                                {renderContactMessages()}
                                <div style={{ height: '10px' }}></div>
                            </div>
                        </div>
                        <div className='left-side-footer' />
                        {/* {branchGymId ?
                            <div className='start-chat' style={{ padding: '10px', textAlign: 'right' }}>
                                <Button label='Iniciar conversa' type='button' style={{ fontSize: '0.9rem', minWidth: 150 }}
                                    className='p-button-rounded p-button p-button-success shadow-3' icon='pi pi-plus' />
                            </div> : null} */}
                    </div>
                    <div className={classNames('center-side', { 'center-side-sidebar-open': mobileMenuActive }, { 'center-side-close': !rightSideOpen })}>
                        <div className='center-side-header'>
                            <div style={{ width: '100%', flexWrap: 'nowrap' }}>
                                <div className='grid'>
                                    <div className={classNames({ 'col-10': !optionsFilters }, { 'col-12': optionsFilters })}>
                                        <div className='grid' style={{ marginTop: 2 }}>
                                            <h4 className='col-12 align-items-right' style={{ fontSize: '1rem', maxHeight: 8, whiteSpace: 'nowrap' }}>
                                                {contactSelected ?
                                                    <Button className='p-button-warning' style={{ height: 6, width: 6, fontSize: '0.8rem', marginRight: 10 }} icon='pi pi-times' onClick={() => setContact('')}></Button>
                                                    : null}
                                                <span style={{ display: 'inline-block' }}>{limitCharacters(trackerContactMessages?.find(t => contactSelected && t.jid === contactSelected.jid)?.pushName || '', 100)}</span>
                                            </h4>
                                            <div className='col-12' style={{ marginTop: 14 }}>
                                                {contactSelected ?
                                                    <div className='flex align-items-center justify-content-start' style={{ overflow: 'auto' }}>
                                                        {
                                                            tagContactsData && tagContactsData.filter(t => !t.blocked && t.selected).length > 0 ?
                                                                tagContactsData.filter(t => !t.blocked && t.selected).sort((l1: any, l2: any) => l1.tag < l2.tag ? -1 : l1.tag > l2.tag ? 1 : 0).map(t =>
                                                                    <div key={t._id}>
                                                                        <Tooltip target={`.tag-header-${t._id}`} content={t.tag} position="top" />
                                                                        <div className={classNames('tag-header', `tag-header-${t._id}`)}>
                                                                            <div className='flex align-items-center justify-content-start'>
                                                                                {contactSelected?.assumedBy?._id === customerId || checkRoleHierarchyByToken(RoleRules.manager) ?
                                                                                    <Button className='unselect-tag' style={{ height: 2, width: 2, margin: 2 }} icon='pi pi-times-circle' onClick={() => { if (t._id) setSelectTagContact(t._id); }}></Button>
                                                                                    :
                                                                                    null}
                                                                                <i className={`pi ${t.selected ? 'pi-bookmark-fill' : 'pi-bookmark'} `}
                                                                                    style={{ fontSize: '1rem', color: `#${t.tagColor}`, marginRight: '2px', textShadow: `1px 0 rgba(255, 255, 255, 0.2666666667), -1px 0 rgba(255, 255, 255, 0.2666666667), 0 1px rgba(255, 255, 255, 0.2666666667), 0 -1px rgba(255, 255, 255, 0.2666666667), 1px 1px rgba(255, 255, 255, 0.2666666667), -1px -1px rgba(255, 255, 255, 0.2666666667), 1px -1px rgba(255, 255, 255, 0.2666666667), -1px 1px rgba(255, 255, 255, 0.2666666667)` }} />
                                                                                <span style={{ color: '#000', fontWeight: 'bold', fontSize: '0.8rem', overflow: 'hidden' }}>{t.tag}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='center-side-content'>
                            <div className='center-side-content-message'>
                                <div className='chatBx'>
                                    <AttendanceMessage contactSelected={contactSelected}
                                        setMessageReplyId={setMessageReplyId}
                                        setMessageReplyText={setMessageReplyText}
                                        setOriginalMessageReply={setOriginalMessageReply}
                                        setWaitInteraction={() => releaseWaitInteraction()} args={props.args} />
                                </div>
                            </div>
                            <div className='center-side-controllers'>
                                {contactSelected && !optionsFilters ?
                                    <div style={{ position: 'absolute', background: '#fff', bottom: 0, width: '100%', height: `${optionsMessage === true ? `${rowsSizeCount}px` : `${rowsSizeCount}px`}`, textAlign: 'center' }}>
                                        {defineControlMessages()}
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                        <div className={classNames('center-side-footer', { 'center-side-footer-sidebar-open': mobileMenuActive }, { 'center-side-footer-close': !rightSideOpen })} />
                    </div>
                    <div className={classNames('right-side flex', { 'right-side-open': !rightSideOpen })} style={{ backgroundColor: '#e6ebe4' }}>
                        <div className='right-side-control flex flex-column justify-content-center' onClick={() => setRightSideOpen(!rightSideOpen)}>
                            {rightSideOpen ?
                                <span className='pi pi-arrow-circle-right text-white' style={{ fontWeight: 'bold', fontSize: '1.2rem', marginLeft: '3px', color: '#fff' }}></span>
                                :
                                <span className='pi pi-arrow-circle-left text-white' style={{ fontWeight: 'bold', fontSize: '1.2rem', marginLeft: '3px', color: '#fff' }}></span>
                            }
                        </div>
                        <div className='right-side-content flex flex-column justify-content-start' style={{ overflowY: 'hidden' }}>
                            {contactSelected ?
                                <>
                                    <span className='py-1 w-full text-white font-bold' style={{ backgroundColor: '#118cac', height: '2rem', fontSize: '1.0rem', textAlign: 'center' }}>:: FILTROS  APLICADOS</span>
                                    <Button icon={`pi pi-pencil`} className={classNames('button-filter-chat')} text aria-label='Habilitar filtros'
                                        onClick={() => { setContact(''); }} tooltip='Habilitar filtros' tooltipOptions={{ position: 'left' }} />
                                    <div className='flex flex-colum flex-wrap justify-content-start' style={{ width: '100%', backgroundColor: '#c3dede' }}>
                                        <span className='filter-options filter-options-header'>Exibindo:</span>
                                        {filterContactMessage ? <span className='filter-options'>- Contatos filtrados por: '{filterContactMessage}'</span> : null}
                                        {selectMyContacts ? <span className='filter-options'>- Somente minhas conversas</span> : <span className='filter-options'>- Conversas de todos atendentes</span>}
                                        {/* {selectActiveStatus ? <span className='filter-options'>- Conversas ativas</span> : null} */}
                                        {selectEndStatus ? <span className='filter-options'>- Conversas encerradas</span> : null}
                                        {selectEndStatus ? <span className='filter-options filter-end-options'>
                                            <span>de <span>{moment(selectStartDateMessages).format('DD/MM/YYYY')}</span> até <span>{moment(selectEndDateMessages).format('DD/MM/YYYY')}</span></span>
                                        </span> : null}
                                        {tagFiltersData && tagFiltersData.find(t => !t.blocked && t.selected) ?
                                            <span className='filter-options'>- Com alguma das etiquetas:&nbsp;
                                                {tagFiltersData.filter(t => !t.blocked && t.selected).map(t => t.tag).join(', ')}</span>
                                            :
                                            <span className='filter-options'>- Com ou sem etiquetas</span>}
                                    </div>
                                    <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#118cac', fontSize: '0.8rem' }} />
                                    <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#e6ebe4', fontSize: '0.8rem' }} />
                                    <span className='py-1 w-full text-white font-bold' style={{ backgroundColor: '#118cac', height: '2rem', fontSize: '1.0rem', textAlign: 'center' }}>FERRAMENTAS</span>
                                    <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#e6ebe4', fontSize: '0.8rem' }} />

                                    {contactSelected?.branchGym?._id && contactSelected?.assumedBy?._id === customerId ?
                                        <>
                                            <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#118cac', fontSize: '0.8rem' }}>:: Mensagens Rápidas</span>
                                            <div style={{ height: '45%', width: '100%', overflowY: 'auto' }}>
                                                {
                                                    quickMessageData && quickMessageData.filter(q => !q.blocked && q.branchGyms?.includes(contactSelected?.branchGym?._id)).length > 0 ?
                                                        quickMessageData.filter(q => !q.blocked && q.branchGyms?.includes(contactSelected?.branchGym?._id))
                                                            .sort((l1: any, l2: any) => l1.title < l2.title ? -1 : l1.title > l2.title ? 1 : 0).map(q =>
                                                                <div key={q._id} className='quickMessage bg-primary-reverse px-1 py-1 text-xs shadow-2' style={{ margin: '0.1rem !important' }}
                                                                    onClick={() => { if (!quickMessageToShow) setShowQuickMessage(q); }} >
                                                                    {/* data-pr-tooltip={`Ação rápida: #${q.tag}#`} data-pr-position='left' */}
                                                                    <i className={`pi ${q.isObject ? 'pi-images' : 'pi-align-left'} mr-1`} />{q.title}
                                                                    {/* <Tooltip target='.quickMessage' content={''} /> */}
                                                                </div>
                                                            )
                                                        :
                                                        <div className='m-1 bg-primary-reverse px-1 py-1 text-xs' style={{ color: '#b00020' }}><i className='pi pi-info mr-1' />Não há mensagens rápidas disponíveis.</div>
                                                }
                                            </div>
                                            <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#118cac', fontSize: '0.8rem' }} />
                                        </>
                                        :
                                        null
                                    }
                                    <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#e6ebe4', fontSize: '0.8rem' }} />
                                    {
                                        contactSelected?.assumedBy?._id === customerId || checkRoleHierarchyByToken(RoleRules.manager) ?
                                            <>
                                                <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#118cac', fontSize: '0.8rem' }}>:: Etiquetas do contato</span>
                                                <div style={{ height: '45%', width: '100%', overflowY: 'auto', backgroundColor: '#e6ebe4' }}>
                                                    {
                                                        tagContactsData && tagContactsData.filter(t => !t.blocked).length > 0 ?
                                                            tagContactsData.filter(t => !t.blocked).sort((l1: any, l2: any) => l1.tag < l2.tag ? -1 : l1.tag > l2.tag ? 1 : 0).map(t =>
                                                                <div key={t._id} className='tag bg-primary-reverse px-1 py-1 text-xs shadow-2'
                                                                    onClick={() => { if (t._id) setSelectTagContact(t._id); }}>
                                                                    <div className='flex align-items-center justify-content-start'>
                                                                        <i className={`pi ${t.selected ? 'pi-bookmark-fill' : 'pi-bookmark'} `} style={{ width: '15%', fontSize: '1.5rem', color: `#${t.tagColor}`, textShadow: `1px 0 rgba(255, 255, 255, 0.2666666667), -1px 0 rgba(255, 255, 255, 0.2666666667), 0 1px rgba(255, 255, 255, 0.2666666667), 0 -1px rgba(255, 255, 255, 0.2666666667), 1px 1px rgba(255, 255, 255, 0.2666666667), -1px -1px rgba(255, 255, 255, 0.2666666667), 1px -1px rgba(255, 255, 255, 0.2666666667), -1px 1px rgba(255, 255, 255, 0.2666666667)` }} />
                                                                        <span style={{ width: '85%', fontSize: '0.8rem' }}>{t.tag}</span>
                                                                    </div>
                                                                    {/* <i className={`pi pi-align-left mr-1`} />{t.tag} */}
                                                                    <Tooltip target='.Tag' content={''} />
                                                                </div>
                                                            )
                                                            :
                                                            <div className='m-1 bg-primary-reverse px-1 py-1 text-xs' style={{ color: '#b00020' }}><i className='pi pi-info mr-1' />Não há etiquetas disponíveis.</div>
                                                    }
                                                </div>
                                                <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#118cac', fontSize: '0.8rem' }} />
                                            </>
                                            : null
                                    }
                                </>
                                :
                                <>
                                    <span className='py-1 w-full text-white font-bold' style={{ color: '#fff', backgroundColor: '#43ac90', height: '2rem', fontSize: '1.0rem', textAlign: 'center' }}>:: FILTROS  APLICADOS ::</span>
                                    <div className='flex flex-colum flex-wrap justify-content-start' style={{ width: '100%', backgroundColor: '#c3dede' }}>
                                        <span className='filter-options filter-options-header'>Exibindo:</span>
                                        {filterContactMessage ? <span className='filter-options'>- Contatos filtrados por: '{filterContactMessage}'</span> : null}
                                        {selectMyContacts ? <span className='filter-options'>- Somente minhas conversas</span> : <span className='filter-options'>- Conversas de todos atendentes</span>}
                                        {/* {selectActiveStatus ? <span className='filter-options'>- Conversas ativas</span> : null} */}
                                        {selectEndStatus ? <span className='filter-options'>- Conversas encerradas</span> : null}
                                        {selectEndStatus ? <span className='filter-options filter-end-options'>
                                            <span>de <span>{moment(selectStartDateMessages).format('DD/MM/YYYY')}</span> até <span>{moment(selectEndDateMessages).format('DD/MM/YYYY')}</span></span>
                                        </span> : null}
                                        {tagFiltersData && tagFiltersData.find(t => !t.blocked && t.selected) ?
                                            <span className='filter-options'>- Com alguma das etiquetas:&nbsp;
                                                {tagFiltersData.filter(t => !t.blocked && t.selected).map(t => t.tag).join(', ')}</span>
                                            :
                                            <span className='filter-options'>- Com ou sem etiquetas</span>}
                                    </div>
                                    <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#c3dede', fontSize: '0.8rem' }} />
                                    <div className='flex flex-colum flex-wrap justify-content-start' style={{ width: '100%', backgroundColor: '#c3dede' }}>
                                        <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#43ac90', fontSize: '0.8rem' }}>:: Status</span>
                                        <div style={{ width: '100%', backgroundColor: '#c3dede' }}>
                                            <Button icon={`pi pi-star`} label={selectMyContacts ? 'Exibir outros atendentes' : 'Exibir somente minhas conversas'}
                                                className={classNames('button-action-chat', { 'active': selectMyContacts }, 'shadow-2')} text aria-label='Minhas conversas'
                                                onClick={() => { setSelectMyContacts(!selectMyContacts); }} tooltip='Minhas conversas' tooltipOptions={{ position: 'bottom' }} />

                                        </div>
                                        <div style={{ width: '100%', backgroundColor: '#c3dede', display: 'none' }}>
                                            <Button icon={`pi pi-comments`} label={selectActiveStatus ? 'Ocultar conversas ativas' : 'Exibir conversas ativas'}
                                                className={classNames('button-action-chat', { 'active': selectActiveStatus }, 'shadow-2')} text aria-label='Conversas Ativas'
                                                onClick={() => { setSelectActiveStatus(!selectActiveStatus); }} tooltip='Conversas Ativas' tooltipOptions={{ position: 'bottom' }} />
                                        </div>
                                        <div style={{ width: '100%', backgroundColor: '#c3dede' }}>
                                            <Button icon={`pi pi-ban`} label={selectEndStatus ? 'Ocultar conversas encerradas' : 'Exibir conversas encerradas'}
                                                className={classNames('button-action-chat', { 'active': selectEndStatus }, 'shadow-2')} text aria-label='Conversas encerradas'
                                                onClick={() => { setSelectEndStatus(!selectEndStatus); }} tooltip='Conversas encerradas' tooltipOptions={{ position: 'bottom' }} />
                                            {selectEndStatus ?
                                                <div className='date-message-box' style={{ position: 'relative', marginLeft: '1.5rem' }}>
                                                    <span style={{ display: 'block', height: '24px', fontSize: '0.8rem', fontWeight: 'bold', marginRight: 20 }}>Período:</span>
                                                    <div className='col-12 grid' style={{ padding: 0 }}>
                                                        <span className='col-2'>De:</span>
                                                        <div className='col-10'>
                                                            <Calendar id='startDateMessages' name={'startDateMessages'} value={startDateMessages}
                                                                onChange={(props: CalendarProps | Readonly<CalendarProps>) => props.value && props.value instanceof Date ? defineStartDate(props) : null}
                                                                dateFormat={'dd/mm/yy'} showIcon={true}
                                                                locale={'pt-BR'}
                                                                className='col-10 date-messages2 start-date-messages2' />
                                                        </div>
                                                        <span className='col-2'>Até:</span>
                                                        <div className='col-10'>
                                                            <Calendar id='endDateMessages' name={'endDateMessages'} value={endDateMessages}
                                                                onChange={(props: CalendarProps | Readonly<CalendarProps>) => props.value && props.value instanceof Date ? defineEndDate(props) : null}
                                                                dateFormat={'dd/mm/yy'} showIcon={true}
                                                                locale={'pt-BR'}
                                                                className='date-messages2 end-date-messages2' />
                                                        </div>
                                                    </div>
                                                    {messageChangeDate ?
                                                        <div className='col-12' style={{ padding: '10px 0px', textAlign: 'center' }}>
                                                            <span style={{ fontSize: '0.7rem', color: '#000', margin: '10px' }}>{messageChangeDate}</span>
                                                        </div>
                                                        : null}
                                                    <Button label='Aplicar data' type='button' onClick={() => { processFilters(true); }} style={{ fontSize: '0.8rem', minWidth: 120, marginRight: 10, padding: '0.4rem' }}
                                                        className='p-button-rounded p-button p-button-success shadow-3' icon='pi pi-search' />
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                    <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#c3dede', fontSize: '0.8rem' }} />
                                    <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#43ac90', fontSize: '0.8rem' }}>:: Filtro de Etiquetas</span>
                                    <div style={{ height: '65%', width: '100%', overflowY: 'auto', backgroundColor: '#c3dede' }}>
                                        {
                                            tagFiltersData && tagFiltersData.filter(t => !t.blocked).length > 0 ?
                                                tagFiltersData.filter(t => !t.blocked).sort((l1: any, l2: any) => l1.tag < l2.tag ? -1 : l1.tag > l2.tag ? 1 : 0).map(t =>
                                                    <div key={t._id} className={classNames('tag', { 'active': t.selected }, 'bg-primary-reverse px-1 py-1 text-xs shadow-2')}
                                                        onClick={() => { if (t._id) setSelectTagFiltered(t._id); }}>
                                                        <div className='flex align-items-center justify-content-start'>
                                                            <i className={`pi ${t.selected ? 'pi-bookmark-fill' : 'pi-bookmark'} `} style={{ width: '15%', fontSize: '1.5rem', color: `#${t.tagColor}`, textShadow: `1px 0 rgba(255, 255, 255, 0.2666666667), -1px 0 rgba(255, 255, 255, 0.2666666667), 0 1px rgba(255, 255, 255, 0.2666666667), 0 -1px rgba(255, 255, 255, 0.2666666667), 1px 1px rgba(255, 255, 255, 0.2666666667), -1px -1px rgba(255, 255, 255, 0.2666666667), 1px -1px rgba(255, 255, 255, 0.2666666667), -1px 1px rgba(255, 255, 255, 0.2666666667)` }} />
                                                            <span style={{ width: '85%', fontSize: '0.8rem' }}>{t.tag}</span>
                                                        </div>
                                                        {/* <i className={`pi pi-align-left mr-1`} />{t.tag} */}
                                                        <Tooltip target='.Tag' content={''} />
                                                    </div>
                                                )
                                                :
                                                <div className='m-1 bg-primary-reverse px-1 py-1 text-xs' style={{ color: '#b00020' }}><i className='pi pi-info mr-1' />Não há etiquetas disponíveis.</div>
                                        }
                                    </div>
                                    <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#43ac90', fontSize: '0.8rem' }} />
                                </>
                            }
                        </div>
                        <div className='right-side-footer' />
                    </div>
                </div >
                <GenericFormDialog<IFormDataAttendanceCreateData> key='AttendanceCreateDataDialog'
                    token={token}
                    objectData='contato'
                    refreshList={() => { }}
                    // refreshList={() => refreshListTrackerContactMessage()}
                    setLoading={(value: any) => setLoadingDialog(value)}
                    showDialog={dialogCreateData}
                    setDialogData={(value: any) => setDialogCreateData(value)}
                    setMessage={(message: IMessage) => props.args.setMessage(message)}
                    editFormData={editFormCreateData}
                    emptyFormData={{
                        _id: '',
                        countryMobilePhone: '+55',
                        districtMobilePhone: '11',
                        numberMobilePhone: '',
                        mobilePhone: '',
                        jid: '',
                        pushName: '',
                        dataExtra: '',
                        isValidWhatsapp: false,
                        activelyInitiatedContact: true,
                        typeInitiatedContact: 'pró-ativa',
                        active: true,
                        deleted: false
                    }}
                    retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataAttendanceCreateData>[]) => [
                        {
                            colSize: 'col-2', fieldName: 'countryMobilePhone', fieldType: 'InputTextField', description: '* Código do País', placeholder: '+55', value: formData.values.countryMobilePhone,
                            autoFocus: false,
                            onChange: (e: any) => limitCharactersInput('countryMobilePhone', 4, formData, e)
                        },
                        {
                            colSize: 'col-2', fieldName: 'districtMobilePhone', fieldType: 'InputTextField', description: '* Código da Área', placeholder: '11', value: formData.values.districtMobilePhone,
                            autoFocus: false,
                            onChange: (e: any) => limitCharactersInput('districtMobilePhone', 3, formData, e)
                        },
                        {
                            colSize: 'col-3', fieldName: 'numberMobilePhone', fieldType: 'InputTextField', description: '* Número da linha', placeholder: '99999-9999', value: formData.values.numberMobilePhone,
                            autoFocus: false,
                            onChange: (e: any) => limitCharactersInput('numberMobilePhone', 9, formData, e),
                            onBlur: (e: any) => {
                                changeMobilePhone(formData);
                            }
                        },
                        {
                            colSize: 'col-12', fieldName: 'pushName', fieldType: 'InputTextField', description: '* Nome:', placeholder: 'Nome do contato', value: formData.values.pushName, autoFocus: false,
                            onChange: (e: any) => limitCharactersInput('pushName', 100, formData, e)
                        },
                        {
                            colSize: 'col-12', fieldName: 'dataExtra', fieldType: 'InputTextAreaField', description: 'Dados Extras:', placeholder: 'Dados Extras', value: formData.values.dataExtra, autoFocus: false,
                            rows: 5, cols: 5,
                            onChange: formData.handleChange
                        }
                    ])}
                    editForceData={{}}
                    editFunctions={[]}
                    formLists={[]}
                    validate={(data: any) => {
                        let errors: any = {};

                        data.countryMobilePhone = data.countryMobilePhone.substring(0, 4);

                        if (!data.countryMobilePhone) {
                            errors.countryMobilePhone = 'Código do país é obrigatória.';
                        } else if (data.countryMobilePhone.length < 1) {
                            errors.countryMobilePhone = 'Código do país precisa ter mais que 2 caracteres.';
                        } else if (data.countryMobilePhone.substring(0, 1) !== '+') {
                            errors.countryMobilePhone = 'Código do país precisa iniciar com `+`.';
                        } else if (isNaN(data.countryMobilePhone)) {
                            errors.countryMobilePhone = 'Código do país precisa ter somente números.';
                        }

                        // console.log('isNaN(data.districtMobilePhone): ', data.districtMobilePhone);
                        // console.log('isNaN(data.districtMobilePhone): ', isNaN(data.districtMobilePhone));
                        // console.log('isNaN(data.districtMobilePhone): ', !isNaN(data.districtMobilePhone));

                        data.districtMobilePhone = data.districtMobilePhone.substring(0, 3);

                        if (!data.districtMobilePhone) {
                            errors.districtMobilePhone = 'Código de área é obrigatória.';
                        } else if (data.districtMobilePhone.length < 2) {
                            errors.districtMobilePhone = 'Código de área precisa ter mais que 1 caracter.';
                        } else if (isNaN(data.districtMobilePhone)) {
                            errors.districtMobilePhone = 'Código de área precisa ter somente números.';
                        }

                        data.numberMobilePhone = data.numberMobilePhone.substring(0, 9);
                        let validNumberMobilePhone = data.numberMobilePhone.replace(/[-|&;$%@'<>()+,]/g, '');

                        if (!validNumberMobilePhone) {
                            errors.numberMobilePhone = 'Número do contato é obrigatória.';
                        } else if (validNumberMobilePhone.length < 8) {
                            errors.numberMobilePhone = 'Número do contato precisa ter ao menos 8 caracteres.';
                        } else if (validNumberMobilePhone.length > 9) {
                            errors.numberMobilePhone = 'Número do contato não pode ter mais que 9 caracteres.';
                        } else if (isNaN(validNumberMobilePhone)) {
                            errors.numberMobilePhone = 'Número do contato precisa ter somente números.';
                        }

                        if (!data.pushName) {
                            errors.pushName = 'Nome do contato é obrigatório.';
                        } else if (data.pushName.length < 3) {
                            errors.pushName = 'Nome do contato precisa ter mais que 3 caracteres.';
                        }

                        return errors;
                    }}
                    submitForceData={{}}
                    onSubmit={async (props2: IPropsFormDialog<IFormDataAttendanceCreateData>, data: FormikValues, formData: FormikInterface) => {
                        setLoadingDialog(true);
                        let contactMessageByJidData;
                        let contactMessageToSave: any = {};
                        let messageAlert = [];

                        const numberJid = `${data.countryMobilePhone.replace(/[-|&;$%@'<>()+,]/g, '')}${data.districtMobilePhone.replace(/[-|&;$%@'<>()+,]/g, '')}${data.numberMobilePhone.replace(/[-|&;$%@'<>()+,]/g, '')}`;
                        const jid = `${numberJid}@s.whatsapp.net`;

                        const trackerMobile = await new TrackerMobileService().findTrackerMobileByNetworkGymId(networkGymId);
                        const trackerMobileData = trackerMobile.data;

                        // console.log('trackerMobileData: ', trackerMobileData, trackerMobileData.length, trackerMobileData[0]._id);
                        if (trackerMobileData && trackerMobileData.length > 0 && trackerMobileData[0]._id) {
                            const contactMessageByJid = await new TrackerContactMessageService().findTrackerContactMessageByNetworkGymId(networkGymId, undefined, {
                                queries: [{
                                    field: 'jid',
                                    value: jid
                                }]
                            });
                            if (contactMessageByJid && contactMessageByJid.data && contactMessageByJid.data.length > 0) {
                                contactMessageByJidData = (await trackerContactMessageService.findTrackerContactMessageById(contactMessageByJid.data[0]._id)).data;
                            }
                            if (contactMessageByJidData) {
                                // contactMessageToSave = Object.assign(contactMessageToSave, contactMessageByJidData);
                                if (contactMessageByJidData.finished !== true) {
                                    contactMessageToSave = undefined;
                                    if (contactMessageByJidData.assumedBy._id !== customerId) {
                                        messageAlert.push(`Existe uma conversa em andamento com este contato`);
                                        if (contactMessageByJidData.branchGym && contactMessageByJidData?.branchGym?.name) messageAlert.push(` na unidade: ${contactMessageByJidData?.branchGym?.name}`);
                                        if (contactMessageByJidData.assumedBy && contactMessageByJidData.assumedBy.nickname) messageAlert.push(` com o atendente: ${contactMessageByJidData.assumedBy.nickname}`);
                                    } else {
                                        messageAlert.push(`Este contato está sendo atendido por você`);
                                        if (contactMessageByJidData.branchGym && contactMessageByJidData?.branchGym?.name) messageAlert.push(` na unidade: ${contactMessageByJidData?.branchGym?.name}`);
                                    }
                                } else {
                                    contactMessageToSave = Object.assign(contactMessageByJidData, {
                                        available: false,
                                        new: false,
                                        inProgress: true,
                                        finished: false,
                                        lastChange: (await ls.socket.getServerSocketDateTime()).toDate(),
                                        enumerator: contactMessageByJidData.enumerator + 1,
                                        pushName: data.pushName,
                                        branchGym: branchGymId,
                                        assumedBy: customerId,
                                        lastAssumedBy: contactMessageByJidData.assumedBy?._id ? contactMessageByJidData.assumedBy?._id : null,
                                        activelyInitiatedContact: true,
                                        typeInitiatedContact: 'pró-ativa',
                                        guide: '',
                                        guideStep: '',
                                        // dataExtra: `${data.dataExtra}\n${contactMessageByJidData.dataExtra ? contactMessageByJidData.dataExtra : ''}`
                                        dataExtra: data.dataExtra,
                                    });
                                }
                            } else {
                                contactMessageToSave = {
                                    // '_id': ObjectId('637079c25423c9d277844ead'),
                                    available: false,
                                    new: false,
                                    inProgress: true,
                                    finished: false,
                                    lastChange: (await ls.socket.getServerSocketDateTime()).toDate(),
                                    key: makeKeyByString(`${numberJid}@s.whatsapp.net`),
                                    typeContactMessage: 'web.whatsapp',
                                    networkGym: networkGymId,
                                    trackerMobile: trackerMobileData[0]._id,
                                    jid,
                                    pushName: data.pushName,
                                    group: false,
                                    stateContactMessage: 'ignored',
                                    // createdAt: ISODate('2022-11-13T04:59:46.394Z'),
                                    // updatedAt: ISODate('2022-11-13T05:30:34.747Z'),
                                    guide: '',
                                    guideStep: '',
                                    branchGym: branchGymId,
                                    assumedBy: customerId,
                                    // lastAssumedBy: customerId,
                                    dateCreated: (await ls.socket.getServerSocketDateTime()).toDate(),
                                    activelyInitiatedContact: true,
                                    typeInitiatedContact: 'pró-ativa',
                                    isValidWhatsapp: false,
                                    dataExtra: data.dataExtra,
                                    // 'deleted': false,
                                    // 'active': true,
                                    // '__v': 0,
                                };
                            }

                            if (contactMessageToSave) {
                                // console.log('contactMessageToSave: ', contactMessageToSave);
                                const returned = await new TrackerContactMessageService().saveTrackerContactMessage(Object.assign(contactMessageToSave, { lastChange: (await ls.socket.getServerSocketDateTime()).toDate() }));

                                if (returned && isSuccess(returned.status)) {
                                    // refreshListTrackerContactMessage(false);
                                    if (returned && returned.data) setContactSelected(Object.assign({}, returned.data));
                                    saveSuccessed(props2, formData)(returned);
                                } else {
                                    saveFailed(props2, formData)(returned);
                                }


                                // args.serverApplication.saveConversationDashboardReport('dashboard-report-new-conversation-schedule', trackerContactMessage.networkGym?._id || trackerContactMessage.networkGym, trackerContactMessage.branchGym?._id || trackerContactMessage.branchGym,
                                //     undefined, {
                                //     contactMessage: trackerContactMessage,
                                //     contactMessageEnumerator: trackerContactMessage.enumerator
                                // });

                                await new DashboardReportService().saveConversationDashboardReport(
                                    'dashboard-report-new-conversation-attendance',
                                    contactMessageToSave.networkGym?._id || networkGymId, contactMessageToSave.branchGym || branchGymId,
                                    customerId!,
                                    {
                                        contactMessage: contactMessageToSave,
                                        contactMessageEnumerator: contactMessageToSave.enumerator,
                                        flags: {
                                            contactMessageReopened: !!contactMessageToSave._id,
                                            contactMessageProactive: true
                                        }
                                    });

                                await new DashboardReportService().saveGenericConversationDashboardReport('dashboard-report-assumed-conversation-frontend-attendant', 'conversation', 'assumed',
                                    contactMessageToSave.networkGym || networkGymId, contactMessageToSave.branchGym || branchGymId,
                                    customerId!, contactMessageToSave._id, contactMessageToSave.enumerator);
                                
                            } else {
                                saveSuccessed(props2, formData, messageAlert.join(''))(undefined);

                            }
                            setLoadingDialog(false);

                        }

                    }}
                />

                {
                    contactSelected && token ?
                        <>
                            <GenericFormDialog<IFormDataAttendanceUpdateData> key='AttendanceUpdateDataDialog'
                                token={token}
                                objectData='contato'
                                refreshList={() => { }}
                                // refreshList={() => refreshListTrackerContactMessage()}
                                setLoading={(value: any) => setLoadingDialog(value)}
                                showDialog={dialogData}
                                setDialogData={(value: any) => setDialogData(value)}
                                setMessage={(message: IMessage) => props.args.setMessage(message)}
                                editFormData={editFormData}
                                emptyFormData={{
                                    _id: '',
                                    pushName: '',
                                    mobilePhone: '',
                                    dataExtra: '',
                                    active: true,
                                    deleted: false
                                }}
                                retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataAttendanceUpdateData>[]) => [
                                    {
                                        colSize: 'col-12', fieldName: 'pushName', fieldType: 'InputTextField', description: '* Nome:', placeholder: 'Nome do contato', value: formData.values.pushName, autoFocus: false,
                                        onChange: formData.handleChange
                                    },
                                    {
                                        colSize: 'col-12', fieldName: 'mobilePhone', fieldType: 'InputMaskField', description: '* Celular', placeholder: '+55 (99) 99999-9999', value: formData.values.mobilePhone,
                                        mask: maskBuilder(formData.values.mobilePhone),
                                        style: { opacity: 1 }, disabled: true, autoFocus: false,
                                    },
                                    {
                                        colSize: 'col-12', fieldName: 'dataExtra', fieldType: 'InputTextAreaField', description: 'Dados Extras:', placeholder: 'Dados Extras', value: formData.values.dataExtra, autoFocus: false,
                                        rows: 5, cols: 5,
                                        onChange: formData.handleChange
                                    }
                                ])}
                                editForceData={{}}
                                editFunctions={[]}
                                formLists={[]}
                                validate={(data: any) => {
                                    let errors: any = {};

                                    if (!data.pushName) {
                                        errors.pushName = 'Nome do contato é obrigatório.';
                                    } else if (data.pushName.length < 3) {
                                        errors.pushName = 'Nome do contato precisa ter mais que 3 caracteres.';
                                    } else if (data.pushName.length > 100) {
                                        errors.pushName = 'Nome do contato precisa ter menos de 100 caracteres.';
                                    }

                                    return errors;
                                }}
                                submitForceData={{}}
                                onSubmit={(props: IPropsFormDialog<IFormDataAttendanceUpdateData>, data: FormikValues, formData: FormikInterface) => {
                                    const saveData = { _id: data._id, pushName: data.pushName, dataExtra: data.dataExtra };
                                    new TrackerContactMessageService().saveTrackerContactMessage(Object.assign(saveData))
                                        .then((returned: IPerformRequestResult<any>) => saveSuccessed(props, formData)(returned))
                                        .catch((error: any) => saveFailed(props, formData)(error));
                                }}
                            />
                            <GenericFormDialog<IFormDataScheduleData> key='ScheduleDataDialog'
                                token={token}
                                objectData='agendamento'
                                title='Criar agendamento'
                                refreshList={() => null}
                                setLoading={(value: any) => prepareLoadingScreen(value)}
                                saveButtonDisabled={props.args.loading || saveCancelButtonDisabled}
                                cancelButtonDisabled={saveCancelButtonDisabled}
                                showDialog={scheduleDialogData}
                                setDialogData={(value: any) => setScheduleDialogData(value)}
                                setMessage={(message: IMessage) => props.args.setMessage(message)}
                                editFormData={editScheduleData}
                                emptyFormData={{
                                    key: makeKeyByString(`${contactSelected.networkGym?._id}_${contactSelected.branchGym?._id}_${contactSelected._id}_${customerId}_${ls.socket.getServerSocketDateTime().toDate()}`),
                                    networkGym: contactSelected.networkGym?._id || contactSelected.networkGym,
                                    branchGym: contactSelected.branchGym?._id || contactSelected.branchGym,
                                    date: momentRoundMinutes(ls.socket.getServerSocketDateTime(), 30).toDate(),
                                    datePristine: ls.socket.getServerSocketDateTime().toDate(),
                                    statusSchedule: 'opened',
                                    typeSchedule: { id: 'followup', name: 'FOLLOW-UP', description: 'Follow-up' },
                                    guest: contactSelected._id,
                                    guestName: contactSelected.pushName,
                                    invitedBy: customerId!,
                                    confirmedPresence: false,
                                    attendedAcademy: false,
                                    closedContract: false,
                                    dataScheduleExtra: '',
                                    deleted: false,
                                    active: true
                                }}
                                retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataScheduleData>[]) => [
                                    {
                                        colSize: 'col-12', fieldName: 'date', fieldType: 'CalendarField', description: '* Data do agendamento', value: formData.values.date,
                                        onChange: (e: any) => formData.handleChange(e), autoFocus: false,
                                        dateFormat: 'dd/mm/yy', locale: 'pt-BR', showTime: true, stepMinute: 30, showIcon: true,
                                        onShow: () => { setSaveCancelButtonDisabled(true); },
                                        onHide: () => { setSaveCancelButtonDisabled(false); }
                                    },
                                    {
                                        colSize: 'col-6', fieldName: 'typeSchedule', fieldType: 'DropdownField', description: '* Motivo:', placeholder: 'Selecione um motivo', value: formData.values.typeSchedule, autoFocus: false,
                                        onChange: (event: any) => formData.handleChange(event),
                                        options: [{ id: 'visit', name: 'VISITA PROGRAMADA', description: 'Visita Programada' }, { id: 'class', name: 'AULA EXPERIMENTAL', description: 'Aula Experimental' },
                                        { id: 'contact', name: 'CONTATO PESSOAL', description: 'Contato Pessoal' }, { id: 'followup', name: 'FOLLOW-UP', description: 'Follow-up' }],
                                        optionLabel: 'description', filter: true, showClear: true, filterBy: 'description',
                                    },
                                    {
                                        colSize: 'col-12', fieldName: 'dataScheduleExtra', fieldType: 'InputTextAreaField', description: 'Detalhes:', placeholder: 'Detalhes', value: formData.values.dataScheduleExtra, autoFocus: false,
                                        rows: 5, cols: 5,
                                        onChange: formData.handleChange
                                    }
                                ])}
                                editForceData={{
                                    networkGym: contactSelected.networkGym?._id || contactSelected.networkGym,
                                    branchGym: contactSelected.branchGym?._id || contactSelected.branchGym,
                                }}
                                editFunctions={[]}
                                formLists={[]}
                                validate={(data: any) => {
                                    let errors: any = {};

                                    if (!data.date) {
                                        errors.date = 'Data de agendamento é obrigatório.';
                                    } else if (!isDate(data.date)) {
                                        errors.date = 'Data de agendamento não é uma data válida.';
                                    } else if (moment(data.date).isBefore(ls.socket.getServerSocketDateTime())) {
                                        errors.date = 'Data de agendamento não pode ser inferior a data atual.';
                                    } else if (moment(data.date).hours() < initialHour || (moment(data.date).hours() > finalHour || (moment(data.date).hours() === finalHour && moment(data.date).minutes() > finalMinute))) {
                                        errors.date = `Data de agendamento precisar estar entre ${initialHour}${initialMinute !== 0 ? `:${padLeft(initialMinute, 2, '0')}` : ''}h e ${finalHour}${finalMinute !== 0 ? `:${padLeft(finalMinute, 2, '0')}` : ''}h.`;
                                    }

                                    if (!data.typeSchedule) {
                                        errors.typeSchedule = 'Motivo do agendamento é obrigatório';
                                    }
                                    // data.date = momentRoundMinutes(moment(data.date), 15).toDate();

                                    return errors;
                                }}
                                submitForceData={{}}
                                onSubmit={async (props: IPropsFormDialog<IFormDataScheduleData>, data: FormikValues, formData: FormikInterface) => {
                                    const scheduleToSave = Object.assign({}, data);
                                    let networkGymId = contactSelected.networkGym;
                                    let branchGymId = contactSelected.branchGym;

                                    let typeScheduleDescription = data?.typeSchedule?.description || 'follow-up';
                                    typeScheduleDescription = typeScheduleDescription.toLowerCase();

                                    data.key = makeKeyByString(`${contactSelected.networkGym?._id}_${contactSelected.branchGym?._id}_${contactSelected._id}_${customerId}_${(await ls.socket.getServerSocketDateTime()).toDate()}`);
                                    data.date = momentRoundMinutes((await ls.socket.getServerSocketDateTime()), 30).toDate();
                                    data.datePristine = (await ls.socket.getServerSocketDateTime()).toDate();

                                    if (scheduleToSave.typeSchedule && scheduleToSave.typeSchedule.id) scheduleToSave.typeSchedule = scheduleToSave.typeSchedule.id;

                                    new ScheduleService().saveSchedule(scheduleToSave)
                                        .then(async (returned: IPerformRequestResult<any>) => {
                                            if (returned && returned.data && isSuccess(returned.status)) {
                                                const schedule = returned.data;

                                                if (data.typeSchedule?.id !== 'followup') {
                                                    await managerSystemMessageService.sendSystemMessage('AGENDAMENTO_ATENDIMENTO', networkGymId, contactSelected.group, {
                                                        methodSend: sendMessageToContact,
                                                        typeShowMessage: EnumTypeShowMessage.botMessage,
                                                        customerNickname: ls.customer.getCustomerNicknameStorage(),
                                                        scheduleType: typeScheduleDescription,
                                                        scheduleDate: `${moment(schedule.date).format('dddd, D')} de ${moment(schedule.date).format('MMMM')} de ${moment(schedule.date).format('YYYY')}`,
                                                        scheduleHour: moment(schedule.date).format('H:mm'),
                                                        branchGymName: schedule.branchGym?.name,
                                                        branchGymAddress: schedule.branchGym?.address,
                                                        branchGymNumber: schedule.branchGym?.number,
                                                        branchGymComplement: schedule.branchGym?.complement,
                                                        branchGymDistrict: schedule.branchGym?.district,
                                                        branchGymCity: schedule.branchGym?.city?.name,
                                                        branchGymState: schedule.branchGym?.state?.key,
                                                        branchGymCep: schedule.branchGym?.zipCode,
                                                        typeObjectMessage: `messagesSender.message.conversation.assumedBy.${customerId}`,
                                                        callbackMethod: () => {
                                                            saveDashboardSchedule(customerId, schedule, networkGymId, branchGymId, contactSelected);
                                                        }
                                                    });
                                                }
                                                // let messageSchedule = `Êba... o nosso encontro pessoal está agendado. \n\n' +
                                                //     'Por favor, anote as informações da sua ${typeScheduleDescription}.\n\n` +
                                                //     `Data: *${moment(schedule.date).format('dddd, D')} de ${moment(schedule.date).format('MMMM')} de ${moment(schedule.date).format('YYYY')}*\n` +
                                                //     `Horário: *${moment(schedule.date).format('H:mm')}hs*\n\n` +
                                                //     `Endereço: *${schedule.branchGym?.address} - ${schedule.branchGym?.number}, ${schedule.branchGym?.complement}, ${schedule.branchGym?.district}, ` +
                                                //     `${schedule.branchGym?.city?.name}/${schedule.branchGym?.state?.key}, CEP ${schedule.branchGym?.zipCode}*\n\n` +
                                                //     'Até Breve!';
                                                // sendMessageToContact(messageSchedule, EnumTypeShowMessage.botMessage, `messagesSender.message.schedule.created.${customerId}`);
                                                // saveDashboardSchedule(customerId, schedule, networkGymId, branchGymId, contactSelected);
                                            }
                                            saveSuccessed(props, formData)(returned);
                                        })
                                        .catch((error: any) => saveFailed(props, formData)(error));

                                }}
                            />
                            <SelecDialog key={`branchGym-selected-dialog`}
                                dialogIcon='pi-th-large'
                                titleIcon={'pi-th-large'}
                                evalTitleValue={'data.name'}
                                keyDialog={`branchGym-selected-dialog`}
                                typeData={{ tag: 'contactMessageBranchGym', value: 'contactMessageBranchGym' }} data={branchGymNoBlockedData} objectData={'uma unidade'}
                                select={select}
                                unselect={unselect}
                                setDialogData={(value: boolean) => selecioneBranchGym(value)}
                                showDialog={dialogBranchGymData}
                                loadingData={props.args.loading} />

                            <Dialog visible={showConfirmateAction} style={{ width: '40%' }} modal={true} closable={false}
                                onHide={() => setShowConfirmateAction(false)}>
                                <div className='grid'>
                                    <div className='col-12'>
                                        <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                                            <h3>{showConfirmateActionText}</h3>
                                        </div>
                                    </div>
                                    <div className='flex p-fluid col-6 align-items-center justify-content-center'>
                                        <Button label='Sim' type='button' onClick={async () => {
                                            await showConfirmateActionMethod.method();
                                            setShowConfirmateAction(false);
                                        }} style={{ minWidth: 150 }} className='p-button-rounded p-button p-button shadow-3' icon='pi pi-sign-out' />
                                    </div>
                                    <div className='flex p-fluid col-6 align-items-center justify-content-center'>
                                        <Button label='Não' type='button' onClick={() => { setShowConfirmateAction(false); }} style={{ minWidth: 150 }}
                                            className='p-button-rounded p-button p-button-warning shadow-3' icon='pi pi-times' />
                                    </div>
                                </div>
                            </Dialog>

                            <Dialog visible={!!dialogQuickMessageToShow} style={{ width: '40%' }} modal={true} closable={false}
                                onHide={() => setDialogQuickMessageToShow(false)} onShow={() => prepareLoadingScreen(false)}>
                                <div className='grid'>
                                    <div className='col-12'>
                                        <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                                            <h3>{quickMessageToShow?.title}</h3>
                                        </div>
                                    </div>
                                    {
                                        quickMessageToShow?.isObject ?
                                            previewMedia(quickMessageToShow.mimetype, quickMessageToShow.mediaBase64)
                                            :
                                            textQuickMessage ?
                                                <div className='col-12'>
                                                    < div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                                                        <InputTextarea className='textQuickMessage' autoResize={false} value={textQuickMessage} onChange={(e) => setTextQuickMessage(e.target.value || null)}
                                                            style={{ fontSize: '1.2em', minWidth: '100%', minHeight: 200 }} placeholder={`Olá! 
                                                             *bem vindo* a nossa academia.\n\nEstou aqui para _esclarecer suas dúvidas_. 🤗`}
                                                            aria-describedby='textQuickMessage-help' />
                                                    </div>
                                                </div>
                                                : null
                                    }
                                    <div className='flex p-fluid col-6 align-items-center justify-content-center'>
                                        <Button label='Enviar' type='button' onClick={async () => {
                                            if (quickMessageToShow) await sendMessageByQuickMessage(quickMessageToShow);
                                        }} style={{ minWidth: 150 }} className='p-button-rounded p-button p-button shadow-3' icon='pi pi-sign-out' />
                                    </div>
                                    <div className='flex p-fluid col-6 align-items-center justify-content-center'>
                                        <Button label='Cancelar' type='button' onClick={() => { setDialogQuickMessageToShow(false); setQuickMessageToShow(null); }} style={{ minWidth: 150 }}
                                            className='p-button-rounded p-button p-button-warning shadow-3' icon='pi pi-times' />
                                    </div>
                                </div>
                            </Dialog >

                        </>
                        :
                        null
                }

            </>
        );
    }

};

function previewMedia(mimeTypeQuickMessage: string, mediaQuickMessage: string): React.ReactNode {
    return <div className='col-12 flex flex-wrap justify-content-center'>
        <div className={classNames('msg', 'msg-me', 'justify-content-center')}>
            {mimeTypeQuickMessage?.includes('image') ?
                <div className='flex-initial flex align-items-center justify-content-center'>
                    <Image className='flex-initial flex flex-wrap' downloadable={true} src={mediaQuickMessage} alt='Imagem' height='100' preview />
                </div>
                :
                mimeTypeQuickMessage?.includes('video') ?
                    <div className='flex-initial flex align-items-center justify-content-center'>
                        <video className='flex-initial flex flex-wrap' width='400' height='200' controls>
                            <source src={mediaQuickMessage} type={mimeTypeQuickMessage} />
                        </video>
                    </div>
                    :
                    mimeTypeQuickMessage?.includes('audio') ?
                        <div className='flex-initial flex align-items-center justify-content-center'>
                            <audio className='flex-initial flex flex-wrap' controls>
                                <source src={mediaQuickMessage} type={mimeTypeQuickMessage} />
                            </audio>
                        </div>
                        :
                        mimeTypeQuickMessage?.includes('pdf') ?

                            <div className='flex-initial flex align-items-center justify-content-center'>
                                <i className='pi pi-file-pdf' style={{ fontSize: '4rem' }}></i>
                            </div> :
                            mimeTypeQuickMessage?.includes('ms-excel') ?
                                <div className='flex-initial flex align-items-center justify-content-center'>
                                    <i className='pi pi-file-excel' style={{ fontSize: '4rem' }}></i>
                                </div>
                                :

                                mimeTypeQuickMessage?.includes('ms-word') ?
                                    <div className='flex-initial flex align-items-center justify-content-center'>
                                        <i className='pi pi-file-word' style={{ fontSize: '4rem' }}></i>
                                    </div>
                                    :
                                    mimeTypeQuickMessage?.includes('pdf') ?
                                        <div className='flex-initial flex align-items-center justify-content-center'>
                                            <i className='pi pi-file-pdf' style={{ fontSize: '4rem' }}></i>
                                        </div>
                                        : null}
        </div>
    </div>;
}


