/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { RoleRules, checkRoleHierarchyByToken } from '../../../projects/checkRoleHierarchy';
import { EnumTypeShowMessage } from '../../../utils/functions/message';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import TrackerMessageService from '../../service/admin/TrackerMessageService';
import { IFormDataAttendance } from '../generics/entities';
import { IPropsArgsComponents } from '../generics/formInterfaces';
import ls from '../../../projects/localStorage';

import './Attendance.css';

const trackerMessageService = new TrackerMessageService();

const timeRefreshMessages = 500;
const limitMessagesFirst = 100;
const limitMessages = 500;

const vCard = require('vcard-parser')


export const AttendanceMessage = (props: IPropsArgsComponents) => {
    const [data, setData] = useState<IFormDataAttendance[] | undefined>(undefined);
    const [dataLength, setDataLength] = useState<number>(0);

    const [customerId] = useState<string | null>(ls.customer.getCustomerIdStorageToken());

    const [refreshKey, setRefreshKey] = useState(0);

    const [contactMessageSelected, setContactMessageSelected] = useState<any>();
    const [lastContactMessageSelected, setLastContactMessageSelected] = useState<any>();

    const [listMessagesOption] = useState<'recebidas' | 'enviadas' | 'todas'>('todas');
    const [filterTextMessage] = useState<string>('');

    const [messagesEnd, setMessagesEnd] = useState<HTMLDivElement | null>();

    const [scrollMessages, setScrollMessages] = useState<boolean>(true);

    useEffect(() => {
        if (contactMessageSelected != props.contactSelected) {
            props.args.setLoading(true);
            setData(undefined);
            setContactMessageSelected(props.contactSelected);
            props.args.setLoading(false);
        }
    }, [props.contactSelected]);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setRefreshKey(oldKey => oldKey + 1);
    //     }, 1000);
    //     return () => clearInterval(interval);
    // }, [refreshKey]);

    // useEffect(() => refreshList(true), []);
    useEffect(() => refreshList(false), [refreshKey]);

    useEffect(() => {
        if (props.setWaitInteraction && data) props.setWaitInteraction();
    }, [data]);

    useEffect(() => {
        if (data && dataLength !== data.length) { setDataLength(data.length); setScrollMessages(true); }
    }, [data]);

    const refreshList = (unloadingScreen?: boolean) => {
        let isMounted = true;
        let first = lastContactMessageSelected != contactMessageSelected;
        // console.log(`refreshList(${first}): `, contactMessageSelected);
        // console.log(`findTrackerMessageByTrackerMobileId(${first}): `, (!first ? limitMessages : limitMessagesFirst));
        // console.log('contactMessageSelected.jid: ', contactMessageSelected?.jid);
        // console.log('contactMessageSelected.networkGym._id: ', contactMessageSelected?.networkGym?._id);
        // console.log('contactMessageSelected?.branchGym?._id: ', contactMessageSelected?.branchGym?._id);
        // console.log('contactMessageSelected.trackerMobile._id: ', contactMessageSelected?.trackerMobile?._id);

        if (first) setLastContactMessageSelected(contactMessageSelected);
        if (contactMessageSelected && contactMessageSelected?.jid && contactMessageSelected?.networkGym?._id
            && contactMessageSelected?.trackerMobile?._id) {
            trackerMessageService.findTrackerMessageByTrackerMobileId(contactMessageSelected?.jid,
                contactMessageSelected?.networkGym?._id, contactMessageSelected?.branchGym?._id, contactMessageSelected?.trackerMobile?._id, undefined, undefined, (!first ? limitMessages : limitMessagesFirst)).then((returned: any) => {
                    if (isMounted) {
                        if (returned) {
                            if (returned.status === HttpStatus.UNAUTHORIZED) {
                                props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                                setTimeout(() => { window.location.href = '/'; }, 5000);
                                setData(undefined);
                                setContactMessageSelected(undefined);
                                if (unloadingScreen) props.args.setLoading(false);
                            } else if (returned.status === HttpStatus.NOT_FOUND) {
                                // console.log('data: VAZIO: ', [].length);
                                setData([]);
                                setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshMessages);
                                if (unloadingScreen) props.args.setLoading(false);
                            } else if (isSuccess(returned.status)) {
                                // console.log('returned.data: ', returned.data?.length);
                                const returnedDataIdDeleted: any[] = returned.data.filter((d: any) => d.deleted).map((d: any) => d._id);
                                const returnedDataIdNotDeleted: any[] = returned.data.filter((d: any) => !d.deleted).map((d: any) => d.originalMessage?.key?.id);
                                const returnedData = returned.data.filter((d: any) => !d.deleted || !returnedDataIdNotDeleted.includes(d.originalMessage?.key?.id));
                                // const returnedData = returned.data.filter((d: any) => (!d.isObject || d.mimetype !== '') &&(!d.deleted || !returnedDataIdNotDeleted.includes(d.originalMessage?.key?.id)));
                                let newData: any[] = [];
                                if (data) newData = data;
                                let updateNewMessages = returned.data.filter((item: any) => newData.find((itemData: any) => itemData._id === item._id && (itemData.text !== item.text || itemData.reactText !== item.reactText || itemData.dateText !== item.dateText)));
                                for (let i = 0; i < updateNewMessages.length; i++) {
                                    const updateNewMessage = updateNewMessages[i];
                                    let updateMessage = newData.find((item: any) => item._id === updateNewMessage._id);
                                    if (updateMessage) {
                                        updateMessage = Object.assign(updateMessage, updateNewMessage);
                                        // updateMessage.text = updateNewMessage.text;
                                        // updateMessage.reactText = updateNewMessage.reactText;
                                        // updateMessage.dateText = updateNewMessage.dateText;
                                    }
                                }
                                newData = newData.concat(returnedData.filter((item: any) => !newData.find((itemData: any) => itemData._id === item._id))) || [];
                                newData = newData.filter((d: any) => !returnedDataIdDeleted.includes(d._id)) || [];
                                // console.log('data: CARREGANDO: ', newData.length);
                                setData(newData);

                                setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshMessages);
                                if (unloadingScreen) props.args.setLoading(false);
                            } else {
                                setData(undefined);

                                setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshMessages);
                                if (unloadingScreen) props.args.setLoading(false);
                            }
                        } else {
                            setData(undefined);
                            // setContactMessageSelected(undefined);
                            setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshMessages);
                            if (unloadingScreen) props.args.setLoading(false);
                        }
                    }
                }).catch(error => {
                    console.error('error', error);
                    if (isMounted) {
                        setData(undefined);
                        // setContactMessageSelected(undefined);
                        setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshMessages);
                        if (unloadingScreen) props.args.setLoading(false);
                    }
                });
            return () => { isMounted = false };
        } else {
            if (isMounted) {
                setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshMessages);
            }
            props.args.setLoading(false);
        }
        return () => { isMounted = false };
    };

    const prettyMessage = (message: string) => {
        return message.replace(/```([a-z][\S\s]+?)```/gmi, '<span style="font-size: 0.7rem; font-style: italic;">$1</span>')
            .replace(/\*([a-z][\S\s]+?)\*/gmi, '<span style="font-weight: bold;">$1</span>')
            .replace(/_([a-z][\S\s]+?)_/gmi, '<span style="font-style: italic;">$1</span>')
            .replace(/\n/g, "<br />");
    }

    const renderMessageInteraction = (contactMessageSelected: any, messageAttendance: IFormDataAttendance,
        content: JSX.Element,
        setMessagesEnd?: (el: HTMLDivElement | null) => void): JSX.Element => {
        return <><div key={`${messageAttendance._id}_${messageAttendance.dateText}`}
            className={classNames('msg', { 'msg_geral': !contactMessageSelected?.jid || messageAttendance.group },
                { 'msg-object': messageAttendance.isObject && !messageAttendance?.originalMessage?.message?.orderMessage?.orderTitle && !messageAttendance?.mimetype?.includes('text/plain') && !messageAttendance?.mimetype?.includes('image') && !messageAttendance?.mimetype?.includes('video') && !messageAttendance?.mimetype?.includes('audio') && !messageAttendance?.mimetype?.includes('application') },
                { 'msg-me': messageAttendance.fromMe },
                { 'msg-frnd': !messageAttendance.fromMe },
                { 'msg-hidden': messageAttendance.typeShowMessage === EnumTypeShowMessage.hidden },
                { 'msg-system-message': messageAttendance.typeShowMessage === EnumTypeShowMessage.systemMessage },
                { 'msg-bot-message': messageAttendance.typeShowMessage === EnumTypeShowMessage.botMessage },
                { 'msg-bot-message-hidden': messageAttendance.typeShowMessage === EnumTypeShowMessage.botMessageHidden },
                { 'msg-interaction-message': messageAttendance.typeShowMessage === EnumTypeShowMessage.interactionMessage },
                { 'msg-nps-question-hidden': messageAttendance.typeShowMessage === EnumTypeShowMessage.npsQuestionHidden },
                { 'msg-not-read-receipt': !messageAttendance.readReceipt })}
            ref={setMessagesEnd ? (el) => setMessagesEnd(el) : null}>
            {content}
        </div>
        </>;
    }

    const renderMessages = () => {
        let messageList = data?.sort((t1: any, t2: any) => moment(t1.dateText).diff(moment(t2.dateText))) || null;

        if (listMessagesOption !== 'todas')
            messageList = messageList?.filter(m => m.fromMe === (listMessagesOption === 'enviadas')) || null;

        if (contactMessageSelected) {
            messageList = messageList?.filter(m => m.jid === contactMessageSelected.jid) || null;
            if (data && messageList && messageList.length === 0 && data.length > 0) messageList = null;
        }

        // messageList = messageList?.filter(m => !m.isObject || (m.isObject === true && m.mimetype !== '')) || null;

        messageList = filterTextMessage && filterTextMessage.length > 0 ?
            messageList?.filter(m => ((m.pushName && m.pushName.toLowerCase().indexOf(filterTextMessage.toLowerCase()) >= 0)
                || (m.text && m.text.toLowerCase().indexOf(filterTextMessage.toLowerCase()) >= 0))) || null
            : messageList;

        if (scrollMessages && messagesEnd) scrollToBottom();

        // console.log('data: ', !!data, data?.length);
        // console.log('messageList: ', !!messageList, messageList?.length);
        let sizeMessages = messageList?.length || 0;

        if (!messageList) {
            // console.log('Mensagens-1: ', messageList);
            return (<div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Carregando mensagens...</h3>
            </div>);
        } else if (messageList.length === 0) {
            // console.log('Mensagens-2: ', messageList?.length);
            return (<div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Nenhuma mensagem localizada!</h3>
            </div>);
        } else if (messageList.length !== 0) {
            // console.log('Mensagens-3: ', messageList?.length);
            return messageList.map((m, i) => {
                // console.log('m.typeShowMessage: ', m.typeShowMessage);
                // let contact = trackerContactMessages.find(c => c.jid === m.jid);
                // contact = contact ? contact.pushName : 'desconhecido';
                const content = renderMessage(m, contactMessageSelected, prettyMessage);

                // return renderMessageInteraction(contactMessageSelected, m, content, i === messageList.length - 1 ? setMessagesEnd : undefined);

                return m.typeShowMessage === EnumTypeShowMessage.npsQuestionHidden.toString() && !checkRoleHierarchyByToken(RoleRules.admin) ?
                    checkRoleHierarchyByToken(RoleRules.owner) ?
                        renderMessageInteraction(contactMessageSelected, m, content, i === sizeMessages - 1 || 0 ?
                            setMessagesEnd :
                            undefined) :
                        null :
                    renderMessageInteraction(contactMessageSelected, m, content, i === sizeMessages - 1 || 0 ? setMessagesEnd : undefined);

            });
        }

    }

    const renderMessage = (m: IFormDataAttendance, contactMessageSelected: any, prettyMessage: (message: string) => string) => {
        let contact = m.pushName; // contact ? contact.pushName : 'desconhecido';

        let quoteText;
        let reply;

        if (contactMessageSelected.assumedBy && contactMessageSelected.assumedBy?._id === customerId &&
            props.setMessageReplyId && props.setMessageReplyText && props.setOriginalMessageReply && m.messageId && m.originalMessage
        ) {

            reply = <>
                <div className='button-reply-area'>
                    <div className={classNames('button-reply', { 'button-reply-me': m.fromMe, 'button-reply-frnd': !m.fromMe })}
                        data-toggle="tooltip" title="Responder"
                        onClick={() => {
                            if (props.setMessageReplyId && m.messageId) props.setMessageReplyId(m.messageId);
                            if (props.setMessageReplyText) if (m.text) props.setMessageReplyText(m.text); else props.setMessageReplyText('objeto compartilhado');
                            if (props.setOriginalMessageReply && m.originalMessage) props.setOriginalMessageReply(m.originalMessage);
                        }}>
                        <i className={classNames('pi', 'pi-reply')} ></i>
                    </div>
                </div>
            </>
        }

        // if (m.originalMessage?.message?.orderMessage?.orderTitle) {
        //     console.log('Message: ', m);
        // }
        // if (m.originalMessage?.message?.extendedTextMessage?.contextInfo?.quotedMessage) {
        //     console.log('m.originalMessage?.message?.extendedTextMessage?.contextInfo?.quotedMessage: ', m);
        // }

        if (m.originalMessage?.message?.extendedTextMessage?.contextInfo?.quotedMessage?.conversation) {
            quoteText = <div className='flex-initial flex align-items-right justify-content-right' style={{ margin: '10px', backgroundColor: '#ddd', padding: '7px', border: 'dotted 1px cadetblue', borderRadius: '5px', opacity: 0.6 }}>
                <span className='flex-initial flex flex-wrap'>{m.originalMessage?.message?.extendedTextMessage?.contextInfo?.quotedMessage?.conversation}</span>
            </div>;
        }

        if (m.originalMessage?.message?.extendedTextMessage?.contextInfo?.quotedMessage?.extendedTextMessage) {
            quoteText = <div className='flex-initial flex align-items-right justify-content-right' style={{ margin: '10px', backgroundColor: '#ddd', padding: '7px', border: 'dotted 1px cadetblue', borderRadius: '5px', opacity: 0.6 }}>
                <span className='flex-initial flex flex-wrap'>{m.originalMessage?.message?.extendedTextMessage?.contextInfo?.quotedMessage?.extendedTextMessage?.text}</span>
            </div>;
        }

        if (m.originalMessage?.message?.imageMessage?.contextInfo?.quotedMessage?.conversation) {
            quoteText = <div className='flex-initial flex align-items-right justify-content-right' style={{ margin: '10px', backgroundColor: '#ddd', padding: '7px', border: 'dotted 1px cadetblue', borderRadius: '5px', opacity: 0.6 }}>
                <span className='flex-initial flex flex-wrap'>{m.originalMessage?.message?.imageMessage?.contextInfo?.quotedMessage?.conversation}</span>
            </div>;
        }




        if (m && m.isObject
            && ((!m.mimetype && !m.originalMessage?.message?.orderMessage?.orderTitle)
                || (m.mimetype
                    && !m.mimetype.includes('text/plain') && !m.mimetype.includes('image') && !m.mimetype.includes('video') && !m.mimetype.includes('audio') && !m.mimetype.includes('pdf') && !m.mimetype.includes('msexcel') && !m.mimetype.includes('ms-excel') && !m.mimetype.includes('openxmlformats-officedocument.spreadsheetml.sheet')
                    && !m.mimetype.includes('msword') && !m.mimetype.includes('ms-word') && !m.mimetype.includes('openxmlformats-officedocument.wordprocessingml.document')
                    && !m.mimetype.includes('mspowerpoint') && !m.mimetype.includes('ms-powerpoint') && !m.mimetype.includes('openxmlformats-officedocument.presentationml.presentation')
                    && !m.originalMessage?.message?.orderMessage?.orderTitle
                ))) {
            return (<div className='msg_context msg_context_unknown'>
                {!contactMessageSelected?.jid || m.group ? <span className='msg_contact'>{contact}</span> : null}
                <>
                    {reply}
                    {quoteText}
                    <div className='flex-initial flex align-items-left justify-content-left' style={{ marginTop: 0 }}>
                        <span className='flex-initial flex flex-wrap'>Contato enviou conteúdo recusado ou não reconhecido pelo sistema!</span>
                    </div>
                    {m.mimetype ?
                        <div className='flex-initial flex align-items-left justify-content-left' style={{ padding: 0, paddingLeft: 10 }}>
                            <span className='flex-initial flex flex-wrap'>Tipo: {m.mimetype}</span>
                        </div>
                        : null}
                    {m.text ?
                        <div className='flex-initial flex align-items-left justify-content-left' style={{ padding: 0, paddingLeft: 10 }}>
                            <span className='flex-initial flex flex-wrap' dangerouslySetInnerHTML={{ __html: prettyMessage(m.text) }}></span>
                        </div>
                        : null}
                </>
                <span className={classNames('msg_time', `tooltip_msg_${m._id}_${moment(m.dateText).format('yyyyDDMMHHmmss')}`)}>{moment(m.dateText).format('DD-MM-yyyy HH:mm')}
                    <i className={classNames('pi', { 'pi-circle': !m.readReceipt }, { 'pi-check-circle': m.readReceipt })} style={{ fontSize: 10, marginTop: 3, marginLeft: 10 }}></i>
                </span>
                <Tooltip target={`.tooltip_msg_${m._id}_${moment(m.dateText).format('yyyyDDMMHHmmss')}`} content={`${moment(m.dateText).format('DD-MM-yyyy HH:mm')}`} />
            </div>);
        }
        let contactVcard = m.vcard ? vCard.parse(m.vcard) : null;
        // console.log('contactVcard: ', JSON.stringify(contactVcard));
        const content = <div className='msg_context'>
            {!contactMessageSelected?.jid || m.group ? <span className='msg_contact'>{contact}</span> : null}
            {!m.isObject ?
                m.typeShowMessage === EnumTypeShowMessage.npsQuestionHidden.toString() && !checkRoleHierarchyByToken(RoleRules.owner) ?
                    <span dangerouslySetInnerHTML={{ __html: '<< sigilo de resposta da avaliação >>' }}></span>
                    :
                    m.text ?
                        <>

                            {reply}
                            {quoteText}
                            <div className='flex-initial flex align-items-left justify-content-left' style={{ padding: 0, paddingLeft: 10 }}>
                                <span className='flex-initial flex flex-wrap' dangerouslySetInnerHTML={{ __html: prettyMessage(m.text) }}></span>
                            </div>
                        </> : null
                : null}
            {contactVcard ?
                <>
                    <div className='flex flex-column'>
                        <span>Nome: {contactVcard?.fn?.map((fn: any) => fn?.value).join()}</span>
                        <span>Número: {contactVcard?.tel?.map((t: any) => t?.value).join()}</span>
                    </div>
                </>
                :
                null}
            {m.originalMessage?.message?.orderMessage?.orderTitle ?
                <>
                    {reply}
                    {quoteText}
                    <div className='flex-initial flex align-items-left justify-content-left'>
                        <span className='flex-initial flex flex-wrap' dangerouslySetInnerHTML={{ __html: prettyMessage(`*Opção do catálogo selecionada*: ${m.originalMessage?.message?.orderMessage?.orderTitle}\n- ${m.originalMessage?.message?.orderMessage?.itemCount} item(s).`) }}></span>
                    </div>
                    {m.text ?
                        <div className='flex-initial flex align-items-center justify-content-center'>
                            <span className='flex-initial flex flex-wrap' dangerouslySetInnerHTML={{ __html: prettyMessage(m.text) }}></span>
                        </div>
                        : null}
                </>
                :
                null}
            {m && m.mimetype && m.mimetype.includes('image') ?
                <>
                    {reply}
                    {quoteText}
                    <div className='flex-initial flex align-items-center justify-content-center'>
                        <Image className='flex-initial flex flex-wrap' downloadable={true} src={`${m.pathCDN}`} alt="Imagem" height="100" preview />
                        {/* <Image className='flex-initial flex flex-wrap' downloadable={true} src={`${m.pathCDN}`} alt="Imagem" height="100" preview
                    onContextMenu={(e) => e.preventDefault()}></Image> */}
                    </div>
                    {m.text ?
                        <div className='flex-initial flex align-items-center justify-content-center'>
                            <span className='flex-initial flex flex-wrap' dangerouslySetInnerHTML={{ __html: prettyMessage(m.text) }}></span>
                        </div>
                        : null}
                </>
                :
                null}

            {m && m.mimetype && m.mimetype.includes('video') ?
                <>
                    {reply}
                    {quoteText}
                    <div className='flex-initial flex align-items-center justify-content-center'>
                        <video className='flex-initial flex flex-wrap' width="400" height="200" controls>
                            <source src={`${m.pathCDN}`} type={m.mimetype} />
                        </video>
                    </div>
                    {m.text ?
                        <div className='flex-initial flex align-items-center justify-content-center'>
                            <span className='flex-initial flex flex-wrap' dangerouslySetInnerHTML={{ __html: prettyMessage(m.text) }}></span>
                        </div>
                        : null}
                </>
                : null}
            {m && m.mimetype && m.mimetype.includes('audio') ?
                <>
                    {reply}
                    {quoteText}
                    <div className='flex-initial flex align-items-center justify-content-center'>
                        <audio className='flex-initial flex flex-wrap' controls>
                            <source src={`${m.pathCDN}`} type={m.mimetype} />
                        </audio>
                    </div>
                    {m.text ?
                        <div className='flex-initial flex align-items-center justify-content-center'>
                            <span className='flex-initial flex flex-wrap' dangerouslySetInnerHTML={{ __html: prettyMessage(m.text) }}></span>
                        </div>
                        : null}
                </>
                : null}
            {m && m.mimetype && (m.mimetype.includes('text/plain') || m.mimetype.includes('pdf') || m.mimetype.includes('msexcel') || m.mimetype.includes('ms-excel') || m.mimetype.includes('openxmlformats-officedocument.spreadsheetml.sheet')
                || m.mimetype.includes('msword') || m.mimetype.includes('ms-word') || m.mimetype.includes('openxmlformats-officedocument.wordprocessingml.document')
                || m.mimetype.includes('mspowerpoint') || m.mimetype.includes('ms-powerpoint') || m.mimetype.includes('openxmlformats-officedocument.presentationml.presentation')) ?
                <>
                    {m && m.mimetype && m.mimetype.includes('pdf') ?
                        <>
                            {reply}
                            {quoteText}
                            {m.jpegThumbnail ?
                                <div className='flex-initial flex align-items-center justify-content-center'>
                                    {/* <Image className='flex-initial flex flex-wrap' src={`${m.jpegThumbnail ? `data:image/jpg;base64,${m.jpegThumbnail}` : ''}`} alt="Arquivo" height="80"
                                                onContextMenu={(e) => e.preventDefault()}></Image> */}
                                    <Image className='flex-initial flex flex-wrap' src={`${m.jpegThumbnail ? `data:image/jpg;base64,${m.jpegThumbnail}` : ''}`} alt="Arquivo" height="80" />
                                </div>
                                :
                                <div className='flex-initial flex align-items-center justify-content-center'>
                                    <i className="pi pi-file-pdf" style={{ fontSize: '4rem' }}></i>
                                </div>
                            }
                        </>
                        : null}
                    {m.mimetype.includes('msexcel') || m.mimetype.includes('ms-excel') || m.mimetype.includes('openxmlformats-officedocument.spreadsheetml.sheet') ?
                        <>
                            {reply}
                            {quoteText}
                            <div className='flex-initial flex align-items-center justify-content-center'>
                                <i className="pi pi-file-excel" style={{ fontSize: '4rem' }}></i>
                            </div>
                        </>
                        : null}
                    {m.mimetype.includes('msword') || m.mimetype.includes('ms-word') || m.mimetype.includes('openxmlformats-officedocument.wordprocessingml.document') ?
                        <>
                            {reply}
                            {quoteText}
                            <div className='flex-initial flex align-items-center justify-content-center'>
                                <i className="pi pi-file-word" style={{ fontSize: '4rem' }}></i>
                            </div>
                        </>
                        : null}
                    {m.mimetype.includes('mspowerpoint') || m.mimetype.includes('ms-powerpoint') || m.mimetype.includes('openxmlformats-officedocument.presentationml.presentation') ?
                        <>
                            {reply}
                            {quoteText}
                            <div className='flex-initial flex align-items-center justify-content-center'>
                                <i className="pi pi-file" style={{ fontSize: '4rem' }}></i>
                            </div>
                        </>
                        : null}
                    {m.mimetype.includes('text/plain') ?
                        <>
                            {reply}
                            {quoteText}
                            <div className='flex-initial flex align-items-center justify-content-center'>
                                <i className="pi pi-file" style={{ fontSize: '4rem' }}></i>
                            </div>
                        </>
                        : null}

                    {m.pathCDN ?
                        <>
                            {reply}
                            {quoteText}
                            <div className='flex-initial flex align-items-center justify-content-center' style={{ padding: 0, paddingLeft: 10 }}>
                                <a href={`${m.pathCDN}`} download target='_blank' rel="noreferrer">
                                    <Button label="Clique para realizar o download." icon="pi pi-download" />
                                </a>
                            </div>
                        </>
                        : null}
                    {m.text ?
                        <>
                            {reply}
                            {quoteText}
                            <div className='flex-initial flex align-items-left justify-content-left' style={{ padding: 0, paddingLeft: 10 }}>
                                <span className='flex-initial flex flex-wrap' dangerouslySetInnerHTML={{ __html: prettyMessage(m.text) }}></span>
                            </div>
                        </>
                        : null}
                </>
                : null}
            {m.reactText ?
                <span className='react-message-text'>{m.reactText}</span>
                : null}
            <span className={classNames('msg_time', `tooltip_msg_${m._id}_${moment(m.dateText).format('yyyyDDMMHHmmss')}`)}>{moment(m.dateText).format('DD-MM-yyyy HH:mm')}
                <i className={classNames('pi', { 'pi-circle': !m.readReceipt }, { 'pi-check-circle': m.readReceipt })} style={{ fontSize: 10, marginTop: 3, marginLeft: 10 }}></i>
            </span>
            <Tooltip target={`.tooltip_msg_${m._id}_${moment(m.dateText).format('yyyyDDMMHHmmss')}`} content={`${moment(m.dateText).format('DD-MM-yyyy HH:mm')}`} />
        </div >;
        return content;
    }

    const scrollToBottom = () => {
        messagesEnd?.scrollIntoView({ behavior: 'auto' });
        setScrollMessages(false);
    }



    // console.log('data: ', data);


    if (!contactMessageSelected)
        return (<div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
            <h3>Selecione um contato.</h3>
        </div>);
    else
        return (
            <>
                {renderMessages()}
                <div style={{ minHeight: 50 }}>
                    &nbsp;
                </div>
            </>
        );
};


