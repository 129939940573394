import { useEffect } from 'react';
import { IPropsArgsComponents } from '../generics/formInterfaces';

// db.networkgyms.updateMany({}, { $unset: { analitycUrl: "", analyticUrl: "" } })
// db.networkgyms.update({key: 'SUMARÉ ESPORTES'}, {$set:{analyticUrl:'https://app.powerbi.com/view?r=eyJrIjoiZDAxYTQxZDctNjZjNy00MTZlLWEyMTEtNTNkZDQ1MGMxY2RlIiwidCI6IjIxMDZhNmE5LTQyYzAtNDhkNC04YmU4LTYzMTkxNDAxMGNjNyJ9'}});
// db.networkgyms.update({key: 'CHATDEMO '}, {$set:{analyticUrl:'https://app.powerbi.com/view?r=eyJrIjoiNDM0MWUwOWQtODMwYy00MWYyLTgyZjQtZmU2YmU5NDkyY2FjIiwidCI6IjIxMDZhNmE5LTQyYzAtNDhkNC04YmU4LTYzMTkxNDAxMGNjNyJ9'}});
// db.networkgyms.update({key: 'MIND FIT'}, {$set:{analyticUrl:'https://app.powerbi.com/view?r=eyJrIjoiYzU0ZWI4MDEtMzBmMC00MDc5LWJiNGEtN2NmMWI4NzRmNWIwIiwidCI6IjIxMDZhNmE5LTQyYzAtNDhkNC04YmU4LTYzMTkxNDAxMGNjNyJ9'}});

export const Analytic = (props: IPropsArgsComponents) => {
    const openNewTab = () => {
        // console.log('props.args.networkGymSelected?.analyticUrl: ', props.args.networkGymSelected);
        window.open(props.args.networkGymSelected?.analyticUrl, '_blank');
    };
    const goBack = () => window.history.back(); // Volta uma página no histórico

    useEffect(() => { openNewTab(); goBack(); }, []);

    return (<></>);
}