/* eslint-disable no-eval */
/* eslint-disable react-hooks/exhaustive-deps */
import moment, { Moment, isDate } from 'moment';
import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Chart } from 'primereact/chart';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import ls from '../../../projects/localStorage';
import { makeKeyByString } from '../../../utils/functions/hash';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import BranchGymExternalUserService from '../../service/admin/BranchGymExternalUserService';
import DashboardService from '../../service/admin/DashboardService';
import NetworkGymExternalUserService from '../../service/admin/NetworkGymExternalUserService';
// import DashboardService from '../../service/admin/DashboardService';
// import { IFormDataDashboard } from '../generics/entities';
import { IPropsArgsComponents } from '../generics/formInterfaces';
import { createView } from '../generics/view';
import './Dashboard.css';

// const dashboardService = new DashboardReportService();
const dashboardService = new DashboardService();
const branchGymExternalUserService = new BranchGymExternalUserService();
const networkGymExternalUserService = new NetworkGymExternalUserService();

const colors = [
    { bc1: '#074a38', bc2: '#686968' },
    { bc1: '#137C60', bc2: '#686968' },
    { bc1: '#238269', bc2: '#686968' },
    { bc1: '#43AC90', bc2: '#686968' },
    { bc1: '#73DCC0', bc2: '#686968' },
    { bc1: '#93bfb4', bc2: '#686968' },
    { bc1: '#aae3d4', bc2: '#686968' },
    { bc1: '#b5c9e8', bc2: '#686968' },
    { bc1: '#95a9c7', bc2: '#686968' },
    { bc1: '#6483b0', bc2: '#686968' },
    { bc1: '#5a85c4', bc2: '#686968' },
    { bc1: '#375582', bc2: '#686968' },
    { bc1: '#2c6ed1', bc2: '#686968' },
    { bc1: '#116cf5', bc2: '#686968' }
]

let listStatusConversations = [{ rep: 'conversation', ref: 'register', qtty: 0, description: 'Encerradas', fields: { contactMessageFinished: true } },
{ rep: 'conversation', ref: 'register', qtty: 0, description: 'Não Encerradas', fields: { contactMessageFinished: false } }];

let listConversationReleaseds = [{ rep: 'conversation', ref: 'register', qtty: 0, description: 'Atendidas', fields: { contactMessageAvailabled: false } },
{ rep: 'conversation', ref: 'register', qtty: 0, description: 'Disponibilizadas', fields: { contactMessageAvailabled: true } }];

let listConversationsNps = [{ rep: 'conversation', ref: 'register', qtty: 0, description: 'Não Avaliadas', fields: { contactMessageEvaluated: false } },
{ rep: 'conversation', ref: 'register', qtty: 0, description: '1. Ruim', fields: { contactMessageEvaluated: true, contactMessageEvaluationValue: '1' } },
{ rep: 'conversation', ref: 'register', qtty: 0, description: '2. Razoável', fields: { contactMessageEvaluated: true, contactMessageEvaluationValue: '2' } },
{ rep: 'conversation', ref: 'register', qtty: 0, description: '3. Bom', fields: { contactMessageEvaluated: true, contactMessageEvaluationValue: '3' } },
{ rep: 'conversation', ref: 'register', qtty: 0, description: '4. Muito bom', fields: { contactMessageEvaluated: true, contactMessageEvaluationValue: '4' } },
{ rep: 'conversation', ref: 'register', qtty: 0, description: '5. Excelente', fields: { contactMessageEvaluated: true, contactMessageEvaluationValue: '5' } }];

let listConversationSchedules = [{ rep: 'conversation', ref: 'register', qtty: 0, description: 'Agendadas', fields: { contactMessageScheduled: true } },
{ rep: 'conversation', ref: 'register', qtty: 0, description: 'Não Agendadas', fields: { contactMessageScheduled: false } }];



let listScheduleStatus = [{ rep: 'schedule', ref: 'register', qtty: 0, description: 'Abertos', fields: { scheduleFinished: false }, evalFields: { date: `moment(obj).isAfter(serverSocketDateTime.set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0))` } },
{ rep: 'schedule', ref: 'register', qtty: 0, description: 'Pendentes', fields: { scheduleFinished: false }, evalFields: { date: `moment(obj).isBefore(serverSocketDateTime.set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0))` } },
{ rep: 'schedule', ref: 'register', qtty: 0, description: 'Encerrados', fields: { scheduleFinished: true } }];

let listSchedules = [{ rep: 'schedule', ref: 'register', qtty: 0, description: 'Via Chat', fields: { scheduleByChat: true } },
{ rep: 'schedule', ref: 'register', qtty: 0, description: 'Via Tela de Agendamento', fields: { scheduleByChat: false } }];

let listSchedulesType = [{ rep: 'schedule', ref: 'register', qtty: 0, description: 'Visita Programada', fields: { scheduleReason: 'visit' } },
{ rep: 'schedule', ref: 'register', qtty: 0, description: 'Aula Experimental', fields: { scheduleReason: 'class' } },
{ rep: 'schedule', ref: 'register', qtty: 0, description: 'Contato Pessoal', fields: { scheduleReason: 'contact' } },
{ rep: 'schedule', ref: 'register', qtty: 0, description: 'Follow-up', fields: { scheduleReason: 'followup' } }];

let listAttendantsConversationAssumeds = [{ rep: 'conversation', ref: 'assumed', qtty: 0 }];
let listAttendantsConversationReleaseds = [{ rep: 'conversation', ref: 'availabled', qtty: 0 }];
let listAttendantsConversationScheduleds = [{ rep: 'conversation', ref: 'scheduled', qtty: 0 }];

let listAttendantsScheduledOpeneds = [{ rep: 'schedule', ref: 'created', qtty: 0 }];
let listAttendantsScheduledCloseds = [{ rep: 'schedule', ref: 'finished', qtty: 0 }];



export const Dashboard = (props: IPropsArgsComponents) => {
    const [title] = useState('Dashboard');
    const [token] = useState(props.args.token);
    // const [data, setData] = useState<IFormDataDashboard[]>([]);
    const [loadingData, setLoadingData] = useState(true);
    const [networkGymId, setNetworkGymId] = useState(props.args.networkGymId);
    const [branchGymId, setBranchGymId] = useState(props.args.branchGymId);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [serverSocketDateTime] = useState<Moment>(ls.socket.getServerSocketDateTime());


    const [dashboardReport, setDashboardReport] = useState<any[] | null>();
    const [attendants, setAttendants] = useState<any[] | null>();
    const [attendantOthers, setAttendantOthers] = useState<any[] | null>();
    const [attendantOwners, setAttendantOwners] = useState<any[] | null>();

    const [attendantSelectedReport, setAttendantSelectedReport] = useState<any | null>();
    const [attendantSelected, setAttendantSelected] = useState<any | null>();
    const [startDateMessages, setStartDateMessages] = useState<Date>(moment().subtract(7, 'days').toDate());
    const [startDateMessageReports, setStartDateMessageReports] = useState<Date>(moment().subtract(7, 'days').toDate());
    const [endDateMessages, setEndDateMessages] = useState<Date>(moment().add(7, 'days').toDate());
    const [endDateMessageReports, setEndDateMessageReports] = useState<Date>(moment().add(7, 'days').toDate());

    const [showBeforeList, setShowBeforeList] = useState<boolean>(false);

    const [listChartData, setListChartData] = useState<any>();
    const [showDialogListChart, setShowDialogListChart] = useState<boolean>(false);

    useEffect(() => { setNetworkGymId(props.args.networkGymId); }, [props.args.networkGymId]);
    useEffect(() => { setBranchGymId(props.args.branchGymId); }, [props.args.branchGymId]);
    // useEffect(() => defineAttendants(dashboardReport), [dashboardReport]);

    useEffect(() => { refreshListAttendantOthers(); refreshListAttendantOwners(); }, [networkGymId, branchGymId]);

    useEffect(() => {

        let attendants = [];
        if (attendantOthers) attendants.push(...attendantOthers);
        if (attendantOwners) attendants.push(...attendantOwners);

        attendants.sort((a: any, b: any) => a.nickname.localeCompare(b.nickname));
        attendants = attendants.sort((a: any, b: any) => a.nickname.localeCompare(b.nickname));
        setAttendants(attendants);
    }, [attendantOthers, attendantOwners]);

    useEffect(() => {
        refreshList();
    }, [networkGymId, branchGymId, attendantSelectedReport, startDateMessageReports, endDateMessageReports]);

    useEffect(() => {
        props.args.setUpdateConsidered([
            { key: 'networkGym', show: true, considered: true },
            { key: 'branchGym', show: true, considered: false },
        ])
    }, []);

    useEffect(() => {
        if (!token) window.location.href = '/';
    }, [token]);



    const refreshList = () => {
        if (networkGymId) {
            let isMounted = true;
            props.args.setLoading(true);
            // console.log('refreshList: ', networkGymId, branchGymId, attendantSelectedReport, startDateMessageReports, endDateMessageReports);
            dashboardService.findAllDashboard(networkGymId, branchGymId, attendantSelectedReport,
                startDateMessageReports, moment(endDateMessageReports).add(1, 'days').toDate()).then((returned: any) => {
                    if (isMounted) {
                        if (returned.status === HttpStatus.UNAUTHORIZED) {
                            props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                            setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                            setDashboardReport([]);
                        } else if (isSuccess(returned.status)) {
                            let returnedDashboardService = returned.data;
                            returnedDashboardService = returnedDashboardService.filter(async (r: any) => (await ls.branchGym.getBranchGymsByStorageToken())?.map(b => b._id)?.includes(r.branchGymId));
                            setDashboardReport(returnedDashboardService);
                            setLoadingData(false);
                        } else {
                            setDashboardReport([]);
                            setLoadingData(false);
                        }
                        props.args.setLoading(false);
                    }
                }).catch(error => {
                    console.error('error', error);
                    if (isMounted) {
                        props.args.setLoading(false);
                        setDashboardReport([]);
                    }
                });
            return () => { isMounted = false };
        }
    };

    const refreshListAttendantOwners = () => {
        if (networkGymId) {
            let isMounted = true;
            // props.args.setLoading(true);
            networkGymExternalUserService.allNetworkGymExternalUsersByNetworkGym(networkGymId).then((returned: any) => {
                if (isMounted) {
                    if (returned.status === HttpStatus.UNAUTHORIZED) {
                        props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                        setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                        setAttendantOwners([]);
                    } else if (isSuccess(returned.status)) {
                        let attendantOwners = returned.data;
                        // console.log('attendantOwners: ', attendantOwners);
                        attendantOwners = attendantOwners.filter((c: any) => c.role === 'owner');
                        // console.log('attendantOwners: ', attendantOwners);
                        attendantOwners = attendantOwners.map((d: any) => d.customer);
                        // console.log('attendantOwners: ', attendantOwners);
                        attendantOwners = attendantOwners.filter((c: any) => c.blocked === false);
                        // console.log('attendantOwners: ', attendantOwners);
                        setAttendantOwners(attendantOwners);
                    } else {
                        setAttendantOwners([]);
                    }
                    // props.args.setLoading(false);
                }
            }).catch(error => {
                console.error('error', error);
                if (isMounted) {
                    // props.args.setLoading(false);
                    setAttendantOwners([]);
                }
            });
            return () => { isMounted = false };
        }
    };

    const refreshListAttendantOthers = () => {
        if (networkGymId) {
            let isMounted = true;
            // props.args.setLoading(true);
            branchGymExternalUserService.allNetworkGymExternalUsers(networkGymId, branchGymId).then((returned: any) => {
                if (isMounted) {
                    if (returned.status === HttpStatus.UNAUTHORIZED) {
                        props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                        setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                        setAttendantOthers([]);
                    } else if (isSuccess(returned.status)) {
                        let attendantOthers = returned.data;
                        // attendants = attendants.sort((a: any, b: any) => a.nickname.localeCompare(b.nickname));
                        // console.log('attendantOthers: ', attendantOthers);
                        attendantOthers = attendantOthers.map((d: any) => d.customer);
                        // console.log('attendantOthers: ', attendantOthers);
                        attendantOthers = attendantOthers.filter((c: any) => c.blocked === false)
                        // console.log('attendantOthers: ', attendantOthers);
                        setAttendantOthers(attendantOthers);
                    } else {
                        setAttendantOthers([]);
                    }
                    // props.args.setLoading(false);
                }
            }).catch(error => {
                console.error('error', error);
                if (isMounted) {
                    // props.args.setLoading(false);
                    setAttendantOthers([]);
                }
            });
            return () => { isMounted = false };
        }
    };

    const lightOptions = (
        title: { text?: string, display?: boolean, font?: { size?: number } },
        legend?: { position?: string, labels?: { color?: string } }) => {
        if (!title.display) title.display = true;
        if (!title.font) title.font = { size: 16 };
        if (!title.font.size) title.font.size = 16;
        if (!legend) legend = {};
        if (!legend.position) legend.position = 'top';
        if (!legend.labels) legend.labels = {};
        if (!legend.labels.color) legend.labels.color = '#495057';
        return {
            plugins: {
                title: { display: title.display, text: title.text, font: title.font },
                legend: { position: legend.position, labels: { color: legend.labels?.color } }
            }
        };
    }

    const colorByPosition = (pos: number) => {
        let lpos = pos;
        if (colors?.length && colors.length === 0) return { bc1: '#074a38', bc2: '#9fab55' };
        while (lpos > colors.length - 1) lpos = lpos - colors.length;
        return colors[lpos];
    }

    const createChartPieAttendants = (type: string, title: string, list: any[], args: { class: string }) => {
        const moment = require('moment');
        const titles: any[] = [];
        const graphics: any[] = [];
        if (moment && attendants && dashboardReport) {
            let listByAttendants = [];
            for (let i = 0; i < attendants.length; i++) {
                const attendant = attendants[i];
                listByAttendants.push(...list.map((l: any) => { return { ...l, description: attendant.nickname, userReference: attendant._id } }));
            }
            // console.log('listByAttendants: ', listByAttendants);

            // listByAttendants.forEach((l: any) => {
            //     l.qtty = dashboardReport.reduce((p: number, c: any) =>
            //         p += c.report === l.rep && c.referenceReport === l.ref && (!l.userReference || c.userReference?._id === l.userReference)
            //             && (!l.hasValueReport || c.valueReport) && (!l.valueReport || l.valueReport === c.valueReport) ? 1 : 0, 0);
            //     l.details = dashboardReport.filter((c: any) => c.report === l.rep && c.referenceReport === l.ref && (!l.userReference || c.userReference?._id === l.userReference)
            //         && (!l.hasValueReport || c.valueReport) && (!l.valueReport || l.valueReport === c.valueReport));
            // });

            listByAttendants.forEach((l: any) => {
                l.details = dashboardReport.filter((c: any) => {
                    let valid = true;
                    if (l.fields) for (const key in l.fields) valid = valid && l.fields[key] === c[key];
                    if (l.evalFields) {
                        for (const key in l.evalFields) {
                            const obj = c[key];
                            // console.log('obj: ', l.rep, l.ref, key, obj);
                            valid = valid && obj && eval(l.evalFields[key]);
                        }
                    }
                    return valid === true && c.report === l.rep && c.referenceReport === l.ref && (!l.userReference || c.userReferenceId === l.userReference);
                });

                l.qtty = l.details.length;
                // l.qtty = l.details.reduce((p: number, c: any) => p += c.report === l.rep && c.referenceReport === l.ref ? 1 : 0, 0);
            });

            // console.log('listByAttendants: ', listByAttendants);

            let labels = listByAttendants.map(c => c.description);
            let dataset = {
                data: listByAttendants.map(c => c.qtty),
                backgroundColor: listByAttendants.map((c: any, i: number) => colorByPosition(i).bc1),
                hoverBackgroundColor: listByAttendants.map((c: any, i: number) => colorByPosition(i).bc2)
            };

            let details: any[] = listByAttendants.map(c => c.details);

            titles.push(<div key={`titles_${makeKeyByString(title)}`} className={classNames('flex justify-content-center', args.class)}>
                <span>{`${title}`}</span>
            </div>);

            graphics.push(<div key={`graphics_pie_${makeKeyByString(title)}`} className={args.class}>
                {listByAttendants.reduce((p, c) => p += c.qtty, 0) > 0 ?
                    <span key={`graphics_pie_${makeKeyByString(title)}`}>{chartPie(type, title, labels, dataset, details, args)}</span>
                    :
                    <div key={`graphics_nopie_${makeKeyByString(title)}`} className='flex p-fluid col-12 align-items-middle justify-content-middle' style={{ color: '#DBA00D' }}>
                        <h6>Não há dados parra análise que atendam as regras do filtro.</h6>
                    </div>
                }
            </div>);

        }
        return { titles, graphics };
    }

    const createChartPie = (type: string, title: string, list: any[], args: { class: string }) => {
        const moment = require('moment');
        const titles: any[] = [];
        const graphics: any[] = [];
        if (moment && dashboardReport) {
            list.forEach((l: any) => {
                l.details = dashboardReport.filter((c: any) => {
                    let valid = true;
                    if (l.fields) for (const key in l.fields) valid = valid && l.fields[key] === c[key];
                    if (l.evalFields) {
                        for (const key in l.evalFields) {
                            const obj = c[key];
                            // console.log('obj: ', l.rep, l.ref, key, obj, l.evalFields);
                            valid = valid && obj && eval(l.evalFields[key]);
                        }
                    }
                    return valid === true && c.report === l.rep && c.referenceReport === l.ref;
                });

                l.qtty = l.details.length;
                // l.qtty = l.details.reduce((p: number, c: any) => p += c.report === l.rep && c.referenceReport === l.ref ? 1 : 0, 0);
            });

            let labels = list.map(c => c.description);
            let dataset = {
                data: list.map(c => c.qtty),
                backgroundColor: list.map((c: any, i: number) => colorByPosition(i).bc1),
                hoverBackgroundColor: list.map((c: any, i: number) => colorByPosition(i).bc2)
            };

            let details: any[] = list.map(c => c.details);

            titles.push(<div key={`titles_${makeKeyByString(title)}`} className={classNames('flex justify-content-center', args.class)}>
                <span>{`${title}`}</span>
            </div>);

            graphics.push(<div key={`graphics_${makeKeyByString(title)}`} className={args.class}>
                {list.reduce((p, c) => p += c.qtty, 0) > 0 ?
                    <span key={`graphics_pie_${makeKeyByString(title)}`}>{chartPie(type, title, labels, dataset, details, args)}</span>
                    :
                    <div key={`graphics_nopie_${makeKeyByString(title)}`} className='flex p-fluid col-12 align-items-middle justify-content-middle' style={{ color: '#DBA00D' }}>
                        <h6>Não há dados parra análise que atendam as regras do filtro.</h6>
                    </div>
                }
            </div>);

        }
        return { titles, graphics };
    }

    const chartPie = (type: string, title: string, labels: string[], dataset: any, details?: any[], args?: { class: string }) => {
        // console.log('chartPie: ', title, labels, dataset, args);
        let showDetails = {
            onClick: function (evt: any, item: any) {
                let index: number | undefined;

                if (item && item.length > 0 && item[0]) index = item[0].index;

                // eslint-disable-next-line eqeqeq
                if (labels && details && (index === 0 || index != undefined)) {
                    setListChartData({ type, title, label: labels[index], details: details[index] });
                    setShowDialogListChart(true);
                }
            }
        }

        return <div className="flex justify-content-center">
            {/* ..., */}
            <Chart type="pie" data={{ labels, datasets: [dataset] }} options={Object.assign(lightOptions({}), showDetails)} />
        </div>;
    }

    const changeStartDateMessages = (e: CalendarChangeEvent) => {
        if (e.target.value instanceof Date) {
            if (isDate(e.target.value)) {
                setStartDateMessages(e.target.value);
                if (moment(e.target.value).isAfter(moment(endDateMessages)))
                    setEndDateMessages(moment(e.target.value).add(1, 'days').toDate());
            }
        }
    }

    const changeEndDateMessages = (e: CalendarChangeEvent) => {
        if (e.target.value instanceof Date) {
            if (isDate(e.target.value)) {
                setEndDateMessages(e.target.value);
                if (moment(e.target.value).isBefore(moment(startDateMessages)))
                    setStartDateMessages(moment(e.target.value).subtract(1, 'days').toDate());
            }
        }
    }

    const changeDataReport = (changeData: boolean) => {
        if (changeData) {
            setAttendantSelectedReport(attendantSelected);
            setStartDateMessageReports(startDateMessages);
            setEndDateMessageReports(endDateMessages);
            // refreshList();
        } else {
            setAttendantSelected(attendantSelectedReport);
            setStartDateMessages(startDateMessageReports);
            setEndDateMessages(endDateMessageReports);

        }
    }

    const listDataByAttendantSchedules = (dashboardReport?: any[] | null) => {
        return (prepareListDataGraphics([
            { type: 'schedule', title: 'Agendamentos Criados', list: listAttendantsScheduledOpeneds, args: { class: 'col-6' } },
            { type: 'schedule', title: 'Agendamentos Encerrados', list: listAttendantsScheduledCloseds, args: { class: 'col-6' } }
        ], createChartPieAttendants));
    }

    const listDataByAttendantConversations = (dashboardReport?: any[] | null) => {
        return (prepareListDataGraphics([
            { type: 'conversation', title: 'Conversas Assumidas', list: listAttendantsConversationAssumeds, args: { class: 'col-4' } },
            { type: 'conversation', title: 'Conversas Disponibilizadas', list: listAttendantsConversationReleaseds, args: { class: 'col-4' } },
            { type: 'conversation', title: 'Conversas Agendadas', list: listAttendantsConversationScheduleds, args: { class: 'col-4' } }
        ], createChartPieAttendants));
    }

    const listDataByConversations = (dashboardReport?: any[] | null) => {
        return (prepareListDataGraphics([
            { type: 'conversation', title: 'Status das Conversas', list: listStatusConversations, args: { class: 'col-3' } },
            { type: 'conversation', title: 'Conversas Disponibilizadas', list: listConversationReleaseds, args: { class: 'col-3' } },
            { type: 'conversation', title: 'Avaliações das Conversas', list: listConversationsNps, args: { class: 'col-3' } },
            { type: 'conversation', title: 'Agendamentos via Conversas', list: listConversationSchedules, args: { class: 'col-3' } }
        ], createChartPie));
    }

    const listDataBySchedules = (dashboardReport?: any[] | null) => {
        return (prepareListDataGraphics([
            { type: 'schedule', title: 'Status dos Agendamentos', list: listScheduleStatus, args: { class: 'col-4' } },
            { type: 'schedule', title: 'Origem dos Agendamentos', list: listSchedules, args: { class: 'col-4' } },
            { type: 'schedule', title: 'Motivo dos Agendamentos', list: listSchedulesType, args: { class: 'col-4' } }
        ], createChartPie));
    }

    const prepareListDataGraphics = (reportMethods: { type: string, title: string, list: any[], args: { class: string; } }[],
        method: (type: string, title: string, list: any[], args: { class: string }) => { titles: any[]; graphics: any[]; }) => {
        let returned = prepareListGraphics(reportMethods, method);
        return (
            <div key={makeKeyByString(returned?.titles?.join())} className="col-12 grid">
                {returned.titles}
                {returned.graphics}
            </div>);
    }

    const prepareListGraphics = (reportMethods: { type: string, title: string, list: any[], args: { class: string; } }[],
        method: (type: string, title: string, list: any[], args: { class: string }) => { titles: any[]; graphics: any[]; }) => {
        let returnedValue: { titles: any[]; graphics: any[]; } = { titles: [], graphics: [] };
        let returned: { titles: any[]; graphics: any[]; } = { titles: [], graphics: [] };

        for (let i = 0; i < reportMethods.length; i++) {
            const reportMethod = reportMethods[i];
            returnedValue = method(reportMethod.type, reportMethod.title, reportMethod.list, reportMethod.args)
            returned.titles.push(...returnedValue.titles); returned.graphics.push(...returnedValue.graphics);
        }

        return returned;
    }

    const listData = (dashboardReport?: any[] | null) => {

        if (!dashboardReport || !attendants || loadingData)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Buscando dados.</h3>
            </div>;

        // if (!attendants || attendants.length === 0)
        //     return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
        //         <h3>Não há dados cadastrais para o processamento dos gráficos.</h3>
        //     </div>;

        return (<>
            {createView(`Conversas consolidadas (${dashboardReport.filter((d: any) => d.report === 'conversation' && d.referenceReport === 'register').length})`,
                { lowTitle: true, listData: () => listDataByConversations(dashboardReport) })}
            {createView(`Agendamentos consolidados (${dashboardReport.filter((d: any) => d.report === 'schedule' && d.referenceReport === 'register').length})`,
                { lowTitle: true, listData: () => listDataBySchedules(dashboardReport) })}
        </>);
    }

    if (!networkGymId)
        return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
            <h3>Você precisa selecionar uma Rede.</h3>
        </div>;

    // if (!branchGymId && !checkRoleHierarchyByToken(RoleRules.owner))
    //     return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
    //         <h3>Você precisa selecionar uma Unidade.</h3>
    //     </div>;


    return (
        <div className='layout-content-dashboard'>
            {createView(title, {
                showBeforeList,
                setShowBeforeList,
                hideBeforeList: <div className="col-offset-1 col-11 grid" style={{ padding: 10, marginBottom: -20 }}>
                    <div className={'col-3'}>
                        <span style={{ marginLeft: 5, fontWeight: 'bold' }}>Atendentes: </span>
                        <span style={{ marginLeft: 5 }}>{
                            !!attendants && attendantSelectedReport ?
                                attendants.filter((a: any) => a._id === attendantSelectedReport).map((a: any) => a.nickname) : 'Todos'
                        } </span>

                    </div>
                    <div className={classNames(['col-2'])}>
                        <span style={{ marginLeft: 5, fontWeight: 'bold' }}>De: </span>
                        <span style={{ marginLeft: 5 }}>{moment(startDateMessageReports).format('DD/MM/YYYY')}</span>
                    </div>
                    <div className={classNames(['col-2'])}>
                        <span style={{ marginLeft: 5, fontWeight: 'bold' }}>Até: </span>
                        <span style={{ marginLeft: 5 }}>{moment(endDateMessageReports).format('DD/MM/YYYY')}</span>
                    </div>
                </div>,
                beforeList:
                    <div className="col-offset-1 col-11 grid" style={{ padding: 10 }}>
                        <div className={'col-3'}>
                            Atendentes: {!!attendants ? <Dropdown optionLabel="nickname" optionValue="_id"
                                value={attendantSelected}
                                options={[{ _id: null, nickname: 'Selecionar Todos' }, ...attendants]}
                                onChange={(e) => setAttendantSelected(e.value)} placeholder="Selecionar Todos" />
                                : null}
                        </div>
                        <div className={classNames(['col-5'])}>
                            <span>De:</span>
                            <Calendar id='startDateMessages' name={'startDateMessages'} value={startDateMessages}
                                onChange={changeStartDateMessages}
                                dateFormat={'dd/mm/yy'} showIcon={true}
                                locale={'pt-BR'}
                                className='date-messages2 start-date-messages2' />
                            <span>Até:</span>
                            <Calendar id='endDateMessages' name={'endDateMessages'} value={endDateMessages}
                                onChange={changeEndDateMessages}
                                dateFormat={'dd/mm/yy'} showIcon={true}
                                locale={'pt-BR'}
                                className='date-messages2 end-date-messages2' />
                        </div>
                        <div className={'col-2 align-items-start justify-content-start'} style={{ textAlign: 'left' }}>
                            <Button label='Buscar dados' type='button' onClick={() => { changeDataReport(true); setShowBeforeList(false); }} style={{ minWidth: 150 }}
                                className='p-button-rounded p-button p-button-success shadow-3' icon='pi pi-search' />
                        </div>
                        <div className={'col-2 align-items-start justify-content-start'} style={{ textAlign: 'left' }}>
                            <Button label='Fechar' type='button' onClick={() => { changeDataReport(false); setShowBeforeList(false); }} style={{ minWidth: 150 }}
                                className='p-button-rounded p-button p-button-warning shadow-3' icon='pi pi-times' />
                        </div>
                    </div>,
                // eslint-disable-next-line react-hooks/rules-of-hooks
                listData: () => showBeforeList ? <></> : listData(dashboardReport)
            })}
            {!showBeforeList ?
                !!attendants && attendants.length > 0 && !attendantSelectedReport && branchGymId && dashboardReport && dashboardReport.length > 0 ?
                    <>
                        {createView(`Comparativos de conversas por Atendente  (${dashboardReport.filter((d: any) => d.report === 'conversation' && d.referenceReport === 'register').length})`,
                            { lowTitle: true, listData: () => listDataByAttendantConversations(dashboardReport) })}
                        {createView(`Comparativos de agendamentos por Atendente  (${dashboardReport.filter((d: any) => d.report === 'schedule' && d.referenceReport === 'register').length})`,
                            { lowTitle: true, listData: () => listDataByAttendantSchedules(dashboardReport) })}
                    </>
                    : null
                : null}
            <div style={{ marginBottom: 100 }}>&nbsp; </div>

            <Dialog visible={showDialogListChart} style={{ width: '80%' }} modal={true} closable={false}
                onHide={() => setShowDialogListChart(false)}>

                <div className='card' style={{ backgroundColor: '#118CAC3C' }}>
                    <div className='grid p-fluid title-dialog'>
                        <div className='col-9'>
                            <i className='pi pi-fw pi-chart-pie layout-menuitem-icon' style={{ fontSize: 24, color: '#56ae93' }}></i>
                            <span className='title-dialog-value' style={{ fontSize: 24 }}>{`${listChartData?.title}: ${listChartData?.label}`}</span>
                        </div>
                        <div className='col-3 grid flex flex-nowrap justify-content-evenly'>
                            <Button label='Fechar' type='button' onClick={() => { setShowDialogListChart(false); }} style={{ minWidth: 150 }}
                                className='p-button-rounded p-button p-button-warning shadow-3' icon='pi pi-times' />
                        </div>
                    </div>
                </div>

                <div className='grid' style={{ width: '95%', margin: 20 }}>
                    <div className='flex p-fluid col-offset-8 col-4 align-items-center justify-content-center' style={{ margin: 20 }}>
                    </div>
                    <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ margin: 20 }}>
                        <div className='grid' style={{ minWidth: '90%' }}>
                            <div className='col-3' style={{ backgroundColor: '#074a38', border: '1px solid #000', color: '#fff', textAlign: 'center' }}>
                                {listChartData?.type === 'conversation' ? 'Início da Conversa' : 'Data do Agendamento'}
                            </div>
                            <div className='col-3' style={{ backgroundColor: '#074a38', border: '1px solid #000', color: '#fff', textAlign: 'center' }}>
                                Unidade
                            </div>
                            <div className='col-3' style={{ backgroundColor: '#074a38', border: '1px solid #000', color: '#fff', textAlign: 'center' }}>
                                Contato
                            </div>
                            <div className='col-3' style={{ backgroundColor: '#074a38', border: '1px solid #000', color: '#fff', textAlign: 'center' }}>
                                Atendente
                            </div>
                            {listChartData?.details?.map((l: any) =>
                                <>
                                    <div className='col-3' style={{ backgroundColor: '#aae3d4', border: '1px solid #000', color: '#222' }}>
                                        {`${moment(l.date).format('dddd, D')} de ${moment(l.date).format('MMMM')} de ${moment(l.date).format('YYYY')} ${moment(l.date).format('H:mm')}`}
                                    </div>
                                    <div className='col-3' style={{ backgroundColor: '#aae3d4', border: '1px solid #000', color: '#222' }}>
                                        {l.branchGymKey}
                                    </div>
                                    <div className='col-3' style={{ backgroundColor: '#aae3d4', border: '1px solid #000', color: '#222' }}>
                                        {l.contactMessagePushName}
                                    </div>
                                    <div className='col-3' style={{ backgroundColor: '#aae3d4', border: '1px solid #000', color: '#222' }}>
                                        {l.userReferenceNickname}
                                    </div>
                                </>)}
                        </div>
                    </div>
                    <Divider align='right'>
                        <div className='inline-flex align-items-center'> <i className='pi pi-cog mr-2'></i> <b>Ações</b> </div>
                    </Divider>
                    <div className='col-3 col-offset-9 grid  p-fluid flex flex-nowrap justify-content-evenly'>
                        <Button label='Fechar' type='button' onClick={() => { setShowDialogListChart(false); }} style={{ minWidth: 150 }}
                            className='p-button-rounded p-button p-button-warning shadow-3' icon='pi pi-times' />
                    </div>
                </div>

            </Dialog>
        </div>
    )
};

