import moment from "moment";
import { useEffect, useState } from "react";
import ls from "../projects/localStorage";
import { waitSleep } from "../utils/functions";
import { successRequest } from "../utils/performRequest";
import TrackerMobileService from "./service/admin/TrackerMobileService";
import TrackerContactMessageService from "./service/admin/TrackerContactMessageService";

const trackerMobileService = new TrackerMobileService();
const trackerContactMessageService = new TrackerContactMessageService();

export const AppNotification = (props: any) => {
    const [networkGymId, setNetworkGymId] = useState(props.networkGymId);
    const [branchGymId, setBranchGymId] = useState(props.branchGymId);
    const [notifications, setNotifications] = useState<{ title: string, message: string, severity: string, blockScreen:boolean } | null>();
    const [inVerifyTrackerMobile, setInVerifyTrackerMobile] = useState(true);
    // const [notificationHeight] = useState(17);

    useEffect(() => { setNetworkGymId(props.networkGymId) }, [props.networkGymId]);
    useEffect(() => { setBranchGymId(props.branchGymId) }, [props.branchGymId]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { verifyTrackerMobile() }, [networkGymId, branchGymId, inVerifyTrackerMobile]);

    const verifyTrackerMobile = async () => {
        let isMounted = true;
        let newNotification: any = null;
        if (networkGymId) {
            trackerMobileService.findTrackerMobileByNetworkGymId(networkGymId).then(async (returnedTrackerMobile: any) => {
                if (successRequest(returnedTrackerMobile.status)) {
                    const trackerMobiles = returnedTrackerMobile.data;
                    for (let i = 0; i < trackerMobiles.length; i++) {
                        const trackerMobile = trackerMobiles[i];
                        // console.log('trackerMobile.lastSuccessConnected: ', trackerMobile.mobilePhone, trackerMobile.lastSuccessConnected, trackerMobile?.connectionMsgError);
                        // console.log('trackerMobile.lastSuccessConnected: ', moment(trackerMobile.lastSuccessConnected), ls.socket.getServerSocketDateTime(), moment(trackerMobile.lastSuccessConnected).add(600, 'seconds').isBefore(ls.socket.getServerSocketDateTime()));
                        // console.log('Math.round(moment(trackerMobile.lastSuccessConnected).diff(ls.socket.getServerSocketDateTime(), \'minutes\')): ', Math.round(moment(trackerMobile.lastSuccessConnected).diff(ls.socket.getServerSocketDateTime(), 'minutes')));
                        // console.log('trackerMobile?.connectionMsgError: ', trackerMobile);

                        const synchronizing = (trackerMobile?.status === 'aceito' && !newNotification && (trackerMobile?.networkGym?.synchronize === 'all' || trackerMobile?.networkGym?.synchronize === 'messages') &&
                            ((trackerMobile?.lastSynchronize && moment(trackerMobile?.lastSynchronize).isAfter(moment().subtract(5, 'minutes')))
                                || (trackerMobile?.lastSynchronizeContacts && moment(trackerMobile?.lastSynchronizeContacts).isAfter(moment().subtract(10, 'minutes')))));

                        const delay = (trackerMobile?.status !== 'cancelado' &&
                            ((!trackerMobile.lastSuccessConnected || trackerMobile?.connectionMsgError)
                                || (trackerMobile.lastSuccessConnected && moment(trackerMobile.lastSuccessConnected).add(60, 'seconds').isBefore(ls.socket.getServerSocketDateTime()))));

                        const bigDelay = (trackerMobile?.status !== 'cancelado' &&
                            ((!trackerMobile.lastSuccessConnected || trackerMobile?.connectionMsgError)
                                || (trackerMobile.lastSuccessConnected && moment(trackerMobile.lastSuccessConnected).add(5, 'minutes').isBefore(ls.socket.getServerSocketDateTime()))));


                        if (trackerMobile?.status === 'cancelado') {
                            newNotification = {
                                title: '',
                                message: `O Whatsapp necessita ser conectado ao sistema na tela de Canais. Reative sua linha.`,
                                severity: 'warning',
                                blockScreen: true,
                            };
                        } else if (trackerMobile?.status === 'conectando') {
                            newNotification = {
                                title: 'Aguarde Conexão',
                                message: `Aguarde enquanto finalizamos a conexão com seu Whatsapp.`,
                                severity: 'warning',
                                blockScreen: true,
                            };
                        } else if (trackerMobile?.status === 'aguardando vínculo' && trackerMobile?.invalidNumberMobilePhone) {
                            newNotification = {
                                title: 'Falha na Conexão',
                                message: `O número de Whatsapp é inválido, favor verificar.`,
                                severity: 'warning',
                                blockScreen: true,
                            };
                        } else if (trackerMobile?.status === 'aguardando vínculo' && trackerMobile?.invalidQrCodeConnected) {
                            newNotification = {
                                title: 'Falha na Conexão',
                                message: `Falha ao tentar conectar seu Whatsapp, favor tentar novamente.`,
                                severity: 'warning',
                                blockScreen: true,
                            };
                        } else if (trackerMobile?.status === 'criado' || trackerMobile?.status === 'aguardando vínculo') {
                            newNotification = {
                                title: 'Pendente Conexão',
                                message: `O Whatsapp necessita ser conectado ao sistema na tela de Canais.`,
                                severity: 'error',
                                blockScreen: true,
                            };
                        } else if (trackerMobile?.status !== 'cancelado' && trackerMobile?.connectionMsgError) {
                            newNotification = {
                                title: 'Falha na Conexão',
                                message: `Falha ao tentar conectar seu Whatsapp, favor tentar novamente.`,
                                severity: 'error',
                                blockScreen: true,
                            };
                        } else if ((!synchronizing && delay) || bigDelay) {
                            newNotification = {
                                title: 'Conexão Lenta',
                                message: `O Whatsapp está apresentando lentidão. ${bigDelay ? '*' : ''}`,
                                severity: trackerMobile?.connectionMsgError ? 'error' : 'warning',
                                blockScreen: false,
                            };
                        } else if (synchronizing) {

                            newNotification = await prepareMessage(trackerMobile, newNotification);

                        }
                        // console.log('status: ', trackerMobile?.status);
                        // console.log('newNotification: ', newNotification);
                        // console.log('lastSynchronize: ', trackerMobile?.lastSynchronize);
                        // console.log('lastSynchronizeContacts: ', trackerMobile?.lastSynchronizeContacts);
                        // console.log('moment: ', moment().subtract(5, 'minutes'));
                        // console.log('trackerMobiles: ', trackerMobile?.status);
                        // console.log('trackerMobile?.status: ', trackerMobile?.status);
                        // console.log('newNotification: ', newNotification);
                    }
                }
                if (isMounted) {
                    // console.log('newNotification: ', newNotification);
                    // console.log('isMounted: ', isMounted);
                    isMounted = false;
                    setNotifications(newNotification);
                    // console.log('hasNotifications: ', !!notifications);
                    props.setHasNotifications(notifications?.blockScreen);
                    await waitSleep(2000);
                    setInVerifyTrackerMobile(!inVerifyTrackerMobile);
                }
            }).catch(async (error: any) => {
                console.error('Error: ', error);
                if (isMounted) {
                    // console.log('isMounted: ', isMounted);
                    isMounted = false;
                    setNotifications(newNotification);
                    props.setHasNotifications(!!notifications)
                    await waitSleep(2000);
                    setInVerifyTrackerMobile(!inVerifyTrackerMobile);
                }
            });

        }
        return () => { isMounted = false };
    }

    return (
        <div style={{
            position: 'fixed',
            right: 100,
            top: 4,
            border: `2px solid var(--${notifications?.severity}MessageTextColor)`,
            borderRadius: '5px',
            backgroundColor: `var(--${notifications?.severity}MessageBg)`,
            color: `var(--${notifications?.severity}MessageTextColor)`, maxWidth: '540px',
            display: notifications ? 'inline-block' : 'none', padding: '10px', zIndex: 1
        }}>
            <div className="flex justify-content-start" style={{ fontSize: '0.8rem' }}>
                <i className="pi pi-volume-down" style={{ fontSize: '1rem' }}></i>
                <p style={{ marginLeft: '15px', marginRight: '2px', fontWeight: 'bold' }}>{notifications?.title ? `${notifications?.title}:` : null}</p>
                <p style={{ marginLeft: '2px' }}>{notifications?.message}</p>
            </div>
        </div>
    );
}

const getPontos = () => {
    const segundos = new Date().getSeconds(); // Obtém os segundos atuais
    const pontos = (segundos % 6) / 2 + 1; // Alterna entre 1, 2 e 3 pontos
    return '.'.repeat(pontos); // Retorna os pontos na quantidade certa
}

const prepareMessage = async (trackerMobile: any, newNotification: any) => {
    const returnedTrackerContacts = await trackerContactMessageService.findStatusTrackerContactMessageByTrackerMobileId(trackerMobile._id);
    if (successRequest(returnedTrackerContacts.status)) {
        const listContacts = returnedTrackerContacts.data;

        // console.log('listContacts: ', listContacts);
        let pendents = 0, running = 0, finished = 0;
        for (const contact of listContacts) {
            pendents += contact.synchronizeStatus === 'pendent' ? 1 : 0;
            running += contact.synchronizeStatus === 'running' ? 1 : 0;
            finished += contact.synchronizeStatus === 'finished' ? 1 : 0;
        }
        let total = pendents + running + finished;
        let percent = Math.round(finished / total * 10000) / 100;
        if (percent > 99.5) return null;
        let status = `${trackerMobile?.statusImportPrimaryContacts === 'found' ? 'P0' : trackerMobile?.statusImportPrimaryContacts === 'processed' ? 'P1' : trackerMobile?.statusImportPrimaryContacts === 'finished' ? 'P2' : ''}${trackerMobile?.statusImportSecondaryContacts === 'found' ? 'S0' : trackerMobile?.statusImportSecondaryContacts === 'processed' ? 'S1' : trackerMobile?.statusImportSecondaryContacts === 'finished' ? 'S2' : ''}`
        let message = `${status}: Bem vindo ao Chat4Fit! O sistema pode já ser utilizado, este alerta será desativado assim que a sincronização com o Whatsapp for finalizada. (${percent > 0 ? `${percent}%` : `preparando${getPontos()}`})${getPontos()}`;
        newNotification = {
            title: '',
            message: message,
            severity: 'info',
            blockScreen: false,
        };
    } else {
        // console.log('trackerMobile?.networkGym?.synchronize: ', trackerMobile);
        newNotification = {
            title: '',
            message: `Bem vindo ao Chat4Fit! O sistema pode já ser utilizado, este alerta será desativado assim que a sincronização com o Whatsapp for finalizada.`,
            severity: 'info',
            blockScreen: false,
        };
    }
    return newNotification;
}

export default AppNotification;

