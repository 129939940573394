import moment from "moment";
import { useEffect, useState } from "react";
import ls from "../projects/localStorage";
import { waitSleep } from "../utils/functions";
import { successRequest } from "../utils/performRequest";
import TrackerMobileService from "./service/admin/TrackerMobileService";

const trackerMobileService = new TrackerMobileService();

export const AppNotification = (props: any) => {
    const [networkGymId, setNetworkGymId] = useState(props.networkGymId);
    const [branchGymId, setBranchGymId] = useState(props.branchGymId);
    const [notifications, setNotifications] = useState<{ title: string, message: string, severity: string } | null>();
    const [inVerifyTrackerMobile, setInVerifyTrackerMobile] = useState(true);
    // const [notificationHeight] = useState(17);

    useEffect(() => { setNetworkGymId(props.networkGymId) }, [props.networkGymId]);
    useEffect(() => { setBranchGymId(props.branchGymId) }, [props.branchGymId]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { verifyTrackerMobile() }, [networkGymId, branchGymId, inVerifyTrackerMobile]);

    const verifyTrackerMobile = async () => {
        let isMounted = true;
        let newNotification: any = null;
        if (networkGymId) {
            trackerMobileService.findTrackerMobileByNetworkGymId(networkGymId).then(async (returnedTrackerMobile: any) => {
                if (successRequest(returnedTrackerMobile.status)) {
                    const trackerMobiles = returnedTrackerMobile.data;
                    for (let i = 0; i < trackerMobiles.length; i++) {
                        const trackerMobile = trackerMobiles[i];
                        // console.log('trackerMobile.lastSuccessConnected: ', moment(trackerMobile.lastSuccessConnected), ls.socket.getServerSocketDateTime(), moment(trackerMobile.lastSuccessConnected).add(600, 'seconds').isBefore(ls.socket.getServerSocketDateTime()));
                        // console.log('Math.round(moment(trackerMobile.lastSuccessConnected).diff(ls.socket.getServerSocketDateTime(), \'minutes\')): ', Math.round(moment(trackerMobile.lastSuccessConnected).diff(ls.socket.getServerSocketDateTime(), 'minutes')));
                        // console.log('trackerMobile?.connectionMsgError: ', trackerMobile);
                        if (trackerMobile?.status === 'criado' || trackerMobile?.status === 'aguardando vínculo') {
                            newNotification = {
                                title: 'Pendente Conexão',
                                message: `O Whatsapp necessita ser conectado ao sistema na tela de Canais.`,
                                severity: 'error'
                            };
                        } else if (trackerMobile?.status !== 'cancelado' &&
                            ((!trackerMobile.lastSuccessConnected || trackerMobile?.connectionMsgError)
                                || (trackerMobile.lastSuccessConnected && moment(trackerMobile.lastSuccessConnected).add(600, 'seconds').isBefore(ls.socket.getServerSocketDateTime())))) {
                            newNotification = {
                                title: 'Falha Conexão',
                                message: `O Whatsapp necessita ser conectado ao sistema na tela de Canais.`,
                                // message: `O Whatsapp está sem comunicação${trackerMobile?.connectionMinError > 0 ?
                                //     ` a ${Math.round(trackerMobile?.connectionMinError)} minuto(s).`
                                //     :
                                //     `${trackerMobile?.lastSuccessConnected && ls.socket.getServerSocketDateTime().diff(moment(trackerMobile?.lastSuccessConnected), 'minutes') > 0 ?
                                //         ` a ${Math.round(ls.socket.getServerSocketDateTime().diff(moment(trackerMobile?.lastSuccessConnected), 'minutes'))} minuto(s).`
                                //         :
                                //         ''}`}.`,
                                severity: 'error'
                            };
                        }
                    }
                    // console.log('trackerMobiles: ', trackerMobiles);
                }
                if (isMounted) {
                    // console.log('isMounted: ', isMounted);
                    isMounted = false;
                    setNotifications(newNotification);
                    // console.log('hasNotifications: ', !!notifications);
                    props.setHasNotifications(!!notifications)
                    await waitSleep(2000);
                    setInVerifyTrackerMobile(!inVerifyTrackerMobile);
                }
            }).catch(async (error: any) => {
                console.error('Error: ', error);
                if (isMounted) {
                    // console.log('isMounted: ', isMounted);
                    isMounted = false;
                    setNotifications(newNotification);
                    props.setHasNotifications(!!notifications)
                    await waitSleep(2000);
                    setInVerifyTrackerMobile(!inVerifyTrackerMobile);
                }
            });

        }
        return () => { isMounted = false };
    }

    return (
        <div style={{
            position: 'fixed',
            right: 100,
            top: 4,
            border: `2px solid var(--${notifications?.severity}MessageTextColor)`,
            borderRadius: '5px',
            backgroundColor: `var(--${notifications?.severity}MessageBg)`,
            color: `var(--${notifications?.severity}MessageTextColor)`, maxWidth: '540px',
            display: notifications ? 'inline-block' : 'none', padding: '10px', zIndex: 1
        }}>
            <div className="flex justify-content-start" style={{ fontSize: '0.8rem' }}>
                <i className="pi pi-volume-down" style={{ fontSize: '1rem' }}></i>
                <p style={{ marginLeft: '15px', marginRight: '2px', fontWeight: 'bold' }}>{notifications?.title}:</p>
                <p style={{ marginLeft: '2px' }}>{notifications?.message}</p>
            </div>
        </div>
    );
}

export default AppNotification;
