/* eslint-disable react-hooks/exhaustive-deps */
import { DefaultEventsMap } from '@socket.io/component-emitter';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import PrimeReact, { addLocale } from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import { classNames } from 'primereact/utils';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Socket, io } from 'socket.io-client';
import enviroment from '../.env';
import projectEnviroment, { Applications } from '../project.enviroments';
import { RoleRules, checVigilanceByToken, checkRoleHierarchyByToken } from '../projects/checkRoleHierarchy';
import ls from '../projects/localStorage';
import { waitSleep } from '../utils/functions';
import { listDayOfWeeks, listMinDayOfWeeks, listMonthOfYear, listShortDayOfWeeks, listShortMonthOfYear } from '../utils/functions/date';
import HttpStatus, { isSuccess } from '../utils/httpStatus';
import './App.scss';
import AppBreadcrumb from './AppBreadcrumb';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import AppNotification from './AppNotification';
import { retrieveMenus, retrieveRoutes } from './AppRoutes';
import { AppTagSelecteds } from './AppTagSelecteds';
import AppTopbar from './AppTopbar';
import { Control, IMessage } from './components/Control';
import { IConsidered } from './components/generics/considereds';
import { IFormDataBranchGym, IFormDataGuide, IFormDataNetworkGym, IFormDataTrackerMobile } from './components/generics/entities';
import AuthenticateService from './service/AuthenticateService';
import BranchGymService from "./service/admin/BranchGymService";
import BranchGymSportService from './service/admin/BranchGymSportService';
import GuideService from './service/admin/GuideService';
import NetworkGymService from "./service/admin/NetworkGymService";
import TrackerMobileService from './service/admin/TrackerMobileService';

const networkGymService = new NetworkGymService();
const branchGymService = new BranchGymService();
const guideService = new GuideService();
const trackerMobileService = new TrackerMobileService();
const branchGymSportService = new BranchGymSportService();

const ignoreAppTagSelected: string[] = ['/admin/SystemMessageDefaults']; // '/admin/users'

const App = (props: any) => {
    const [token, setToken] = useState(ls.getLocalStorageToken());
    const [networkGymId, setNetworkGymId] = useState(localStorage.getItem('networkGym'));
    const [networkGymSelected, setNetworkGymSelected] = useState<IFormDataNetworkGym | null>();
    const [branchGymId, setBranchGymId] = useState(localStorage.getItem('branchGym'));
    const [branchGymSelected, setBranchGymSelected] = useState<IFormDataBranchGym | null>();
    const [guideId, setGuideId] = useState(localStorage.getItem('guide'));
    const [guideSelected, setGuideSelected] = useState<IFormDataGuide | null>();
    const [trackerMobileId, setTrackerMobileId] = useState(localStorage.getItem('trackerMobile'));
    const [trackerMobileSelected, setTrackerMobileSelected] = useState<IFormDataTrackerMobile | null>();
    const [branchGymSportId, setBranchGymSportId] = useState(localStorage.getItem('branchGymSport'));
    const [branchGymSportSelected, setBranchGymSportSelected] = useState<IFormDataGuide | null>();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [socket, setSocket] = useState<Socket<DefaultEventsMap, DefaultEventsMap>>();
    const [socketServerPath] = useState<string>(projectEnviroment.buildPath('/', enviroment(), Applications.SERVER_SOCKET));
    const [dataTimeUpdated] = useState<boolean>(false);

    const [hasNotifications, setHasNotifications] = useState<boolean>();

    const location = useLocation();

    const [considered, setConsidered] = useState<IConsidered[]>([
        { key: 'networkGym', show: false, considered: false },
        { key: 'branchGym', show: false, considered: false },
        { key: 'guide', show: false, considered: false },
        { key: 'trackerMobile', show: false, considered: false },
        { key: 'branchGymSport', show: false, considered: false }
    ]);


    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [message, setMessage] = useState<IMessage | undefined>(undefined);

    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [layoutMode] = useState('static');
    const [layoutWrapperMobile] = useState(true);
    const [mobileMenuActive, setMobileMenuActive] = useState(true);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [menuActive, setMenuActive] = useState(true);
    // const [themeColor, setThemeColor] = useState('deeppurple');
    // const [layoutColor, setLayoutColor] = useState('deeppurple');
    const [darkMenu] = useState(false);
    const [inputStyle] = useState('filled');
    const [ripple] = useState(true);
    const [hideTagSelected, setHideTagSelected] = useState<boolean>(false);


    const sidebar = useRef(null);

    // useEffect(() => {
    // 	console.trace('loading: ', loading, loadingData);
    // }, [loading, loadingData]);

    useEffect(() => {
        // console.log('Notification in window: ', "Notification" in window);
        // console.log('Notification.permission === granted: ', Notification.permission === "granted");
        if ("Notification" in window) {
            if (Notification.permission !== "granted") {
                Notification.requestPermission();
            }
        }
    }, []);


    useEffect(() => {
        setHideTagSelected(ignoreAppTagSelected.indexOf(location.pathname) >= 0);
        ls.branchGym.setAllBranchGyms();
    }, [location.pathname]);


    PrimeReact.ripple = true;

    let topbarMenuClick: boolean = false;
    let topbarMenuButtonClick: boolean = false;
    // let sidebarTimeout: any = null;

    const authenticateService = new AuthenticateService();


    addLocale('pt-BR', {
        firstDayOfWeek: 1,
        dayNames: listDayOfWeeks,
        dayNamesShort: listShortDayOfWeeks,
        dayNamesMin: listMinDayOfWeeks,
        monthNames: listMonthOfYear,
        monthNamesShort: listShortMonthOfYear,
        today: 'Hoje',
        clear: 'Limpar'
    });

    useEffect(() => setNetworkGymId(networkGymId), [networkGymId]);
    useEffect(() => setBranchGymId(branchGymId), [branchGymId]);

    useEffect(() => { socketConnection() }, [dataTimeUpdated]);


    const socketConnection = async () => {
        const newSocket = io(socketServerPath, {
            query: {
                origin: 'WebApplication',
                sessionName: 'Server Date Time',
            }
        });
        newSocket.on('connect', () => {
            // console.log(newSocket.id);
            // console.log('socketServerPath: ', socketServerPath);
            newSocket.emit('get.server.socket.date.time');
        });
        newSocket.on('server.socket.date.time', async (datetime: string) => {
            // console.log('Datetime: ', datetime);
            await waitSleep(5000);
            // console.log('socketServerPath: ', socketServerPath);
            // setDataTimeUpdated(false);
            // console.log('SetDataTimeUpdated: ', datetime);
            ls.socket.setServerSocketDateTime(datetime);
            newSocket.emit('get.server.socket.date.time');

            // newSocket.disconnect();
        });
        setSocket(newSocket);
    }

    useEffect(() => {
        if (networkGymId && (!networkGymSelected || networkGymId !== networkGymSelected._id)) {
            networkGymService.findNetworkGymById(networkGymId).then((returned: any) => {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { window.location.href = '/'; }, 5000);
                } else if (isSuccess(returned.status)) {
                    // console.log('returned.data: ', returned.data);
                    setNetworkGymSelected(Object.assign(returned.data, { showButtons: false }));
                } else {
                    setNetworkGymSelected(null);
                }

            }).catch(error => {
                console.log('error', error);
                setNetworkGymSelected(null);

            });
        } else {
            setNetworkGymSelected(null);
        }
    }, [networkGymId]);

    useEffect(() => {
        if (!networkGymId || !branchGymId) return setBranchGymSelected(null);
        if (branchGymId && (!branchGymSelected || branchGymId !== branchGymSelected._id)) {
            branchGymService.findBranchGymById(branchGymId).then((returned: any) => {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { window.location.href = '/'; }, 5000);
                } else if (isSuccess(returned.status)) {
                    setBranchGymSelected(Object.assign(returned.data, { showButtons: false }));
                } else {
                    setBranchGymSelected(null);
                }

            }).catch(error => {
                console.log('error', error);
                setBranchGymSelected(null);

            });
        } else {
            setBranchGymSelected(null);
        }
    }, [networkGymId, branchGymId]);

    useEffect(() => {
        if (guideId && (!guideSelected || guideId !== guideSelected._id)) {
            guideService.findGuideById(guideId).then((returned: any) => {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { window.location.href = '/'; }, 5000);
                } else if (isSuccess(returned.status)) {
                    setGuideSelected(Object.assign(returned.data, { showButtons: false }));
                } else {
                    setGuideSelected(null);
                }

            }).catch(error => {
                console.log('error', error);
                setGuideSelected(null);

            });
        } else {
            setGuideSelected(null);
        }
    }, [guideId]);

    useEffect(() => {
        if (!networkGymId || !branchGymId || !branchGymSportId) return setBranchGymSportSelected(null);
        if (branchGymSportId && (!branchGymSportSelected || branchGymSportId !== branchGymSportSelected._id)) {
            branchGymSportService.findBranchGymSportById(branchGymSportId).then((returned: any) => {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { window.location.href = '/'; }, 5000);
                } else if (isSuccess(returned.status)) {
                    setBranchGymSportSelected(Object.assign(returned.data, { showButtons: false }));
                } else {
                    setBranchGymSportSelected(null);
                }

            }).catch(error => {
                console.log('error', error);
                setBranchGymSportSelected(null);

            });
        } else {
            setBranchGymSportSelected(null);
        }
    }, [networkGymId, branchGymId, branchGymSportId]);

    useEffect(() => {
        if (!networkGymId || !branchGymId || !trackerMobileId) return setTrackerMobileSelected(null);
        if (trackerMobileId && (!trackerMobileSelected || trackerMobileId !== trackerMobileSelected._id)) {
            trackerMobileService.findTrackerMobileById(trackerMobileId).then((returned: any) => {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { window.location.href = '/'; }, 5000);
                } else if (isSuccess(returned.status)) {
                    setTrackerMobileSelected(Object.assign(returned.data, { showButtons: false }));
                } else {
                    setTrackerMobileSelected(null);
                }

            }).catch(error => {
                console.log('error', error);
                setTrackerMobileSelected(null);

            });
        } else {
            setTrackerMobileSelected(null);
        }
    }, [networkGymId, branchGymId, trackerMobileId]);

    useEffect(() => {
        const tokenLocalStorage = ls.getLocalStorageToken();
        // console.log('tokenLocalStorage:  ',  tokenLocalStorage);
        if (!tokenLocalStorage) setToken(null);
        else {
            authenticateService.refreshToken(tokenLocalStorage).then((refreshToken) => {
                setToken(refreshToken);
            }).catch((error) => {
                console.log('error: ', error);
                setToken(null);
            });
        }
    }, [loading, loadingData]);



    // const onInputStyleChange = (inputStyle: string) => {
    //     setInputStyle(inputStyle);
    // }

    // const onRipple = (e: any) => {
    //     PrimeReact.ripple = e.value;
    //     setRipple(e.value);
    // }

    // const onLayoutModeChange = (mode: string) => {
    //     setLayoutMode(mode);
    // }

    // const onDarkMenuChange = (mode: string) => {
    //     if (mode === 'dark') {
    //         setDarkMenu(true)
    //         setColorMode('dark');
    //     }
    //     else {
    //         setDarkMenu(false);
    //         setColorMode('light');
    //     }
    // }

    const onWrapperClick = () => {
        // if (!menuClick && !menuButtonClick && mobileMenuActive) {
        //     setMobileMenuActive(false)
        // }

        if (!topbarMenuClick && !topbarMenuButtonClick) {
            setActiveTopbarItem(null);
            setTopbarMenuActive(false)
        }

        // if (!menuClick) {
        //     if (isHorizontal() || isOverlay()) {
        //         console.log('onWrapperClick');
        //         setMenuActive(false);
        //     }
        // }

        topbarMenuClick = false;
        topbarMenuButtonClick = false;
    }

    const onTopbarItemClick = (event: any) => {
        topbarMenuClick = true;
        if (activeTopbarItem === event.item)
            setActiveTopbarItem(null)
        else
            setActiveTopbarItem(event.item)
        event.originalEvent.preventDefault();
    }

    const onMenuButtonClick = (event: Event) => {
        if (isMobile()) {
            setMobileMenuActive(prevState => !prevState);
        }
        event.preventDefault();
    }

    const onTopbarMobileMenuButtonClick = (event: Event) => {
        topbarMenuButtonClick = true;
        setTopbarMenuActive(prevState => !prevState)
        event.preventDefault();
    }

    const onSidebarClick = () => {
        // menuClick = true;
    }

    // const onSidebarMouseEnter = () => {
    //     if (layoutMode !== 'horizontal') {
    //         setMenuActive(true);
    //     }
    //     if (sidebarTimeout) {
    //         clearTimeout(sidebarTimeout);
    //     }
    //     addClass(sidebar.current, 'layout-sidebar-active');
    // }

    // const onSidebarMouseLeave = () => {
    //     if (layoutMode !== 'horziontal') {
    //         console.log('onSidebarMouseLeave');
    //         setMenuActive(false);
    //     }
    //     // sidebarTimeout = setTimeout(() => {
    //     //     removeClass(sidebar.current, 'layout-sidebar-active');
    //     // }, 250);
    // }

    // const addClass = (element: any, className: string) => {
    //     if (element) {
    //         if (element.classList)
    //             element.classList.add(className);
    //         else
    //             element.className += ' ' + className;
    //     }
    // }

    // const removeClass = (element: any, className: string) => {
    //     if (element) {
    //         if (element.classList)
    //             element.classList.remove(className);
    //         else
    //             element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    //     }
    // }

    const onRootMenuItemClick = () => {
        setMenuActive(prevState => !prevState)
    }

    const onMenuItemClick = (event: any) => {
        if (!event.item.items && isHorizontal()) {
            // console.log('onMenuItemClick.1');
            // setMenuActive(false)
        }

        if (!event.item.items && isMobile()) {
            // setMobileMenuActive(false);
            // console.log('onMenuItemClick.2');
            // setMenuActive(false);
            // onSidebarMouseLeave();
        }
    }

    // const changeTheme = (color: string) => {
    //     setThemeColor(color);
    //     changeStyleSheetUrl('theme-css', color, 'theme');
    // }

    // const changeLayout = (color: string) => {
    //     setLayoutColor(color)
    //     changeStyleSheetUrl('layout-css', color, 'layout');
    // }

    // const changeStyleSheetUrl = (id: string, value: string, prefix: string) => {
    //     let element = document.getElementById(id);
    //     let urlTokens = element?.getAttribute('href')?.split('/');
    //     if (urlTokens) {
    //         urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
    //         let newURL = urlTokens.join('/');

    //         replaceLink(element, newURL);
    //     }
    // }

    // const isIE = () => {
    //     return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    // }

    // const replaceLink = (linkElement: any, href: string) => {
    //     if (isIE()) {
    //         linkElement.setAttribute('href', href);
    //     }
    //     else {
    //         const id = linkElement.getAttribute('id');
    //         const cloneLinkElement = linkElement.cloneNode(true);

    //         cloneLinkElement.setAttribute('href', href);
    //         cloneLinkElement.setAttribute('id', id + '-clone');

    //         linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

    //         cloneLinkElement.addEventListener('load', () => {
    //             linkElement.remove();
    //             cloneLinkElement.setAttribute('id', id);
    //         });
    //     }
    // }

    const isMobile = () => {
        return true; // window.innerWidth <= 1024;
    }

    // const isOverlay = () => {
    //     return layoutMode === 'overlay';
    // }

    const isHorizontal = () => {
        return layoutMode === 'horizontal';
    }

    let wrapperClass = classNames({
        'layout-wrapper': true,
        'layout-wrapper-mobile': layoutWrapperMobile,
        'layout-wrapper-static': layoutMode === 'static',
        'layout-wrapper-active': mobileMenuActive,
        'layout-menu-horizontal': layoutMode === 'horizontal',
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple,
    });

    let sidebarClassName = classNames("layout-sidebar", { 'layout-sidebar-dark': darkMenu });


    const select = (type: string, _id?: string | null, ...args: string[]) => {
        if (_id) {
            localStorage.setItem(type, _id);
            switch (type) {
                case 'networkGym':
                    setNetworkGymId(_id);
                    ls.branchGym.setAllBranchGyms();
                    break;
                case 'branchGym': setBranchGymId(_id); break;
                case 'guide': setGuideId(_id); break;
                case 'trackerMobile': setTrackerMobileId(_id); break;
                case 'branchGymSport': setBranchGymSportId(_id); break;
            }
        }
    }
    const unselect = (type: string, _id?: string | null, ...args: string[]) => {
        switch (type) {
            case 'networkGym':
                localStorage.removeItem('networkGym');
                localStorage.removeItem('branchGym');
                localStorage.removeItem('trackerMobile');
                localStorage.removeItem('branchGymSport');
                ls.getLocalStorageToken();
                ls.networkGym.getNetworkGymsByStorageToken();
                ls.branchGym.setAllBranchGyms();
                setNetworkGymId(localStorage.getItem('networkGym'));
                setBranchGymId(localStorage.getItem('branchGym'));
                setTrackerMobileId(localStorage.getItem('trackerMobile'));
                setBranchGymSportId(localStorage.getItem('branchGymSport'));
                break;
            case 'branchGym':
                localStorage.removeItem('branchGym');
                localStorage.removeItem('trackerMobile');
                localStorage.removeItem('branchGymSport');
                ls.getLocalStorageToken();
                ls.networkGym.getNetworkGymsByStorageToken();
                ls.branchGym.setAllBranchGyms();
                setBranchGymId(localStorage.getItem('branchGym'));
                setTrackerMobileId(localStorage.getItem('trackerMobile'));
                setBranchGymSportId(localStorage.getItem('branchGymSport'));
                break;
            case 'guide':
                localStorage.removeItem('guide');
                ls.getLocalStorageToken();
                ls.networkGym.getNetworkGymsByStorageToken();
                setGuideId(localStorage.getItem('guide'));
                break;
            case 'trackerMobile':
                localStorage.removeItem('trackerMobile');
                ls.getLocalStorageToken();
                ls.networkGym.getNetworkGymsByStorageToken();
                setTrackerMobileId(localStorage.getItem('trackerMobile'));
                break;
            case 'branchGymSport':
                localStorage.removeItem('branchGymSport');
                ls.getLocalStorageToken();
                ls.networkGym.getNetworkGymsByStorageToken();
                setBranchGymSportId(localStorage.getItem('branchGymSport'));
                break;
        }
    }
    const setUpdateConsidered = (values: IConsidered[]) => {
        const array2Sorted = values.slice().sort();
        const equals = considered.length === values.length
            && considered.slice().sort().every((value, index) => value.key === array2Sorted[index].key
                && value.show === array2Sorted[index].show && value.considered === array2Sorted[index].considered);
        if (!equals) setConsidered(values);
    }

    const hideAppTagSelected = considered.filter(async c => c.show === true &&
        (c.key !== 'networkGym' || ((await ls.networkGym.getNetworkGymsByStorageToken())?.length || 0) > 1)).length === 0 ? 'hideAppTagSelected' : '';


    const selectShowButtons = (type: string, _id?: string | null, ...args: string[]) => {
        // console.log('selectShowButtons: ', type, _id);
        if (_id) {
            switch (type) {
                case 'networkGym':
                    if (networkGymSelected) {
                        networkGymSelected.showButtons = !networkGymSelected?.showButtons;
                        setNetworkGymSelected(Object.assign({}, networkGymSelected));
                    }
                    break;
                case 'branchGym':
                    if (branchGymSelected) {
                        branchGymSelected.showButtons = !branchGymSelected?.showButtons;
                        setBranchGymSelected(Object.assign({}, branchGymSelected));
                    }
                    break;
                case 'guide':
                    if (guideSelected) {
                        guideSelected.showButtons = !guideSelected?.showButtons;
                        setGuideSelected(Object.assign({}, guideSelected));
                    }
                    break;
                case 'trackerMobile':
                    if (trackerMobileSelected) {
                        trackerMobileSelected.showButtons = !trackerMobileSelected?.showButtons;
                        setTrackerMobileSelected(Object.assign({}, trackerMobileSelected));
                    }
                    break;
                case 'branchGymSport':
                    if (branchGymSportSelected) {
                        branchGymSportSelected.showButtons = !branchGymSportSelected?.showButtons;
                        setBranchGymSportSelected(Object.assign({}, branchGymSportSelected));
                    }
                    break;
            }
        }
    }

    const redirectPage = () => {
        if (token === null) {
            return <Redirect to="/login" />
        } else if (location.pathname === '/') {
            if (checVigilanceByToken()) {
                if (checkRoleHierarchyByToken(RoleRules.owner)) {
                    return <Redirect to="/attendance" />
                } else if (checkRoleHierarchyByToken(RoleRules.manager)) {
                    return <Redirect to="/attendance" />
                } else if (checkRoleHierarchyByToken(RoleRules.attendant)) {
                    return <Redirect to="/attendance" />
                }
            } else {
                return <Redirect to="/monitoringAttendance" />
            }
            return <></>;
        } else {
            return (<div className={wrapperClass} onClick={onWrapperClick}>
                <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}
                // onMouseEnter={onSidebarMouseEnter} onMouseLeave={onSidebarMouseLeave}
                >
                    <div className={classNames("sidebar-logo", { "layout-homologation": enviroment() === 'HOMOLOGATION' })}>
                        <button className="p-link">
                            <img alt="logo" src="chat4fit/assets/layout/images/logo-horizontal-white.png" />
                        </button>
                    </div>

                    <div className="layout-menu-container">
                        <AppMenu model={retrieveMenus(networkGymSelected)} onRootMenuItemClick={onRootMenuItemClick} layoutMode={layoutMode}
                            active={menuActive} onMenuItemClick={onMenuItemClick} />
                    </div>
                </div>
                <div className="layout-main">
                    <AppTopbar layoutMode={layoutMode}
                        mobileMenuActive={mobileMenuActive}
                        activeTopbarItem={activeTopbarItem} onTopbarItemClick={onTopbarItemClick}
                        onMenuButtonClick={onMenuButtonClick} onTopbarMobileMenuButtonClick={onTopbarMobileMenuButtonClick}
                        topbarMenuActive={topbarMenuActive} isMobile={isMobile}
                        setToken={(token: string) => setToken(token)}
                        setLoading={setLoading} setMessage={setMessage} >
                        <AppNotification
                            networkGymId={networkGymId}
                            branchGymId={branchGymId}
                            setHasNotifications={setHasNotifications}
                        />
                    </AppTopbar>

                    <AppBreadcrumb setToken={(token: string) => setToken(token)} />


                    {!hideTagSelected ?
                        <AppTagSelecteds token={token} considered={considered}
                            networkGymId={networkGymId}
                            networkGymSelected={networkGymSelected}
                            networkGymShowButtons={networkGymSelected?.showButtons}
                            branchGymId={branchGymId}
                            branchGymSelected={branchGymSelected}
                            branchGymShowButtons={branchGymSelected?.showButtons}
                            guideId={guideId}
                            guideSelected={guideSelected}
                            guideShowButtons={guideSelected?.showButtons}
                            trackerMobileId={trackerMobileId}
                            trackerMobileSelected={trackerMobileSelected}
                            trackerMobileShowButtons={trackerMobileSelected?.showButtons}
                            branchGymSportId={branchGymSportId}
                            branchGymSportSelected={branchGymSportSelected}
                            branchGymSportShowButtons={branchGymSportSelected?.showButtons}
                            topStyle={62}//{hasNotifications ? 63 + notificationHeight : 62}
                            select={(type: string, _id?: string | null | undefined, ...args: string[]) => select(type, _id, ...args)}
                            unselect={(type: string, _id?: string | null | undefined, ...args: string[]) => unselect(type, _id, ...args)}
                            setMessage={setMessage}
                            loadingData={loadingData} setLoadingData={setLoadingData}
                            setLoading={setLoading} selectShowButtons={selectShowButtons}
                        />
                        :
                        null}

                    <div style={{ top: hideTagSelected ? 0 : 70 }} className={classNames("layout-content", hideAppTagSelected)}>
                        {retrieveRoutes({
                            token,
                            mobileMenuActive,
                            select: (type: string, _id?: string | null | undefined, ...args: string[]) => select(type, _id, ...args),
                            unselect: (type: string, _id?: string | null | undefined, ...args: string[]) => unselect(type, _id, ...args),
                            loading, setLoading: setLoadingLogger,
                            setMessage,
                            networkGymId, branchGymId, guideId, trackerMobileId, branchGymSportId,
                            networkGymSelected, branchGymSelected, guideSelected, trackerMobileSelected, branchGymSportSelected,
                            hasNotifications: hasNotifications ? hasNotifications : false,
                            setUpdateConsidered: (values) => setUpdateConsidered(values)
                        })}
                    </div>

                    {/* <AppConfig inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    rippleEffect={ripple} onRippleEffect={onRipple}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange}
                    colorMode={colorMode} onDarkMenuChange={onDarkMenuChange}
                    layoutColor={layoutColor} changeLayout={changeLayout}
                    themeColor={themeColor} changeTheme={changeTheme} /> */}

                    <AppFooter />

                    {/* {mobileMenuActive && <div className="layout-main-mask"></div>} */}
                </div>
            </div>);
        }
    }

    const setLoadingLogger = (loadding: SetStateAction<boolean>) => {
        // console.trace('loading: ', loading, loadingData);
        setLoading(loadding);

    }

    return (
        <>
            <Control loading={{ blocked: loading, fullScreen: true }} message={message} />
            {redirectPage()}
        </>

    );

}

export default App;
