import moment, { Moment } from "moment";
import fixedSchedules from './fixed.json';

const Leite = require('leite');
const leite = new Leite();

const isFixedSchedule = true;
const qttyAttendants = 3;
const pastSchedule = 5;
const actualSchedule = 120 * 3;
const futureSchedule = 30;

const attendants: string[] = [];

for (let i = 0; i < qttyAttendants; i++) {
    attendants.push(leite.pessoa.primeiroNome());
}

const actualDate = moment().utc();

export const randomDate = (start: Moment, end: Moment) => {
    let newDate = start.add(Math.round(Math.random() * end.diff(start, 'days')), 'days');
    newDate = randomHour(newDate, 9 + 3, 21 + 3);
    newDate = randomMinute(newDate);
    return aroundMinute(newDate);
}

export const randomHour = (actualDate: Moment, initialHour: number, finalHour: number) => {
    return actualDate.set('hour', Math.round(Math.random() * (finalHour - initialHour) + initialHour));
}

export const randomMinute = (actualDate: Moment) => {
    return actualDate.set('minute', Math.round(Math.random() * 60));
}

export const aroundMinute = (actualDate: Moment) => {
    actualDate.set('second', 0).set('millisecond', 0);
    const minuteReminder = 30 * Math.floor((actualDate.minute() / 30));
    // console.log('minutes: ', actualDate.minute(), Math.floor((actualDate.minute() / 30)), minuteReminder);
    actualDate.set('minute', minuteReminder);
    return actualDate;
}

export const aroundDate = (actualDate: Moment) => {
    actualDate.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    return actualDate;
}

export enum StatusSchedule {
    VISIT = 'visit',
    CLASS = 'class',
    CONTACT = 'contact',
    FOLLOWUP = 'followup',
    SCHEDULED = 'scheduled',
    VISITED = 'visited',
    RESCHEDULED = 'rescheduled',
    HIRED = 'hired',
    MISSED = 'missed',
};

export class TypeSchedule {
    static readonly AUSENTES = { key: 'ausentes', description: 'AUSENTES' };
    static readonly INATIVOS = { key: 'inativos', description: 'INATIVOS' };
    static readonly INDICADOS = { key: 'indicados', description: 'INDICADOS' };
    static readonly LEADS = { key: 'leads', description: 'LEADS' };
    static readonly PÓS_VISITA = { key: 'pós visita', description: 'PÓS VISITA' };
    static readonly PÓS_VENDA = { key: 'pós venda', description: 'PÓS VENDA' };
    static readonly RENOVAÇÃO = { key: 'renovação', description: 'RENOVAÇÃO' };
    static readonly SAZONAIS = { key: 'sazonais', description: 'SAZONAIS' };

    private constructor(private readonly key?: string, public readonly value?: any) {
    }

    // toString() {
    //     return this.key;
    // }

    static byKey(key: string): TypeSchedule | undefined {
        const typeSchedule = Object.entries(TypeSchedule).find(x=>x[1].key === key);
        if (!typeSchedule) return undefined;
        return typeSchedule[1];
    }

};

export interface ScheduleInterface {
    date: string;
    posSchedule: number,
    statusSchedule: StatusSchedule;
    typeSchedule: TypeSchedule;
    name: string;
    attendant: string;
    interested: string;
}

let dataSchedules: ScheduleInterface[] = [];

// console.log('pastSchedule: ', randomDate(aroundDate(moment(actualDate).weekday(-7)).add(1, 'day'), aroundDate(moment(actualDate).weekday(-1)).subtract('day', 1)).toISOString());
for (let i = 0; i < pastSchedule; i++) {
    const statusSchedule = Math.round(Math.random() * (Object.values(StatusSchedule).length - 1));
    const typeSchedule = Math.round(Math.random() * (Object.values(TypeSchedule).length - 1));
    dataSchedules.push({
        posSchedule: i,
        date: randomDate(aroundDate(moment(actualDate).weekday(-7)).add(1, 'day'), aroundDate(moment(actualDate).weekday(-1)).subtract('day', 1)).toISOString(),
        statusSchedule: Object.values(StatusSchedule)[statusSchedule],
        typeSchedule: Object.values(TypeSchedule)[typeSchedule],
        name: leite.pessoa.primeiroNome(),
        attendant: attendants[Math.round(Math.random() * (attendants.length - 1))],
        interested: 'Pilates'
    });
}
// console.log('actualSchedule: ', aroundDate(moment(actualDate).weekday(-1)).add(2, 'day').toISOString());
// console.log('actualSchedule: ', aroundDate(moment(actualDate).add(1, 'week').weekday(0)).toISOString());
// console.log('actualSchedule: ', randomDate(aroundDate(moment(actualDate).weekday(-1)).add(2, 'day'), aroundDate(moment(actualDate).add(1, 'week').weekday(0))).toISOString());
for (let i = 0; i < actualSchedule; i++) {
    const posSchedule = Math.round(Math.random() * (Object.values(StatusSchedule).length - 1));
    const typeSchedule = Math.round(Math.random() * (Object.values(TypeSchedule).length - 1));
    dataSchedules.push({
        posSchedule,
        date: randomDate(aroundDate(moment(actualDate).weekday(-1)).add(2, 'day'), aroundDate(moment(actualDate).add(1, 'week').weekday(0))).toISOString(),
        statusSchedule: Object.values(StatusSchedule)[posSchedule],
        typeSchedule: Object.values(TypeSchedule)[typeSchedule],
        name: leite.pessoa.primeiroNome(),
        attendant: attendants[Math.round(Math.random() * (attendants.length - 1))],
        interested: 'Pilates'
    });
}

for (let i = 0; i < futureSchedule; i++) {
    const posSchedule = Math.round(Math.random() * (Object.values(StatusSchedule).length - 1));
    const typeSchedule = Math.round(Math.random() * (Object.values(TypeSchedule).length - 1));
    dataSchedules.push({
        posSchedule,
        date: randomDate(aroundDate(moment(actualDate).add(1, 'week').weekday(0)).add(2, 'day'), aroundDate(moment(actualDate).add(1, 'week').weekday(1))).toISOString(),
        statusSchedule: Object.values(StatusSchedule)[posSchedule],
        typeSchedule: Object.values(TypeSchedule)[typeSchedule],
        name: leite.pessoa.primeiroNome(),
        attendant: attendants[Math.round(Math.random() * (attendants.length - 1))],
        interested: 'Pilates'
    });
}


if (isFixedSchedule) {
    dataSchedules = [];
    for (const fixedSchedule of fixedSchedules) {
        dataSchedules.push({
            date: moment(new Date(fixedSchedule.date)).toISOString(),
            posSchedule: fixedSchedule.posSchedule,
            statusSchedule: (fixedSchedule.status as StatusSchedule),
            typeSchedule:  TypeSchedule.byKey(fixedSchedule.type.key)! ,
            name: fixedSchedule.name,
            attendant: fixedSchedule.attendant,
            interested: fixedSchedule.interested,
        });
    }
}

if (!isFixedSchedule) console.log(dataSchedules);

// export default dataSchedules;