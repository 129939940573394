/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFormik } from 'formik';
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ls from '../../projects/localStorage';
import { isMail } from '../../utils/functions/data';
import { BlockLoading } from '../components/BlockLoading';
import AuthenticateService from '../service/AuthenticateService';
import DashboardReportService from '../service/admin/DashboardReportService';

const authenticateService = new AuthenticateService();
const dashboardReportService = new DashboardReportService();

interface IFormData {
	username: string;
	password: string;
	accept: boolean;
	invalid: boolean;
};


export const Login = () => {
	const history = useHistory();
	const [formData, setFormData] = useState<IFormData>();
	const [showMessage, setShowMessage] = useState(false);
	const [loading, setLoading] = useState(false);

	const [customerId] = useState<string | null>(ls.customer.getCustomerIdStorageToken());

	const [isInitialMount, setIsInitialMount] = useState(true);

	// 🆕 Adicionando observação de iframes e ajustando height para 95%
	useEffect(() => {
		const observer = new MutationObserver(mutations => {
			mutations.forEach(mutation => {
				mutation.addedNodes.forEach(node => {
					if (node.nodeName === 'IFRAME') {
						const iframe = node as HTMLIFrameElement;

						// Verifica diretamente os estilos definidos no próprio elemento
						const width = iframe.style.width;
						const height = iframe.style.height;

						if (width === '100%' && height === '100%') {
							console.log('🛑 Iframe detectado com width: 100% e height: 100%! Recarregando página...');
							iframe.style.height = '95%';
							window.location.reload();
						}
					}
				});
			});
		});

		// Iniciar observação
		observer.observe(document.body, { childList: true, subtree: true });

		// Cleanup do observer quando o componente desmonta
		return () => observer.disconnect();
	}, []);

	useEffect(() => {
		if (isInitialMount) {
			logout();
			setIsInitialMount(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const logout = async () => {
		try {
			localStorage.clear();
			if (customerId) await dashboardReportService.updateLogonDashboardReport('access', 'logon', customerId);
		} catch (error) {
			console.error(error);
		}
	}

	const formik = useFormik<IFormData>({
		initialValues: {
			username: '',
			password: '',
			accept: false,
			invalid: false,
		},
		validate: (data) => {
			let errors: any = {};

			if (!data.username) {
				errors.username = 'Username obrigatório.';
			} else if (!isMail(data.username)) {
				errors.username = 'Endereço de email inválido. E.g. example@email.com';
			}

			if (!data.password) {
				errors.password = 'Password é obrigatório.';
			}

			return errors;
		},
		onSubmit: (data) => {
			setLoading(true);
			setShowMessage(true);
			authenticateService.login(data.username, data.password).then(async () => {
				formik.resetForm();
				let userToken = ls.getLocalStorageToken();
				if (!userToken) {
					data.invalid = true;
					data.password = '';
					setFormData(data);
					setLoading(false);
				} else {
					await authenticateService.dataList(userToken)
						.then((returned: any) => {
							if (returned) {
								if (returned.networkGyms) {
									if (returned.networkGyms?.length === 1 && !localStorage.getItem('networkGym')) localStorage.setItem('networkGym', returned.networkGyms[0]._id);
									localStorage.setItem('networkGymDataList', JSON.stringify(returned.networkGyms));
								}
								if (returned.branchGyms) {
									if (ls.networkGym.hasNetworkGymSelected() && !localStorage.getItem('branchGym') && returned.branchGyms?.length === 1) localStorage.setItem('branchGym', returned.branchGyms[0]._id);
									localStorage.setItem('branchGymDataList', JSON.stringify(returned.branchGyms));
								}
							}
						})
						.catch(() => [])
						.finally(() => {
							setLoading(false);
							window.location.href = '/';
						});
				}
			}).catch(error => {
				data.invalid = true;
				data.password = '';
				setFormData(data);
				setLoading(false);
			});
		}
	});

	const isFormFieldValid = (name: keyof IFormData) => !!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name: keyof IFormData) => {
		return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
	};

	return (
		<>
			<BlockLoading blocked={loading} />
			<div className="login-body">
				<div className="login-panel p-fluid">
					<div className="login-panel-header">
						<img src="chat4fit/assets/layout/images/logo-horizontal-white.png" alt="serenity-react" />
					</div>
					<div className="login-panel-content">
						<form onSubmit={formik.handleSubmit} className="p-fluid">
							<div className="p-grid">
								<div className="p-col-12">
									<h1>Entre com seu usuário de acesso</h1>
								</div>

								<div className="p-col-12 field" style={{ padding: 0, color: '#f00' }}>
									<span className="p-float-label">
										<InputText id="username" name="username" value={formik.values.username} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('username') })} />
										<label htmlFor="username" className={classNames({ 'p-error': isFormFieldValid('username') })}>Username*</label>
									</span>
									{getFormErrorMessage('username')}
								</div>
								<div className="p-col-12 field" style={{ padding: 0, color: '#f00' }}>
									<span className="p-float-label">
										<Password id="password" name="password" toggleMask value={formik.values.password} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('password') })} />
										<label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid('password') })}>Password*</label>
									</span>
									{getFormErrorMessage('password')}
								</div>
								{formik.values.invalid ?
									<div className="p-col-12 field" style={{ padding: 0, color: '#f00', textAlign: 'center' }}>
										<span className="p-float-label">
											Usuário ou senha informados são inválidos!
										</span>
									</div> : null
								}
								<div className="p-col-12" style={{ textAlign: 'center', margin: 'auto', padding: '0px', width: '90%' }}>
									<div className="flex" style={{ backgroundColor: '#F00', color: '#FFF', borderRadius: '10px', border: '1px solid #000', padding: '10px' }}>
										<i className="pi pi-exclamation-triangle" style={{ fontSize: '2.5rem', padding: '10px' }} />
										<div style={{ textAlign: 'left', margin: '0px', padding: '0px' }}>
											<div style={{ textAlign: 'left', margin: '0px', padding: '0px' }}>
												- Não remova o Chat4Fit no DISPOSITIVO CONECTADO no WhatsApp.
											</div>
											<div style={{ textAlign: 'left', margin: '0px', padding: '0px' }}>
												- Não utilize o CELULAR para enviar ou receber mensagens.
											</div>
										</div>
									</div>
								</div>
								<div className="p-col-12">
									<Button type="submit" label="Sign In" className="mt-2" style={{ width: '100%' }} />
								</div>
								{/* <div className="p-col-12">
									Esqueceu sua senha? <a href="/#">Recupere sua senha</a> agora.
								</div> */}
							</div>
						</form>
					</div>
				</div>
			</div>
			<Button icon="pi pi-times" rounded severity="danger" aria-label="Clear Cache" onClick={() => { logout(); window.location.href = '/'; }} style={{ position: 'absolute', maxHeight: 24, maxWidth: 24, right: 10, bottom: 10 }} />
		</>
	)
}