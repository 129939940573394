/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { RoleKeyPermiteds, RoleRules, checkRoleHierarchyByToken, convertRoleKeyPermiteds } from '../../../projects/checkRoleHierarchy';
import ls from '../../../projects/localStorage';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import BranchGymPermissionService from '../../service/admin/BranchGymPermissionService';
import PermissionsFunctionalitieService from '../../service/admin/PermissionsFunctionalitiesService';
import QuickMessageBranchGymService from '../../service/admin/QuickMessageBranchGymService';
import QuickMessageService from '../../service/admin/QuickMessageService';
import { IMessage } from '../Control';
import { createSkeletons } from '../commons/GenericCard';
import { GenericStepDialog } from '../commons/GenericStepDialog';
import { addNewStepRegister } from '../commons/formDataFunctions';
import { IStepListDataQuickMessage } from '../generics/entities';
import { IPropsArgsComponents } from '../generics/formInterfaces';
import { createGenericListData } from '../generics/genericCardConfig';
import { createView } from '../generics/view';
import './QuickMessage.css';

const quickMessageService = new QuickMessageService();
const quickMessageBranchGymService = new QuickMessageBranchGymService();

const permissionsFunctionalitieService = new PermissionsFunctionalitieService();
const branchGymPermissionService = new BranchGymPermissionService();

export const QuickMessage = (props: IPropsArgsComponents) => {
    const [title] = useState('Mensagens Rápidas');
    const [token] = useState(props.args.token);
    const [loadingData, setLoadingData] = useState(true);
    const [dialogData, setDialogData] = useState(false);

    const [quickMessageData, setQuickMessageData] = useState<IStepListDataQuickMessage[]>();
    const [quickMessageBranchGymData, setQuickMessageBranchGymData] = useState<any[]>();
    const [quickMessageShowData, setQuickMessageShowData] = useState<IStepListDataQuickMessage[]>();
    const [branchGymData, setBranchGymData] = useState<any[] | null>();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [networkGymId, setNetworkGymId] = useState(props.args.networkGymId);
    const [branchGymId, setBranchGymId] = useState(props.args.branchGymId);

    const [editStepData, setEditStepData] = useState<any | null>();

    const [permissionCreateDefined, setPermissionCreateDefined] = useState<RoleKeyPermiteds>(RoleRules.owner);
    const [permissionEditDefined, setPermissionEditDefined] = useState<RoleKeyPermiteds>(RoleRules.owner);
    const [permissionBlockDefined, setPermissionBlockDefined] = useState<RoleKeyPermiteds>(RoleRules.owner);

    const [loadingDataRefreshList, setLoadingDataRefreshList] = useState<boolean>(true);
    const [loadingQuickMessageBranchGymList, setLoadingQuickMessageBranchGymList] = useState<boolean>(true);



    useEffect(() => props.args.setUpdateConsidered([
        { key: 'networkGym', show: true, considered: true },
        { key: 'branchGym', show: true, considered: true },
    ]), []);

    useEffect(() => setNetworkGymId(props.args.networkGymId), [props.args.networkGymId]);
    useEffect(() => setBranchGymId(props.args.branchGymId), [props.args.branchGymId]);

    useEffect(() => { refreshList(); }, [networkGymId]);

    useEffect(() => { refreshListBranchGyms(); }, [networkGymId]);
    useEffect(() => { refreshQuickMessageBranchGymList(); }, [quickMessageData]);

    useEffect(() => { refreshListBranchGymPermissionPermissionCreateDefined(); refreshListBranchGymPermissionPermissionEditDefined(); refreshListBranchGymPermissionPermissionBlockDefined(); }, [networkGymId, branchGymId]);

    useEffect(() => { refreshQuickMessageWithQuickMessageBranchGymList(); }, [branchGymData, quickMessageBranchGymData]);

    useEffect(() => { setLoadingData(loadingDataRefreshList || loadingQuickMessageBranchGymList); },
        [loadingDataRefreshList, loadingQuickMessageBranchGymList]);


    useEffect(() => { if (!token) window.location.href = '/'; }, [token]);

    const refreshListBranchGymPermissionPermissionCreateDefined = async () => {
        await definePermission('register_quick_messages_create', setPermissionCreateDefined);
    }

    const refreshListBranchGymPermissionPermissionEditDefined = async () => {
        await definePermission('register_quick_messages_edit', setPermissionEditDefined);
    }

    const refreshListBranchGymPermissionPermissionBlockDefined = async () => {
        await definePermission('register_quick_messages_block', setPermissionBlockDefined);
    }

    const definePermission = async (key: string, methodSet: React.Dispatch<React.SetStateAction<RoleKeyPermiteds>>) => {
        let role: RoleRules | undefined = RoleRules.owner;
        const functionCreate = await permissionsFunctionalitieService.findPermissionsFunctionalityByFunctionalityKey(key);
        if (functionCreate.data && functionCreate.data.length > 0 && functionCreate.data[0]._id) {
            const permissionCreate = await branchGymPermissionService.findBranchGymPermissionByPermissionsFunctionalitiesId(networkGymId, branchGymId, functionCreate.data[0]._id);
            if (permissionCreate.data && permissionCreate.data.length > 0 && permissionCreate.data[0]._id) {
                role = convertRoleKeyPermiteds(permissionCreate.data[0].role.hierarchyRoleKey)?.key;
            } else {
                role = convertRoleKeyPermiteds(functionCreate.data[0].defaultRole.hierarchyRoleKey)?.key;
            }
            methodSet(role && role !== RoleRules.no_role ? role : RoleRules.owner);
        } else {
            methodSet(RoleRules.owner);
        }
    }

    const refreshQuickMessageBranchGymList = () => {
        let isMounted = true;
        if (!quickMessageData) return;
        const validIds: string[] = quickMessageData
            .filter(b => b._id !== undefined && typeof b._id === 'string') // Filtra apenas por elementos com _id definido e do tipo string
            .map(q => q._id || ''); // Agora o TypeScript sabe que o resultado é string[]

        quickMessageBranchGymService.allQuickMessageBranchGyms(validIds).then(result => {
            if (isMounted) { setQuickMessageBranchGymData(result.data); }
        });
        return () => { isMounted = false };
    }

    const refreshQuickMessageWithQuickMessageBranchGymList = () => {
        if (branchGymData && quickMessageData && quickMessageBranchGymData) {
            loadBranchGyms();
        }
    }
    const refreshList = () => {
        let isMounted = true;
        setLoadingDataRefreshList(true);
        if (networkGymId) {
            quickMessageService.allNetworkGymQuickMessages(networkGymId).then((returned: any) => {
                if (isMounted) {
                    if (returned.status === HttpStatus.UNAUTHORIZED) {
                        props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                        setTimeout(() => { setLoadingDataRefreshList(false); window.location.href = '/'; }, 5000);
                        setQuickMessageData([]);
                    } else if (isSuccess(returned.status)) {
                        let returnedQuickMessages = returned.data;
                        setQuickMessageData(returnedQuickMessages);
                    } else {
                        setQuickMessageData([]);
                    }
                    setLoadingDataRefreshList(false);
                }
            }).catch(error => {
                console.error('error', error);
                if (isMounted) {
                    setLoadingDataRefreshList(false);
                    setQuickMessageData([]);
                }
            });
        } else {
            if (isMounted) {
                setLoadingDataRefreshList(false);
                setQuickMessageData([]);
            }
        }
        return () => { isMounted = false };
    };

    const refreshListBranchGyms = async () => {
        let isMounted = true;
        setLoadingData(true);
        const branchGymsByStorageToken = await ls.branchGym.getBranchGymsByStorageToken();

        if (isMounted) {
            setBranchGymData(ls.branchGym.getAllBranchGyms({
                networkGymId,
                branchGymIds: branchGymsByStorageToken?.map(b => b._id),
                nonpayer: false,
                unsubscribe: false
            }));
        }
        setLoadingData(false);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return () => { isMounted = false };
    };

    const setNewStepData = async (stepData: IStepListDataQuickMessage) => {
        props.args.setLoading(true);
        setEditStepData(stepData);
        props.args.setLoading(false);
        setDialogData(true);
    }

    const seStepData = async (stepData: IStepListDataQuickMessage | any) => {
        props.args.setLoading(true);
        if (stepData && stepData._id) {
            const resultQuickMessage = await quickMessageService.findQuickMessageById(stepData._id);
            setEditStepData(Object.assign(stepData, resultQuickMessage.data));
            setDialogData(true);
            props.args.setLoading(false);
        } else {
            setEditStepData(null);
            setDialogData(true);
            props.args.setLoading(false);
        }
    }

    const listData = () => {
        let listData = quickMessageShowData ? quickMessageShowData : [];

        listData = listData.sort((l1: any, l2: any) => {
            return l1.title < l2.title ? -1 : l1.title > l2.title ? 1 : 0;
        });

        // listData.forEach((d: any) => {
        //     d.quickMessage = loadBranchGyms(d.customer?._id, branchGymData, quickMessageShowData);
        //     d.quickMessages = d.quickMessage.filter((f: any) => f.selected).map((b: any) => b.branchGymName).join(', ');
        // });


        if (!networkGymId)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Você precisa selecionar uma Rede.</h3>
            </div>;

        if (loadingData) return createSkeletons(8);


        const buttons = [];
        if (checkRoleHierarchyByToken(permissionBlockDefined)) {
            buttons.push({
                evalCondition: '(!data.blocked || data.blocked === false) ', key: `bloquear`, icon: 'pi-lock',
                method: (type: string, _id?: string | null | undefined, ...args: string[]) => blocked(type, _id, ...args), tooltip: 'Bloquear'
            });

            buttons.push({
                evalCondition: '(data.blocked && data.blocked === true)', key: `desbloquear`, icon: 'pi-lock-open',
                method: (type: string, _id?: string | null | undefined, ...args: string[]) => unblocked(type, _id, ...args), tooltip: 'Desbloquear'
            });
        }

        return createGenericListData('quickMessage', listData.filter(q => !branchGymId || q.branchGyms?.map(b => b._id).includes(branchGymId)),
            { icon: 'pi-align-left', evalIcon: 'data.isObject ? `pi-images` : `pi-align-left`', evalValue: 'data.title', evalInactive: 'data.blocked' },
            // eslint-disable-next-line no-template-curly-in-string
            [{ description: '\'Tipo\'', value: 'data.isObject ? `Mídia`: `Mensagem`' },
            // eslint-disable-next-line no-template-curly-in-string
            { description: '\'Ação rápida\'', value: '`#${data.tag}#`' },
            // { description: '\'Criado por\'', value: 'data.holder?.nickname' },
            { description: '\'Unidades\'', value: `!data.branchGymString ? 'carregando...' : data.branchGymString`, fontSize: '\'0.75rem\'', overflow: '\'visible\'' },
            { description: '', value: '' },
            { description: '', value: '' }
            ],
            localStorage.getItem('quickMessage'),
            {
                isEdited: !!checkRoleHierarchyByToken(permissionEditDefined),
                evalIsEdited: `data.holder._id === '${ls.customer.getCustomerIdStorageToken()}' || ${checkRoleHierarchyByToken(permissionEditDefined)} `,
                isCloned: false, isSelected: false,
                loadingData,
                editForceData: { networkGym: networkGymId },
                // removeForceData: ['_id', 'key'],
                select: props.args.select,
                unselect: props.args.unselect,
                setEditStepData: (stepData: any) => { seStepData(stepData) },
                setDialogData: setDialogData,
                buttons: [...buttons]
            });
    }

    const blockOrUnblock = async (quickMessageById: string, block: boolean) => {
        await quickMessageService.saveQuickMessage({
            _id: quickMessageById,
            blocked: block
        });
        refreshList();
    }
    const blocked = async (type: string, _id?: string | null | undefined, ...args: string[]) => {
        props.args.setLoading(true);
        if (_id) await blockOrUnblock(_id, true);
        props.args.setLoading(false);
    }

    const unblocked = async (type: string, _id?: string | null | undefined, ...args: string[]) => {
        props.args.setLoading(true);
        if (_id) await blockOrUnblock(_id, false);
        props.args.setLoading(false);
    }

    const loadBranchGyms = () => {
        let isMounted = true;
        if (branchGymData && quickMessageData && quickMessageBranchGymData) {
            setLoadingQuickMessageBranchGymList(true);
            let quickMessages = quickMessageData;
            const localQuickMessageBranchGym = quickMessageBranchGymData;
            const branchGymsMap = branchGymData.reduce((acc, gym) => ({ ...acc, [gym._id]: gym }), {});
            if (localQuickMessageBranchGym && localQuickMessageBranchGym.length > 0) {
                quickMessages.forEach(quickMessage => {
                    quickMessage.branchGyms = localQuickMessageBranchGym
                        .filter(qmbg => qmbg.quickMessage?._id === quickMessage?._id || qmbg.quickMessage === quickMessage?._id)
                        .map(qmbg => branchGymsMap[qmbg.branchGym])
                        .filter(b => !!b); // Filtra possíveis undefined

                    quickMessage.branchGymString = quickMessage.branchGyms.map((b: any) => b.name).join(', ');
                    quickMessage.branchGymString = quickMessage.branchGymString ? quickMessage.branchGymString : 'nenhum(a)';
                });
            }
            if (isMounted) setQuickMessageShowData(quickMessages);
            if (isMounted) setLoadingQuickMessageBranchGymList(false);
        }

        return () => { isMounted = false };
    }

    return (
        <>
            {createView(title, {
                editData: true,
                addNew: !!networkGymId && branchGymData! && checkRoleHierarchyByToken(permissionCreateDefined),
                addNewRegister: () => addNewStepRegister('quickMessage', undefined, { setEditStepData: (editStepData: any) => { setNewStepData(editStepData); }, setDialogData }),
                hasSearchTerm: false, searchTerm, setSearchTerm,
                setEditStepData: (editStepData: any) => { seStepData(editStepData); }, setDialogData,
                listData: () => listData()
            })}
            {token && networkGymId && branchGymData && dialogData ?
                <>
                    <GenericStepDialog<IStepListDataQuickMessage> key='QuickMessageDialog'
                        token={token}
                        objectData='usuário'
                        refreshList={() => refreshList()}
                        setLoading={(value) => props.args.setLoading(value)}
                        showStepDialog={dialogData}
                        setStepDialogData={(value) => setDialogData(value)}
                        setMessage={(message: IMessage) => props.args.setMessage(message)}
                        editStepData={editStepData}
                        setEditStepData={setEditStepData}
                        emptyStepData={{
                            _id: undefined,
                            title: '',
                            networkGym: networkGymId,
                            branchGym: undefined,
                            holder: undefined,
                            isObject: false,
                            text: '',
                            mediaBase64: undefined,
                            mimetype: '',
                            tag: '',
                            blocked: false,
                            active: true,
                            deleted: false
                        }}
                        editForceData={{
                            networkGym: networkGymId,
                            // branchGym: branchGymId
                        }}
                        showDialog={false}
                        setDialogData={function (value: React.SetStateAction<boolean>): void {
                            throw new Error('Function not implemented.');
                        }} />
                </>
                :
                null
            }
        </>
    )
}


